import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useLazyGameStepWithSectionIdQuery } from "../../../../../store/features/apiSlice";
import StepHeader from "./StepHeader";
import style from "./StepWrapper.module.scss";
import StepContent from "./StepContent";
import HomeworkHeader from "./HomeworkHeader";
import CompetitionHeader from "./CompetitionHeader";

const StepWrapper = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition");
  const homework = urlParams.get("homework");
  const isHomework = homework > 0;
  const isCompetition = competition > 0;

  const { gameId, sectionId, stepId } = useParams();
  const [fetchCurrentStep, { data: step, isLoading: gameLoading }] =
    useLazyGameStepWithSectionIdQuery();

  useEffect(() => {
    if (gameId && sectionId && stepId)
      fetchCurrentStep({ gameId, sectionId, stepId }).unwrap();
  }, [gameId, sectionId, stepId]);

  if (gameLoading) {
    return (
      <div
        className="w-100 h-100"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  return (
    <div className="w-100 h-100">
      {isHomework ? <HomeworkHeader /> : isCompetition ? <CompetitionHeader /> : <StepHeader />}

      <StepContent step={step ?? []} competition={competition} homework={homework}/>
    </div>
  );
};

export default StepWrapper;
