import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import { adminPages } from "../../../../../../menu";
import Page from "../../../../../../layout/Page/Page";
import Button from "../../../../../../components/bootstrap/Button";
import {
  useLazyCyberCTFQuestionsQuery,
  useDeleteCTFQuestionMutation,
} from "../../../../../../store/features/cyberApi";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../../../../utils/customErrors";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import CTFTable from "./CTFTable";
import CTFForm from "./forms/CTFForm";
import LoadingSpinner from "../../../../../../utils/LoadingSpinner";
import CTFRun from "./forms/CTFRun";

const CTFQuestions = () => {
  const [fetchQuestions, { data, isLoading }] = useLazyCyberCTFQuestionsQuery();
  const [deleteCTF] = useDeleteCTFQuestionMutation();
  const questions = data ?? [];
  const [search, setSearch] = useState("");
  const [filteredCTF, setFilteredCTF] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isCTF_Run, setIsCTF_Run] = useState(false);
  const [CTFId, setCTFId] = useState(false);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const deleteItem = async (id) => {
    try {
      const response = await deleteCTF(id);
      if (response.error) {
        const customError = handleErrorMessage(response.error.data);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>{getCustomErrorMessage(customError)}</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          "Kategori başarıyla silindi.",
          "success"
        );
      }
    } catch (error) {
      console.error("hata");
    }
  };

  const handleCTF = (id) => {
    if (id) {
      setCTFId(id);
      setIsOpen(true);
    } else {
      setCTFId(null);
      setIsOpen(true);
    }
  };
  const handleCTF_RUN = (id) => {
    if (id) {
      setCTFId(id);
      setIsCTF_Run(true);
    } else {
      return;
    }
  };

  useEffect(() => {
    filterCTF();
    //eslint-disable-next-line
  }, [search, questions]);

  const filterCTF = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newcategories = questions?.filter(
      (item) =>
        item.title.toLowerCase().includes(search) ||
        item.description.toLowerCase().includes(search)
    );
    setFilteredCTF(newcategories);
  };

  return (
    <PageWrapper title={adminPages.cyber.text}>
      <Page>
        {isOpen && <CTFForm
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          CTFId={CTFId}
          refetch={fetchQuestions}
        />}
        {isCTF_Run && <CTFRun isOpen={isCTF_Run} setIsOpen={setIsCTF_Run} CTFId={CTFId} />}
        <div className="row">
          <div className="col-3">
            <Button className="btn btn-success" onClick={() => handleCTF()}>
              Yeni CTF Ekle
            </Button>
          </div>
          <div className="col-6">
            <form className="d-flex gap-1" onSubmit={filterCTF}>
              <input
                className="form-control"
                type="search"
                placeholder="Aramak istediğiniz soruyu yazınız"
                value={search}
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
              />
              <button className="btn btn-warning w-25">Bul</button>
            </form>
          </div>
        </div>
        <br />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <CTFTable
            questions={questions ?? []}
            deleteItem={deleteItem}
            filteredCTF={filteredCTF}
            handleCTF={handleCTF}
            testCTF={handleCTF_RUN}
            refetch={fetchQuestions}
          />
        )}
      </Page>
    </PageWrapper>
  );
};

export default CTFQuestions;
