import icerikler from '../../assets/menuImages/CodeHub.png';
import codelab from '../../assets/menuImages/CodeLab.png';
import codemino from '../../assets/menuImages/CodeMino.png';
import codeboo from '../../assets/menuImages/CodeBoo.png';
import codecizz from '../../assets/menuImages/CodeÇizz.png';
import codeart from '../../assets/menuImages/CodeArt.png';
import talentcup from '../../assets/menuImages/TalentCup.png';

export const studentMenuImages = {
    icerikler:icerikler,
    codelab:codelab,
    codemino:codemino,
    codeboo:codeboo,
    codecizz:codecizz,
    codeart:codeart,
    talentcup:talentcup,
}
