import React from "react";
import LeaderBoardLeader from "./LeaderBoardLeader";
import LeaderBoardOthers from "./LeaderBoardOthers";
import style from "./LeaderBoardForStudent.module.scss";
import AVATARS from "../../../../common/data/avatarsDummy";

const MonthlyGeneralLeaderBoard = ({ data }) => {
  if (!data || !data.top_10 || data.top_10.length === 0) {
    console.log("DATA IS NULL or EMPTY");
    return (
      <div className="text-center text-muted my-5">
        Son 30 gün içerisinde puanınız bulunmamaktadır.
      </div>
    );
  }

  let user = data.user;
  let leader = null;
  let others = [];
  let userControl = user ? user.id : 0;

  if (user) {
    if (user.rank === 1) {
      leader = {
        ...user,
        email: user.email.split("@")[0],
      };
      others = data.top_10.slice(1, 5);
    } else if (user.rank > 1 && user.rank <= 5) {
      let _leader = data.top_10[0];
      leader = {
        ..._leader,
        email: _leader.email.split("@")[0],
      };
      others = data.top_10.slice(1, 5);
    } else {
      let _leader = data.top_10[0];
      leader = {
        ..._leader,
        email: _leader.email.split("@")[0],
      };
      others = data.top_10.slice(1, 4).concat(user);
    }
  } else {
    let _leader = data.top_10[0];
    leader = {
      id: _leader.id,
      avatar: _leader.avatar || AVATARS.defaultAvatarSmall,
      email: _leader.email.split("@")[0],
      total_points: _leader.total_points,
    };
    others = data.top_10.slice(1, 5);
  }

  return (
    <div className={`${style.leaderBoard} row h-100 mt-5`}>
    <div className="col-lg-4 col-md-6">
      <LeaderBoardLeader leader={leader} userControl={userControl} />
    </div>
    <div className="col-lg-8 col-md-6">
      {others.length > 0 ? (
        <LeaderBoardOthers others={others} userControl={userControl} />
      ) : (
        <div className="text-center text-muted my-4">
          Henüz sıralamada kimse yok.
        </div>
      )}
    </div>
  </div>
  );
};

export default MonthlyGeneralLeaderBoard;
