import style from "./GamesWrapper.module.scss";

export default function TabsComponent({ data, activeTab, setActiveTab }) {
  return (
    <div className={`${style.tabContainer} mx-5`}>
      {data?.map((tab) => {
        return (
          <div
            key={tab.id}
            className={`${style.tab} btn ${
              activeTab === tab.id ? `${style.active}` : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            <div className={`${style.gameLogoContainer}`}>
              <img
                src={tab.image_path}
                alt=""
                width={70}
                height={70}
                className={`${style.gameLogo} mx-auto d-block img-fluid`}
              />
            </div>
            <span className="ms-2">{tab.title}</span>
          </div>
        );
      })}
    </div>
  );
}
