import React from "react";
import style from "../dashboard.module.scss";
import AnimationCoding from "./gifs/AnimationCoding.gif";
import AnimationGaming from "./gifs/AnimationGaming.gif";
export const CallToActionBox = () => {
  return (
    <div className={style.ctaBox}>
      <img src={AnimationCoding} alt="gif-left" className={style.iconSmall} />
      <p className={style.ctaText}>Kodlamaya ve Oynamaya Devam Et!</p>
      <img src={AnimationGaming} alt="gif-right" className={style.iconSmall} />
    </div>
  );
};
