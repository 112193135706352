import { useState } from "react";
import style from "./TrailsWrapper.module.scss";

// const tabs = [
//   { id: 1, label: "Bilişime İlk Adım" },
//   { id: 2, label: "Siber Güvenlik Geleceğim" },
//   { id: 3, label: "Yapay Zeka Geleceğim" },
//   { id: 4, label: "Oyun Geleceğim" },
//   { id: 5, label: "Web Geleceğim" },
// ];

export default function TabsComponent({ data, activeTab, setActiveTab, partnerTrailIds }) {
  console.log(data);
  // const [activeTab, setActiveTab] = useState(1);

  return (
    <div className={`${style.tabContainer} mx-5`}>
      {/* {data?.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={`${style.tab} btn ${
            activeTab === tab.id
              ? `shadow-sm ${style.active}`
              : `${style.inactive}`
          }`}
        >
          {tab.title}
        </button>
      ))} */}
      {data?.map((tab) => {
        const isPartner = partnerTrailIds.includes(tab.id);
        return (
          <div
            key={tab.id}
            className={`${style.tab} btn ${activeTab === tab.id ? `${style.active}` : ""} ${isPartner ? `${style.partnerTab}` : ""}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </div>
        );
      })}
    </div>
  );
}
