import React, { useState } from "react";
import style from "./GamesWrapper.module.scss";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import { useNavigate } from "react-router";
import Button from "../../../../components/bootstrap/Button";
import {
  useGamesWithSectionQuery,
  useLazyGameStepsWithSectionQuery,
} from "../../../../store/features/apiSlice";
import { getDifficultyText } from "../../../../common/data/enumGameDifficulty";
import {
  getGameSectionStatusColor,
  getGameSectionStatusText,
} from "../gameFunctions";
import SectionModal from "../gameModals/SectionModal";
import Spinner from "../../../../components/bootstrap/Spinner";
const GameContent = ({ game }) => {
  const id = game?.id;
  const { data: sections } = useGamesWithSectionQuery({ id }, { skip: !id });
  const [fetchGameSteps, { isLoading }] = useLazyGameStepsWithSectionQuery();
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const handleCancel = () => setOpen(false);
  const handleSection = async (url, sectionId) => {
    try {
      const response = await fetchGameSteps({ id, sectionId }).unwrap();
      if (response?.length > 0) {
        setCurrentUrl(`${url}/${response[0]?.id}`);
        setOpen(true);
      }
    } catch (error) {
      console.error("Bölüm yüklenirken hata oluştu:", error);
    }
  };
  const handleStartClick = () => {
    setOpen(false);
    if (currentUrl) {
      navigate(currentUrl);
    } else {
      console.warn("Hedef URL bulunamadı.");
    }
  };
  
  const userSubscriptionType = localStorage.getItem("subscription_type");

  const gameCardClasses = {
    1: style.gameCardUzay,
    2: style.gameCardKutup,
    3: style.gameCardCiftlik,
    4: style.gameCardCodali,
    7: style.gameCardCodeboo,
    8: style.gameCardCodecizz,
  };
  // const userSubscriptionType = 1;

  const keywords = {
    games: "games",
    codeBoo: "codeBoo",
    codeCizz: "codeCizz",
  };
  const currentKeyword = window.location.pathname.split("/")[1];
  const keywordUrl = keywords[currentKeyword] || "games";

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };

  return (
    <div>
      <SectionModal
        open={open}
        setOpen={setOpen}
        onCancel={handleCancel}
        onClick={handleStartClick}
        currentUrl={currentUrl}
      />
      <div className={`${style.gameContent}`}>
        {sections?.map((item) => {
          const isFreeUser =
            Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id;
          const canAccessCourse = isFreeUser ? Number(id) === 1 : true;
          const difficultyInfo = getDifficultyText(item.difficulty);
          const url = `/${keywordUrl}/${id}/sections/${item.id}/steps`;
          return (
            <div
              key={item.id}
              className={`${style.gameCard} ${
                gameCardClasses[id] || style.gameCardCodali
              } pt-3`}
            >
              <div
                style={{
                  color: difficultyInfo?.color,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                className={`${style.difficulty}`}
              >
                <span>
                  {difficultyInfo?.emoji} {difficultyInfo?.text}
                </span>
              </div>
              <div className={`${style.gameImage}`}>
                <img
                  src={game.image_path}
                  alt=""
                  width={128}
                  height={128}
                  className="mx-auto d-block img-fluid mb-3"
                />
              </div>
              {/* <ProgressBar percentage={item.percentage} darkMode={false} /> */}
              <div className={`${style.gameDetails}`}>
                <p className={`${style.gameTitle} m-0 p-0`}>{item.title}</p>
                <p className={`${style.gameDescription}`}></p>
                <div className={style.buttonContainer}>
                  <Button
                    disabled={isLoading}
                    color={canAccessCourse ? "dark" : "danger"}
                    className={`${style.button} btn ${
                      canAccessCourse
                        ? `bg-l10-${getGameSectionStatusColor(
                            item.user_status
                          )} text-${getGameSectionStatusColor(
                            item.user_status
                          )} fw-bold px-3 py-2 rounded-pill}`
                        : "btn-myDanger shadow-3d-myDanger"
                    } mb-1 shadow-3d-up-hover`}
                    size="lg"
                    tag="a"
                    // to={canAccessCourse ? url : undefined}
                    onClick={
                      !canAccessCourse
                        ? () => handleNavigation(`/profile`, "Account Details")
                        : () => handleSection(url, item.id)
                    }
                  >
                    {(item.id === id && isLoading) && (
                      <Spinner color="primary" inButton isSmall/>
                    )}
                    {canAccessCourse
                      ? getGameSectionStatusText(item.user_status)
                      : "Planı Yükselt"}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GameContent;

export const ProgressBar = ({ percentage, darkMode }) => {
  return (
    <div
      className={`${style.progressContainer} ${
        darkMode ? "dark-mode" : "light-mode"
      }`}
    >
      <div
        className={`${style.progressBar}`}
        style={{ width: `${percentage}%` }}
      >
        <span className={`${style.progressText}`}>{percentage}%</span>
      </div>
    </div>
  );
};
