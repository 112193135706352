import React from "react";
import COURSE_STATUS from "../../../../common/data/enumCourseState";
import { Card } from "@mui/material";
import style from "./paths.module.scss";
import useDarkMode from "../../../../hooks/useDarkMode";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import Button from "../../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";

const SingleLesson = React.memo(({ course }) => {
  const { darkModeStatus } = useDarkMode();
  const userSubscriptionType = localStorage.getItem("subscription_type");
  // const userSubscriptionType = 2;
  const { id, percentage, status } = course;
  const is_draft = status === COURSE_STATUS.DRAFT.id;

  const isPremiumUser = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id;
  const isStandartUser = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id;
  // const isFreeUser = userSubscriptionType == USER_SUBSCRIPTION_TYPE.FREE.id;

  const freeCourses = [13,14,15,101,179,180,181,182,183,184]
  const standardCourses = [115,106,19,69,70,71,72,94,105,112,113,114,116,117,118]

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };
  return (
    <div>
      {freeCourses.includes(id) ||
      (standardCourses.includes(id) && (isPremiumUser || isStandartUser)) ||
      isPremiumUser ? (
        <Card
          className={`${is_draft ? "bg-l10-dark" : ""} ${
            style.singleLessonContainer
          } mx-auto px-3`}
          style={{
            backgroundColor: darkModeStatus && "#242731",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/lessons/AllLessonsAndContent/${id}`)}
        >
          <div className="d-flex flex-column align-items-center gap-4">
            <div className={`${style.lessonLogo} mt-3`}>
              <img
                src={`/images/${course && course.technology}.png`}
                alt=""
                width={64}
                height={64}
                className="mx-auto d-block"
              />
            </div>
            <div
              className={`${style.lessonTitle}`}
              style={{ color: darkModeStatus && "#E7EEF8" }}
            >
              <span>{course && course.title}</span>
            </div>
            <div className={`${style.lessonProgressBarContainer}`}>
              <div
                className={`${style.lessonProgressBar} d-flex align-items-center`}
              >
                <div
                  style={{ width: `${percentage}%` }}
                  className={`${style.percentage}`}
                >
                  <p
                    style={{
                      color: `${
                        percentage < 30
                          ? "#a253ff"
                          : percentage > 70
                          ? "#fff"
                          : "#68686d"
                      }`,
                    }}
                  >
                    {percentage}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          className={`${is_draft ? "bg-l10-dark" : ""} ${
            style.singleLessonContainer
          } mx-auto px-3`}
          style={{
            backgroundColor: darkModeStatus && "#242731",
            cursor: "pointer",
          }}
          onClick={() => handleNavigation(`/profile`, "Account Details")}
        >
          <div className="d-flex flex-column align-items-center gap-4">
            <div className={`${style.lessonLogo} mt-3`}>
              <img
                src={`/images/${course && course.technology}.png`}
                alt=""
                width={64}
                height={64}
                className="mx-auto d-block"
              />
            </div>
            <div
              className={`${style.lessonTitle}`}
              style={{ color: darkModeStatus && "#E7EEF8" }}
            >
              <span>{course && course.title}</span>
            </div>
            <div className={`${style.lessonButtonContainer}`}>
              <Button
                color="danger"
                className="btn btn-myDanger btn-lg w-100 shadow-3d-up-hover shadow-3d-myDanger"
                size="sm"
                tag="a"
                onClick={() => handleNavigation(`/profile`, "Account Details")}
              >
                Planı Yükselt
              </Button>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
});

export default SingleLesson;

