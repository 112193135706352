import React from "react";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import { registerUser } from "../loginHelpers";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../components/bootstrap/Spinner";
import { AuthContext } from "../../../../store/auth-store";
import showNotification from "../../../../components/extras/showNotification";
import Icon from "../../../../components/icon/Icon";
import style from "../customLogin/login.module.scss";

const StudentForm = ({ setError, setIsNewUser }) => {
  const [busy, setBusy] = React.useState(false);
  const navigate = useNavigate();
  const cntTxt = React.useContext(AuthContext);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email adresi gereklidir.";
    }
    if (
      values.email.length > 0 &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Geçersiz email adresi.";
    }
    if (!values.password) {
      errors.password = "Şifre gereklidir.";
    }
    if (values.password.length > 0 && !/[a-z|A-Z]/.test(values.password)) {
      errors.password = "Şifre en az bir harf içermelidir.";
    }
    if (values.password.length > 0 && !/[0-9]/.test(values.password)) {
      errors.password = "Şifre en az bir rakam içermelidir.";
    } else if (values.password.length > 0 && values.password.length < 8) {
      errors.password = "Şifre en az 8 karakter olmalıdır.";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Şifre tekrarı gereklidir.";
    }
    if (!errors.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = "Şifreler eşleşmiyor.";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      setBusy(true);

      const response = await registerUser("auth/register/", {
        email: values.email,
        password: values.password,
      });
      if (response.status === "success") {
        navigate("/successfulRegister");
        cntTxt.login(response.token, {
          email: response.email,
          roles: response.roles,
          name: response.name,
          surname: response.surname,
          id: response.id,
        });
        setIsNewUser();
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Bilgi</span>
          </span>,
          <p style={{ fontSize: "14px" }}>Kaydınız başarıyla oluşturuldu.</p>,
          "success"
        );
      } else {
        // response.message = "This email is already exist."
        // setError(response.message);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Uyarı</span>
          </span>,
          <p style={{ fontSize: "14px" }}>Bu email zaten kullanılmaktadır.</p>,
          "warning"
        );
      }

      setBusy(false);
      return;
    },
  });
  return (
    <>
      <div className="col-12">
        <FormGroup isFloating label="Email">
          <Input
            name="email"
            type="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.email}
            invalidFeedback={formik.errors.email}
          />
        </FormGroup>
      </div>

      <div className="col-12">
        <FormGroup isFloating label="Şifre">
          <Input
            name="password"
            type="password"
            autoComplete="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.password}
            invalidFeedback={formik.errors.password}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup isFloating label="Şifre (Tekrar)">
          <Input
            name="confirmPassword"
            type="password"
            autoComplete="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.confirmPassword}
            invalidFeedback={formik.errors.confirmPassword}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <span style={{color:"#fff"}}>
          Hesap oluşturarak Bilişim School A.Ş.'nin{" "}
          <a
            href="https://talent14.com/uyelik-sozlesmesi-ve-site-kullanim-kosullari/"
            target="_blank"
            rel="noopener noreferrer"
            className="fw-bold"
            style={{color:"#fff"}}
          >
            Üyelik Sözleşmesi
          </a>{" "}
          ile{" "}
          <a
            href="https://talent14.com/gizlilik-ve-cerez-politikasi/"
            target="_blank"
            rel="noopener noreferrer"
            className="fw-bold"
            style={{color:"#fff"}}
          >
            Çerez ve Gizlilik Politikası
          </a>{" "}
          koşullarını kabul etmiş olursunuz.
        </span>
      </div>
      <div className={`${style.saveAndLoginButton} col-12 mb-3`}>
        <Button
          color="info"
          className="w-100 py-3"
          onClick={formik.handleSubmit}
        >
          {busy && (
            <Spinner color="primary" inButton isSmall>
              Loading...
            </Spinner>
          )}
          Kayıt Ol
        </Button>
      </div>
    </>
  );
};

export default StudentForm;
