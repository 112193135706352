import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ThemeContext from '../../contexts/themeContext';
import { useMediaQuery } from 'react-responsive';

const Page = forwardRef(({ children, className, container, ...props }, ref) => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const isMobile = useMediaQuery({ maxWidth: 768 });
	function closeAsideBAr (){
		if(asideStatus && isMobile){
			setAsideStatus(false)
		}

	}
	return (
		<div
			ref={ref}
			className={classNames('page', className, {
				[`container${typeof container === 'string' ? `-${container}` : ''}`]: container,
			})}
			{...props}
			onClick={()=>closeAsideBAr()}
			>
			{children}
		</div>
	);
});
Page.propTypes = {
	children: PropTypes.node.isRequired,
	container: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.oneOf([null, 'sm', 'md', 'lg', 'xl', 'xxl', 'fluid']),
	]),
	className: PropTypes.string,
};
Page.defaultProps = {
	container: 'xxl',
	className: null,
};

export default Page;
