export const CATEGORY_STATUS = {   
    UNKNOWN: {
        id: 0,
        name: 'Unknown',
    },
    ACTIVE: {
        id: 1,
        name: 'Active',
    },
    PASSIVE: {
        id: 2,
        name: 'Passive',
    },
};
export const CATEGORY_ACCESS_STATUS = {   
    UNKNOWN: {
        id: 0,
        name: 'Unknown',
    },
    PRIVATE: {
        id: 1,
        name: 'Private',
    },
    PUBLIC: {
        id: 2,
        name: 'Public',
    },
};
export const CTF_STATUS = {   
    UNKNOWN: {
        id: 0,
        name: 'Unknown',
    },
    ACTIVE: {
        id: 1,
        name: 'Active',
    },
    PASSIVE: {
        id: 2,
        name: 'Passive',
    },
};
export const CTF_DIFFICULTY = {   
    EASY: {
        id: 1,
        name: 'Easy',
    },
    MEDIUM: {
        id: 2,
        name: 'Medium',
    },
    HARD: {
        id: 3,
        name: 'Hard',
    },
    IMPOSSIBLE: {
        id: 4,
        name: 'Impossible',
    },    
};
export const VIRTUAL_MACHINE_STATUS = {   
    UNKNOWN: {
        id: 0,
        name: 'Unknown',
    },
    ACTIVE: {
        id: 1,
        name: 'Active',
    },
    PASSIVE: {
        id: 2,
        name: 'Passive',
    },
};