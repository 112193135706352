import axios from "axios";
import token from "./token";
import showNotification from "../components/extras/showNotification";
import Icon from "../components/icon/Icon";

const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;

// Axios instance oluştur
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {},
});

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  const _token = token();
  if (_token) {
    config.headers.Authorization = `Bearer ${_token}`;
  }
  return config;
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ((error.response.status === 401) || (error.response.status === 403)) {
      localStorage.clear();
      console.log('%cservice.js line:29 window.location', 'color: #007acc;', window.location);
      if (window.location.pathname !== "/login")
       window.location.replace("/login");
    }
    if (error.response.status === 500) {
      const errorMessage = error.response.data.message[0].error;
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Error" size="lg" className="me-1" />
          <span>Hata!</span>
        </span>,
        errorMessage || "Bir hata oluştu 500",
        "danger"
      );
    }
    return Promise.reject(error);
  }
);

// function _getRequestConfig() {
//   const config = {
//     headers: {}
//   };
//   const _token = token();
//   if (_token) {
//     config.headers.Authorization = `Bearer ${_token}`;
//   }
//   return config;
// }

const get = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    return { error };
  }
};

const simpleGet = async (url) => {
  const response = await axiosInstance.get(url);
  return response;
};

const post = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    return { error };
  }
};

const get_paytr_token = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    return { error };
  }
};

const paymentPost = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    return { error };
  }
};

const simplePost = async (url, data) => {
  return await axiosInstance.post(url, data);
};

const put = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response;
  } catch (error) {
    return { error };
  }
};

const patch = async (url, data) => {
  try {
    const response = await axiosInstance.patch(url, data,);
    return response;
  } catch (error) {
    return { error };
  }
};

const del = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    return { error };
  }
};

export { get, post, put, del, simplePost, patch, paymentPost, simpleGet, get_paytr_token };
