import React, { useState } from "react";
import style from "./Games.module.scss";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import Page from "../../../layout/Page/Page";
import lessonsLogo1 from "../../../../src/assets/lessons/lessonsLogo1.svg";
import lessonsLogo2 from "../../../../src/assets/lessons/lessonsLogo2.svg";
import GamesWrapper from "./gamesWrapper/GamesWrapper";

const Games = () => {
  const [activeTab] = useState(1);

  const tabContents = [
    {
      content: <GamesWrapper />,
      tabName: "Oyunlar",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
  ];
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page
        container={false}
        style={{ padding: "0" }}
        className={`${style.pageContainer}`}
      >
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab?.icon2 : tab?.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab?.tabName}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1].content}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Games;
