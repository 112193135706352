import { createSlice } from "@reduxjs/toolkit";
import { menuList } from "./content restrictions/menuList";

const initialState={
    menu:menuList.LESSONS,
    currentClass:"",
}

const restrictionSlice= createSlice({
    name: 'restriction',
    initialState,
    reducers: {
        setMenu: (state, action) => {
            state.menu = action.payload
        },
        setCurrentClass: (state, action) => {
            state.currentClass = action.payload
        }
    }
});
export const { setMenu, setCurrentClass } = restrictionSlice.actions;
export default restrictionSlice;