import React from "react";
import { useNavigate } from "react-router-dom";
import codeboo from "./videos/1.mp4";
import codecizz from "./videos/3.mp4";
import codemino from "./videos/2.mp4";
import codelab from "./videos/4.mp4";
import cyber14 from "./videos/6.mp4";
import robot14 from "./videos/5.mp4";
import style from "../dashboard.module.scss";

const botData = [
  { video: codeboo, url: "games?activeTab=7" },
  { video: codecizz, url: "games?activeTab=8" },
  { video: codemino, url: "games" },
  { video: codelab, url: "learning-paths-wrapper" },
  { video: cyber14, url: "" },
  { video: robot14, url: "robot14" },
];

const BotCards = () => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    if (!url) return;
    navigate(`/${url}`);
  };

  return (
    <div className={`${style.gamesCard}`}>
      <div className="container">
        <div className="row">
          {botData.map((bot, index) => (
            <div
              key={index}
              className="col-xl-4 col-sm-6 col-12 d-flex align-items-center justify-content-center p-1"
            >
              <video
                src={bot.video}
                autoPlay
                loop
                muted
                className={`${style.gameVideoCard} w-75 rounded-5 cursor-pointer`}
                style={{ borderRadius: "15px" }}
                onClick={() => handleClick(bot.url)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BotCards;
