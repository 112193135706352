import React, { useEffect, useMemo, useState } from "react";
import TabsComponent from "./TabsComponent";
import TrailsContent from "./TrailsContent";
import style from "./TrailsWrapper.module.scss";
import { useTrailsQuery } from "../../../../../store/features/apiSlice";

const TrailsWrapper = () => {
  const { data, isLoading } = useTrailsQuery();

  // Direkt olarak state yerine hesaplanmış değerler kullanıyoruz
  const adminTrails = useMemo(() => data?.filter((trail) => trail.school_status === 1) || [], [data]);
  const partnerTrails = useMemo(() => data?.filter((trail) => trail.school_status === 3) || [], [data]);
  
  const mergedTrails = useMemo(() => [...adminTrails, ...partnerTrails], [adminTrails, partnerTrails]);
  
  // İlk açılışta aktif tabı belirle
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    if (mergedTrails.length > 0) {
      setActiveTab(mergedTrails[0].id);
    }
  }, [mergedTrails]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  return (
    <div>
      <TabsComponent
        data={mergedTrails}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        partnerTrailIds={partnerTrails?.map((item) => item.id) || []}
      />
      <TrailsContent
        courses={mergedTrails?.find((item) => item.id === activeTab)?.courses || []}
      />
    </div>
  );
};

export default TrailsWrapper;
