import { createSlice} from "@reduxjs/toolkit";


const pathWithVideoSlice = createSlice({
    name: "pathWithVideo",
    initialState: {
        course:{},
        currentChapter:localStorage.getItem("currentChapter")?JSON.parse(localStorage.getItem("currentChapter")):[],
        lectureVideoContent:localStorage.getItem("lectureVideoContent")?JSON.parse(localStorage.getItem("lectureVideoContent")):{url:"",description:"", videoId:"",content:""},
        lessonCompleted:false
    },
    reducers: {
        setCourse: (state, action) => {
            state.course = action.payload;
        },
        setCurrentChapter: (state, action) => {
            let lessonId = action.payload.lessonId
            let index = state.currentChapter.findIndex(s=>s.lessonId === lessonId)
            if(index === -1){
                state.currentChapter = [...state.currentChapter, action.payload];
            }
            else {
                let tmp = state.currentChapter;
                tmp[index] = {...tmp[index], ...action.payload}
                state.currentChapter = [...tmp]
            }
            localStorage.setItem("currentChapter",JSON.stringify(state.currentChapter))
        },
        lectureVideoContent: (state, action) => {
            state.lectureVideoContent=action.payload
            localStorage.setItem("lectureVideoContent",JSON.stringify(state.lectureVideoContent))
        },
        setLessonCompleted: (state, action) => {
            state.lessonCompleted = action.payload;
        },

    }
});

export const {lectureVideoContent, setCurrentChapter,setCourse, setLessonCompleted} = pathWithVideoSlice.actions;

export default pathWithVideoSlice;