import uzayLogo from "../../../../assets/games/uzayLogo.webp";
import kutupLogo from "../../../../assets/games/kutupLogo.webp";
import ciftlikLogo from "../../../../assets/games/ciftlikLogo.webp";
import codaliLogo from "../../../../assets/games/codaliLogo.webp";
import codeBooLogo from "../../../../assets/games/codeBooLogo.webp";
import codeCizzLogo from "../../../../assets/games/codeCizzLogo.webp";
// import galaksiLogo from "../../../../assets/games/galaksiLogo.webp";
import startLogo from "../../../../assets/games/uzayStartIcon.svg";
import closeLogo from "../../../../assets/games/uzayCloseIcon.svg";

export const getModalContentByURL = (url) => {
  if (
    url.includes("games/1/sections/1/steps") ||
    url.includes("games/1/sections/2/steps")
  ) {
    return {
      image: uzayLogo,
      title: "HAREKETLER",
      description: [
        `Hareketler, kodlamada tıpkı bir labirentte doğru yolları seçmek gibidir. Hedefe ulaşmak için nereye döneceğini ve kaç adım atacağını iyi bilmen gerekir. Hareketler, bir karakteri ya da nesneyi istediğin yere götürmenin en eğlenceli yoludur!`,
        "Örneğin: “Sağa dön ve 5 adım ilerle” dersen, karakter tam olarak bunu yapar. Ama yanlış bir komut verip “Geri git” dersen, hedefinden uzaklaşabilirsin!",
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/1/sections/3/steps") ||
    url.includes("games/1/sections/37/steps") ||
    url.includes("games/1/sections/38/steps")
  ) {
    return {
      image: uzayLogo,
      title: "DÖNGÜLER",
      description: `Döngüler, tıpkı bir şarkının nakaratı gibi, kodunuzda belirli bir kod bloğunu tekrar tekrar çalıştırmak için kullanılan sihirli araçlardır. Döngüler, tekrarlanan görevleri otomatikleştirmek için mükemmeldirler ve kodunuzu daha kısa ve daha verimli hale getirirler. Mesela bir karakterin 10 kere zıplamasını istiyorsan, döngü kullanabilirsin. Böylece 10 kere "zıpla" yazmak zorunda kalmazsın, bir kere döngüye yazarsın ve karakter 10 kere zıplar!`,
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/1/sections/4/steps")) {
    return {
      image: uzayLogo,
      title: "HATA AYIKLAMA",
      description: [
        `Hata ayıklama, tıpkı bir dedektif gibi çalışmayı gerektirir. Gemisinde sorun fark eden Codemino şöyle düşündü:`,
        "1. Adımları Kontrol Et: “Kodumda hangi adımı yanlış yazdım?”",
        `2. İzleme Yap: Kodun her satırını takip etti, nelerin doğru, nelerin yanlış çalıştığını gözlemledi.`,
        `3. Deneme ve Düzeltme: Yanlış bir komut (“sağa dön” yerine “geri dön”) buldu ve hemen düzeltti.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/2/sections/5/steps")) {
    return {
      image: kutupLogo,
      title: "İÇ İÇE DÖNGÜLER",
      description: [
        `İç içe döngüler, tıpkı kutuptaki karla kaplı bir alanda her bir adımı takip etmek gibidir. Dış döngü her bir bölgeyi keşfederken, iç döngü her bölgedeki her adımı kontrol eder.`,
        ``,
        `Örneğin: Dış döngü: Her bir kutup alanını keşfedin. İç döngü: Her alandaki adımları doğru şekilde uygula.`,
        `Codemino, iç içe döngülerle her bir kar parçasını dikkatlice taradı. Bu sayede her bölgedeki tüm alanları tek tek kontrol edebildi.`,
        ``,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/2/sections/6/steps") ||
    url.includes("games/2/sections/9/steps")
  ) {
    return {
      image: kutupLogo,
      title: "WHILE DÖNGÜSÜ",
      description: [
        `While döngüsü, bir şey doğru olduğu sürece bir işi yapmaya devam etmeyi sağlar.`,
        `Codemino, izleri takip ederken şu şekilde düşündü:`,
        `“Eğer izleri buluyorsak, takip etmeye devam et!” Yani, while döngüsü şöyle çalıştı:`,
        `Koşul: Eğer iz hala var ise, devam et.`,
        `İşlem: İzleri takip et, her adımı kontrol et. Codemino ve ekibi, izleri takip ederek kaybolan ekibe ulaştılar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/2/sections/7/steps") ||
    url.includes("games/2/sections/8/steps")
  ) {
    return {
      image: kutupLogo,
      title: "IF ELSE KOŞULU",
      description: [
        `Codemino ve ekibi kutupta iki yol arasında kaldılar; biri karla, diğeri buzla kaplıydı. Hangi yolu seçeceklerine karar vermek için if-else koşulu kullandılar.`,
        `If-else, bir duruma göre iki seçenek sunar. Codemino şöyle düşündü: Eğer yol karla kaplıysa, devam et.`,
        `Eğer buzla kaplıysa, geri dön ve başka bir yol dene.`,
        `Sonunda, karla kaplı yoldan giderek güvenli bir şekilde ilerlediler.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/2/sections/10/steps")) {
    return {
      image: kutupLogo,
      title: "HATA AYIKLAMA",
      description: [
        `Hata ayıklama, tıpkı bir dedektif gibi çalışmayı gerektirir.`,
        `Gemisinde sorun fark eden Codemino şöyle düşündü:`,
        `1. Adımları Kontrol Et: “Kodumda hangi adımı yanlış yazdım?”`,
        `2. İzleme Yap: Kodun her satırını takip etti, nelerin doğru, nelerin yanlış çalıştığını gözlemledi.`,
        `3. Deneme ve Düzeltme: Yanlış bir komut (“sağa dön” yerine “geri dön”) buldu ve hemen düzeltti.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/11/steps")) {
    return {
      image: ciftlikLogo,
      title: "YENi BLOKLAR",
      description: [
        `Codemino ve arkadaşları, büyük bir çiftlikte çeşitli görevleri yerine getirmek için bir maceraya atıldılar. Çiftlikte, tarlaların ekilmesinden, balyaların toplanmasına kadar birçok iş vardı. Codemino, çiftlik işlerini düzenli ve verimli bir şekilde yapmak için farklı kodlama araçları kullandı.`,
        `Çiftlik macerasında, Codemino her adımda kodlama ve problem çözme yeteneklerini kullanarak işleri kolaylaştırdı ve çiftlikteki tüm görevleri başarıyla tamamladı.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/12/steps")) {
    return {
      image: ciftlikLogo,
      title: "BOOLEAN DEĞERLER",
      description: [
        `Boolean değerler, sadece iki olası durumu ifade eder: True (doğru) veya False (yanlış). Yani, bir şeyin doğru olup olmadığını kontrol etmek için boolean değerleri çok işe yarar. Codemino, çiftlikte şu şekilde kullanıyordu:`,
        `Tavuk Yumurtladı Mı? Eğer tavuk yumurtladıysa: true Eğer tavuk yumurtlamadıysa: false`,
        `Boolean değerleri, karar verme ve koşul kontrolü için çok önemlidir ve bu sayede çiftlikte işler kolayca halledildi! 🌾`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/13/steps")) {
    return {
      image: ciftlikLogo,
      title: "DEĞİŞKENLER",
      description: [
        `Codemino ve ekibi, çiftlikteki işlerini yaparken birçok farklı değişken kullandılar.`,
        `Değişkenler, bilgi saklamak ve gerektiğinde bu bilgileri kullanmak için çok önemli araçlardır.`,
        `Çiftlikte her şey bir değişkene dönüşebilir: hayvanların sayısı, tarladaki ürünler, hava durumu gibi`,
        `Codemino ve ekibi, değişkenleri kullanarak çiftlikteki işleri verimli bir şekilde halletti! 🌾🌟`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/14/steps")) {
    return {
      image: ciftlikLogo,
      title: "FOR DÖNGÜSÜ",
      description: [
        `Codemino ve ekibi, çiftlikteki tekrarlanan işlerini kolaylaştırmak için for döngüsü kullandılar.  For döngüsü, belirli bir işlemi belirli bir sayıda tekrarlamak için kullanılır.`,
        `Bu sayede, aynı işi defalarca yazmak yerine tek bir komutla işi halledebilirler. Örneğin her sabah tavukları kümesten almak gerekiyordu. Codemino, 10 tavuğu almak için for döngüsü kullandı: Bu döngü, her bir tavuk için aynı işlemi tekrar etti ve zaman kazandırdı.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/15/steps")) {
    return {
      image: ciftlikLogo,
      title: "AND / OR",
      description: `
Codemino ve ekibi, çiftlikte bazı görevleri yaparken birden fazla koşulu aynı anda kontrol etmek zorunda kaldılar.
AND (VE): İki koşulun her ikisinin de doğru olması gerektiğinde kullanılır. Örneğin: Codemino, "Eğer hava güneşliyse ve tarla kuruysa, sulama yap" dedi. Yani her iki koşulun da doğru olması gerekiyordu.
OR (VEYA): Koşullardan birinin doğru olması yeterli olduğunda kullanılır. Örneğin: Codemino, "Eğer inekler açsa ya da suyu bitmişse, onları besle" dedi. Yani bir koşul yeterliydi.`,
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/3/sections/16/steps") ||
    url.includes("games/3/sections/18/steps")
  ) {
    return {
      image: ciftlikLogo,
      title: "FONKSİYONLAR",
      description: `Fonksiyonlar, bir görevi tekrar tekrar yapmak yerine, bir kere yazıp her zaman kullanabileceğiniz kod bloklarıdır.
Codemino’nun çiftlikte kullandığı bir fonksiyon tavukları toplamak içindi. Tavukları Toplama Fonksiyonu:
Codemino, her sabah tavukları kümesten almak için bir fonksiyon yazdı.  Bu fonksiyon, her sabah tavukları almak için aynı komutları yazmasına gerek kalmadan her seferinde çalıştı. Fonksiyonlar, kodu daha düzenli, verimli ve tekrar kullanılabilir hale getirir. `,
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/17/steps")) {
    return {
      image: ciftlikLogo,
      title: "KARŞILAŞTIRMA",
      description: [
        `Codemino ve ekibi, çiftlikteki işleri yaparken 
bazı koşulları karşılaştırarak doğru kararlar almaları gerekiyordu.`,
        `Karşılaştırmalar, iki değeri karşılaştırarak hangisinin küçük, büyük, eşit veya doğru olup olmadığını belirlememize yardımcı olur.`,
        `Karşılaştırmalar, doğru kararları almayı ve çiftlikteki işleri düzenlemeyi sağlar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/4/sections/19/steps") ||
    url.includes("games/4/sections/20/steps")
  ) {
    return {
      image: codaliLogo,
      title: "TEMELLER",
      description: [
        `Kodlamada yönler ve hareketler, tıpkı bir labirentte doğru yolu bulmaya benzer. Hedefe ulaşmak için doğru yönü seçmeli ve doğru adımları atmalısın.`,
        `Hareketler, bir karakteri ya da nesneyi istediğin yere taşımak için çok önemlidir! Örneğin, "Sağa dön ve 5 adım ilerle" dersen, karakterin tam olarak bunu yapar.`,
        `Ama "Geri git" dersen, yanlış yola gidebilirsin! Hareketleri doğru planlarsan, hedefe kolayca ulaşabilirsin.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/4/sections/21/steps") ||
    url.includes("games/4/sections/22/steps")
  ) {
    return {
      image: codaliLogo,
      title: "DÖNGÜLER",
      description: [
        `Döngüler, tıpkı bir şarkının nakaratı gibi, kodunuzda belirli bir kod bloğunu tekrar tekrar çalıştırmak için kullanılan sihirli araçlardır.`,
        `Döngüler, tekrarlanan görevleri otomatikleştirmek için mükemmeldirler ve kodunuzu daha kısa ve daha verimli hale getirirler. Mesela bir karakterin 10 kere zıplamasını istiyorsan, döngü kullanabilirsin.`,
        `Böylece 10 kere "zıpla" yazmak zorunda kalmazsın, bir kere döngüye yazarsın ve karakter 10 kere zıplar!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/4/sections/23/steps")) {
    return {
      image: codaliLogo,
      title: "WHILE DÖNGÜSÜ",
      description: [
        `While döngüsü, bir şey doğru olduğu sürece bir işi yapmaya devam etmeyi sağlar.`,
        `CodeAli, izleri takip ederken şu şekilde düşündü:`,
        `“Eğer izleri buluyorsak, takip etmeye devam et!” Yani, while döngüsü şöyle çalıştı:`,
        `Koşul: Eğer iz hala var ise, devam et.`,
        `İşlem: İzleri takip et, her adımı kontrol et. CodeAli ve ekibi, izleri takip ederek kaybolan ekibe ulaştılar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/4/sections/24/steps")) {
    return {
      image: codaliLogo,
      title: "KOŞULLU İFADELER",
      description: [
        `CodeAli ormanda iki yol arasında kaldı; biri ağaçlarla, diğeri çamurla kaplıydı.  If-else koşulunu kullanarak doğru yolu seçmeye karar verdiler.`,
        `If-else şöyle çalıştı: Eğer yol ağaçla kaplıysa, devam et. Eğer çamurla kaplıysa, başka bir yol dene.`,
        `CodeAli, ağaçla kaplı yolu seçerek güvenli bir şekilde ilerledi. If-else koşulu, doğru kararı almayı sağlayarak programın esnek ve doğru çalışmasını temin etti!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  }
  // else if (url.includes("games/6/sections/-----/steps")) {
  //   return {
  //     image: galaksiLogo,
  //     title: "KOŞULLU İFADELER",
  //     description: [
  //       `CodeAli ormanda iki yol arasında kaldı; biri ağaçlarla, diğeri çamurla kaplıydı.  If-else koşulunu kullanarak doğru yolu seçmeye karar verdiler.`,
  //       `If-else şöyle çalıştı: Eğer yol ağaçla kaplıysa, devam et. Eğer çamurla kaplıysa, başka bir yol dene.`,
  //       `CodeAli, ağaçla kaplı yolu seçerek güvenli bir şekilde ilerledi. If-else koşulu, doğru kararı almayı sağlayarak programın esnek ve doğru çalışmasını temin etti!`,
  //     ],
  //     closeIcon: closeLogo,
  //     startButton: startLogo,
  //   };
  // }
  else if (url.includes("games/7/sections/25/steps")) {
    return {
      image: codeBooLogo,
      title: "Temeller-1",
      description: [
        "İyi bir kodlamacı aslında iyi bir analiz yapma yeteneğine sahip olan kişidir",
        "Bu aşamada temel seviyede döngüleri uyguluyor olacağız. Döngüler, bir durumu ya da bir kodu birden fazla kez çalıştırmak demektir.",
        "Örneğin: Araçlardaki otopilotlar, sürekli gaza basma işlemini otomatikleştirirler. Bu da temelde bir döngü yapısıdır.",
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/7/sections/26/steps")) {
    return {
      image: codeBooLogo,
      title: "Temeller-2",
      description: [
        `Kodlamada birinci öncelik durumların ve şartların değişmesidir.`,
        `İyi bir kodlamacının her koşula göre bir yöntemi olmalıdır.`,
        `Koşullar, değişen durum ve şartlara göre uygulanacak kodların yazılmasıdır.`,
        `Örneğin:"Eğer sınavdan 100 alırsan sana bilgisayar alacağım" cümlesi bilgisayarın alınmasının aslında sınavdan 100 alma şartına bağlı olduğunu gösterir.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/7/sections/27/steps")) {
    return {
      image: codeBooLogo,
      title: "Temeller-3",
      description: [
        `Kodlamacılar koşul dışındaki durumlara da hazırlıklı olmalıdır.`,
        `Örneğin:"Eğer sınavdan 100 alırsan sana bilgisayar alırım, 90 alırsan telefon alırım ama daha düşük alırsan hiç bir şey almam" bu cümle aslında her koşulu ya da koşul dışı durumlarda uygulanacak yöntemlerin önceden ayarlanmış olmasını temsil ediyor.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/7/sections/28/steps")) {
    return {
      image: codeBooLogo,
      title: "Değişkenler",
      description: [
        `Yaş kavramının bir değişken olduğunu biliyor muydunuz?`,
        `Evet tam da öyle.`,
        `Örneğin: "Kaç yaşındasın ?", "13 yaşındayım."`,
        `Bu örneğin içerisinde yaş bir değişken karşılığındaki 13 de bir değerdir.`,
        `Tabi her zaman sayısal bir karşılık olması gerekmez.`,
        `Adın ne ? sorusuna "Mehmet" olarak cevap veriyorsan "ad" yapısı da bir değişkendir.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/7/sections/29/steps")) {
    return {
      image: codeBooLogo,
      title: "Dizilier",
      description: [
        `Benzer türdeki verilerin belirli bir sırayla yan yana dizildiği bir yapıdır.`,
        `Tıpkı bir trenin vagonları gibi, dizideki her öğe belirli bir sırada bulunur ve numaralandırılır.`,
        `Örneğin: Elif, kütüphanedeki kitapları rengârenk sıralarken, her rengin belirli bir sırayla dizildiğinde nasıl bir gökkuşağı oluşturduğunu fark etti ve dizilerin gizemini çözdü!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/30/steps")) {
    return {
      image: codeCizzLogo,
      title: "1",
      description: [
        `Küçük robot ÇizgiBot, sihirli kalemiyle harikalar yaratmayı çok seviyor!`,
        `Bir gün, büyülü haritasını tamamlamak için dümdüz bir yol çizmesi gerektiğini öğrendi.`,
        `Bunun için forward(100) komutunu kullanarak 100 birim ileri gitmesi gerekiyordu!
forward(100) komutu, ekrandaki karakterin 100 birim ileri gitmesini sağlar.`,
        `Tıpkı yere bir kalem koyup dümdüz bir çizgi çekmek gibi!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/31/steps")) {
    return {
      image: codeCizzLogo,
      title: "2",
      description: [
        `ÇizgiBot, sihirli bahçesinde dev bir çember çizmeye karar verdi!`,
        `Araştırırken bir seferde dümdüz gitmek yerine, küçük adımlarla döne döne ilerlemesi gerektiğini öğrendi.`,
        `Bunun için rt(360,100) komutunu kullanarak 100 birim yarıçapında mükemmel bir çember çizdi!`,
        `rt(360,100) komutu, karakterin 100 birim yarıçapında tam bir çember çizmesini sağlar.`,
        `Tıpkı bir pusula ile büyük bir daire çizmek gibi!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/32/steps")) {
    return {
      image: codeCizzLogo,
      title: "3",
      description: [
        `ÇizgiBot, uzay haritasını tamamlamak için iki önemli noktayı işaretlemeliydi.`,
        `Önce x:100, y:100 noktasına bir mavi ışık yerleştirdi, sonra x:-100, y:-100 noktasına gidip aynısını yaptı.`,
        `Böylece gökyüzünde iki parlak yıldız belirdi!`,
        `dot(20, "blue") komutu, bulunduğun noktaya 20 birim çapında mavi bir nokta çizer. go(x, y) komutu ise karakterin belirli bir koordinata gitmesini sağlar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/33/steps")) {
    return {
      image: codeCizzLogo,
      title: "4",
      description: [
        `ÇizgiBot, büyük bir oyun alanı oluşturmak için yere tam 100 birim çapında bir çember çizmesi gerektiğini öğrendi.`,
        `Bunun için küçük adımlarla dönerek ilerlemeli ve her adımda biraz sağa dönmeliydi.`,
        `(360, 50) komutunu kullanarak tam bir çember çizdi ve görevini başarıyla tamamladı!`,
        `rt(360, 50) komutu, karakterin 50 birim yarıçapında (çapı 100 birim olan) tam bir daire çizmesini sağlar. Küçük dönüşlerle ilerleyerek kusursuz bir çember oluşturur!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/34/steps")) {
    return {
      image: codeCizzLogo,
      title: "5",
      description: [
        `ÇizgiBot, bal peteği gibi mükemmel bir altıgen çizmek için kolları sıvadı!`,
         `Önce her kenarı 60 birim uzunluğunda olan altıgeni çizdi, sonra her köşeye mavi bir nokta eklemek için dot(20, "blue") komutunu kullandı.`,
         `Sürekli tekrarlayan adımları fark edince, döngü kullanarak işlemi hızlandırdı.`,
        `Böylece altıgeni ve noktaları daha hızlı çizdi!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/35/steps")) {
    return {
      image: codeCizzLogo,
      title: "6",
      description: [
        `ÇizgiBot, kırmızı bir üçgen oluşturmak için işe koyuldu.`,
        `Önce kaleminin rengini kırmızı yaptı ve kalınlığını 5 birim olarak ayarladı.`,
        `Sonra 30 derece sağa döndü ve üçgeni çizmek için 3 kez tekrar eden bir döngü başlattı.`,
        `Her adımda 100 birim ileri gitti, ardından 120 derece sağa döndü.`,
        `Üçgen tamamlandığında, kalemini doldurma moduna aldı ve içini tamamen kırmızı renkle boyadı!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/8/sections/36/steps")) {
    return {
      image: codeCizzLogo,
      title: "7",
      description: [
        `ÇizgiBot, gökyüzünde yeşil bir kutu oluşturmak için işe koyuldu!`,
        `Önce jumpto(0,250) komutunu kullanarak hızla yukarıya x:0, y:250 noktasına zıpladı.`,
        `Ardından box(green,50) komutunu kullanarak kenar uzunluğu 50 birim olan yeşil bir kare çizdi.`,
        `Böylece havada asılı duran büyülü kutusunu tamamlamış oldu!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else {
    return {
      image: codeCizzLogo,
      title: "Genel Bölüm",
      description: "Bu bölüm hakkında daha fazla bilgi yakında eklenecek.",
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  }
};
