import { createSlice} from "@reduxjs/toolkit";
import { menuList } from './talentCupMenuList'

const talentCupSlice = createSlice({
    name: "talentCup",
    initialState: {
        menu:menuList.CODELAB,
        content:{
            // course_lessons:[],
            learning_paths:[],
        },
        selectedCompetition:null,
    },
    reducers: {
        setMenu: (state, action) => {
            state.menu = action.payload
        },
        setContentCourses:(state,action)=>{
            state.content = {...state.content,course_lessons:action.payload};
        },
        setSelectedCompetition:(state,action)=>{
            state.selectedCompetition = action.payload
        },
    }
});

export const {setMenu, setContentCourses, setSelectedCompetition} = talentCupSlice.actions;

export default talentCupSlice;