import React, { useEffect, useState } from "react";
import style from "./StepWrapper.module.scss";
import Icon from "../../../../../components/icon/Icon";
import { useGetStudentPracticesForStudentQuery } from "../../../admin/talent cup/talentCupApi";
import { useNavigate } from "react-router";

const CompetitionHeader = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition");
  const [competitionGames, setCompetitionGames] = useState(
    JSON.parse(localStorage.getItem("competitionGames")) || []
  );
  const { data, isLoading, refetch } = useGetStudentPracticesForStudentQuery(
    competition,
    { skip: !competition }
  );
  const games = data && data[0].games;
  const practices = data && data[0].practices;
  const lessons = data && data[0].lessons;

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "https://gamelab.bilisimschool.com") {
        let gameResult = event.data.gameData;

        // Eğer oyun başarıyla tamamlandıysa
        if (gameResult?.status === "success") {
          // Sadece competition varsa işlem yap
          if (competition) {
            refetch();

            const newGame = { game: gameResult.game, competition };

            // Aynı oyunun aynı competition için kaydedilmemesi için kontrol
            const isCompetitionGameExists = competitionGames.some(
              (item) =>
                item.game === newGame.game &&
                item.competition === newGame.competition
            );

            if (!isCompetitionGameExists) {
              const updatedCompetitionGames = [...competitionGames, newGame];
              setCompetitionGames(updatedCompetitionGames);
              localStorage.setItem(
                "competitionGames",
                JSON.stringify(updatedCompetitionGames)
              );
            }
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Event listener'ı temizle
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    //eslint-disable-next-line
  }, [competitionGames, competition]);

  if (isLoading) {
    return (
      <div
        className="w-100 h-100"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className={`${style.gameHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
      >
        <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
          {lessons?.map((lesson) =>
            lesson.chapters?.map((chapter) =>
              chapter.lessons?.map((l) => (
                <span
                  key={l.id}
                  className="lesson-item"
                  style={{
                    // textDecoration: "underline",
                    color: l.is_solved ? "blue" : "black",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={() =>
                    navigate(
                      `/lessons/content/${l.id}?competition=${competition}`
                    )
                  }
                >
                  {l.is_solved ? (
                    <Icon icon={"check"} color={"success"} size="2x" />
                  ) : (
                    "📖"
                  )}
                </span>
              ))
            )
          )}
          <Icon icon={"HdrWeak"} size="small" color="info" />
          {practices?.map((practice) => {
            const { id, is_solved } = practice;
            return (
              <span
                key={id}
                className="step-item"
                style={{
                  color: is_solved ? "blue" : "black",
                  cursor: "pointer",
                  fontSize: "16px",
                  marginRight: "10px",
                }}
                onClick={() =>
                  navigate(`/practice/${id}?competition=${competition}`)
                }
              >
                {is_solved ? (
                  <Icon icon={"check"} color={"success"} size="2x" />
                ) : (
                  "❓"
                )}
              </span>
            );
          })}
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
            {games?.map((game) =>
              game.sections?.map((section) =>
                section.steps.map((step) => (
                  <span
                    key={step.id}
                    className="step-item"
                    style={{
                      // textDecoration: "underline",
                      color: step.is_solved ? "blue" : "black",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                    onClick={() =>
                      navigate(
                        `/games/${game.id}/sections/${section.id}/steps/${step.id}?competition=${competition}`
                      )
                    }
                  >
                    {competitionGames.some(
                      (game) =>
                        game.game === String(step.id) &&
                        game.competition === competition
                    ) || step.is_solved ? (
                      <Icon icon={"check"} color={"success"} size="2x" />
                    ) : (
                      "⚪"
                    )}
                  </span>
                ))
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitionHeader;
