import { createSlice} from "@reduxjs/toolkit";


const loginWithPaymentPlanSlice = createSlice({
    name: "loginWithPaymentPlan",
    initialState: {
        paymentPlan:false,
        register:false
    },
    reducers: {
        setPaymentPlan: (state, action) => {
            state.paymentPlan = action.payload;
        },
        setRegister: (state, action) => {
            state.register = action.payload;
        },
    }
});

export const {setPaymentPlan, setRegister} = loginWithPaymentPlanSlice.actions;

export default loginWithPaymentPlanSlice;
