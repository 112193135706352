import React from "react";
import style from "../ParentReportWithClass.module.scss";
import certificateTemplate from "../../../../student/leaderboardsAndRewardsWrapper/certificatesPage/certificateTemplate.png";
import CertificateThumbnail from "./DynamicThumbnail/CertificateThumbnail";

const CertificateInfo = ({ certificateData, courseData }) => {
  const certificateCourse = courseData?.find(
    (item) => item.id === certificateData.object_id
  );
  return (
    <div>
      <div
        className={`${style.certificateDiv} mx-auto`}
      >
        <CertificateThumbnail
          name={certificateCourse.title}
          src={certificateTemplate}
        />
      </div>
    </div>
  );
};

export default CertificateInfo;
