import React, { useEffect, useState } from "react";
import style from "./StepWrapper.module.scss";
import {
  useGetStudentHomeworkQuery,
} from "../../../../../store/features/apiSlice";
import Icon from "../../../../../components/icon/Icon";
import HomeworkLessons from "../../../student/common/HomeworkLessons";
import HomeworkPractices from "../../../student/common/HomeworkPractices";
import HomeworkGames from "../../../student/common/HomeworkGames";

const HomeworkHeader = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const homework = urlParams.get("homework");
  const [homeworkGames, setHomeworkGames] = useState(
    JSON.parse(localStorage.getItem("homeworkGames")) || []
  );
  const { data: hwData, isLoading } = useGetStudentHomeworkQuery(homework, {
    skip: !homework,
  });
  const hwGames = hwData && hwData.content.game_steps;
  const hwPractices = hwData && hwData.content.learning_paths;
  const hwLessons =
    hwData &&
    hwData.content.course_lessons?.filter(
      (item) => item.school_status === 1 || item.school_status === 3
    );

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "https://gamelab.bilisimschool.com") {
        let gameResult = event.data.gameData;

        // Eğer oyun başarıyla tamamlandıysa
        if (gameResult?.status === "success") {
          if (homework) {
            const newHomework = { game: gameResult.game, homework };

            // Aynı oyunun aynı homework için kaydedilmemesi için kontrol
            const isHomeworkGameExists = homeworkGames.some(
              (item) =>
                item.game === newHomework.game &&
                item.homework === newHomework.homework
            );

            if (!isHomeworkGameExists) {
              const updatedHomeworkGames = [...homeworkGames, newHomework];
              setHomeworkGames(updatedHomeworkGames);
              localStorage.setItem(
                "homeworkGames",
                JSON.stringify(updatedHomeworkGames)
              );
            }
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Event listener'ı temizle
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    //eslint-disable-next-line
  }, [homeworkGames, homework]);

  if (isLoading) {
    return (
      <div
        className="w-100 h-100"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className={`${style.gameHeader} d-flex flex-column justify-content-center align-items-center`}
      >
        <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
          <HomeworkLessons hwLessons={hwLessons} homework={homework} />
          <Icon icon={"HdrWeak"} size="small" color="info" />
          <HomeworkPractices hwPractices={hwPractices} homework={homework} />
        </div>
        <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
          <HomeworkGames
            hwGames={hwGames}
            homework={homework}
            homeworkGames={homeworkGames}
            isActive={true}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeworkHeader;
