import React from 'react'
import { useGetStudentSubscribedClassesQuery } from '../../../../../../store/features/apiSlice';
import { useGet_a_SchoolQuery } from '../../../../teacher/teacherApi';
import StudentHeader from '../studentHeader/StudentHeader';

const StudentWithSchool = () => {
    const school = localStorage.getItem("school");
      const { data} = useGetStudentSubscribedClassesQuery();
      const { data:schoolData} = useGet_a_SchoolQuery(school, {skip:!school});
      const studentClassName = data && data.class_name
      const teacherFulName = data && data.teacher_name
      const schoolName = schoolData && schoolData.title
  return (
    <div>
      <StudentHeader studentClassName={studentClassName} teacherFulName={teacherFulName} schoolName={schoolName} />
    </div>
  )
}

export default StudentWithSchool