const USER_ROLES = {
    GUEST: {
        id: 0,
        name: 'Misafir',
    },
    STUDENT: {
        id: 1,
        name: 'Öğrenci',
    },
    TEACHER: {
        id: 2,
        name: 'Öğretmen',
    },
    SCHOOL_MANAGER: {
        id: 3,
        name: 'Yönetici',
    },
    EDITOR: {
        id: 4,
        name: 'Editör',
    },
    USER_SUPPORT: {
        id: 5,
        name: 'Müşteri Destek',
    },
    ADMIN: {
        id: 6,
        name: 'Admin',
    },
};
export default USER_ROLES;