import React from "react";
import style from "./LeaderBoardForStudent.module.scss";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import AVATARS from "../../../../common/data/avatarsDummy";

const LeaderBoardOthers = ({ others, userControl }) => {
  return (
    <Card stretch className={`${style.leaderBoard_rightMenu} pt-5`}>
      {others && others.length === 0 && (
        <div className="text-center text-muted my-4">
          Henüz sıralamada kimse yok.
        </div>
      )}
      {others &&
        others.map((other, i) => {
          const { rank, id, email, total_points, avatar } = other;
          return (
            <Card className={`${style.others} mx-auto`} key={id}>
              <CardBody className="d-flex justify-content-center">
                <div className="row">
                  <div className="col-12 d-flex flex-column align-items-center">
                    <div className={`${style.rank}`}>
                      <h3>{rank}</h3>
                    </div>

                    <div className={`${style.othersImgDiv}`}>
                      <img
                        src={
                          avatar && avatar !== "null"
                            ? avatar
                            : AVATARS.defaultAvatarSmall
                        }
                        alt=""
                      />
                      <div className={`${style.userPoints}`}>
                        {total_points} puan
                      </div>
                    </div>

                    <div className={`${style.userEmail}`}>
                      <h5
                        style={{
                          backgroundColor: `${
                            Number(userControl) === Number(id)
                              ? "lightGray"
                              : "transparent"
                          }`,
                        }}
                        className={`${style.email}`}
                      >
                        {email}
                      </h5>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          );
        })}
    </Card>
  );
};

export default LeaderBoardOthers;
