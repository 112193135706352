import React, { useEffect, useState } from "react";
import Chart from "../../../../../../components/extras/Chart";
import style from "./LessonCompletedRate.module.scss";

const ChartInfo = ({ value=[] }) => {
  const values = value?.map((item) => item.average_completion_rate);
  let titles = value?.map((item) => item.course_title);
  // titles = titles.map(title => title.split(/\s+/));
  // console.log(titles);
  const [customValue, setCustomValue] = useState(68);
  const [distTest, setDistTest] = useState(true);
  const isMobile = customValue === 29;

  // Pencere boyutu değiştiğinde bu işlev çalışacak
  const handleWindowResize = () => {
    const newWindowWidth = window.innerWidth;

    if (newWindowWidth < 501) {
      setCustomValue(29);
      setDistTest(false)
    } else {
      setCustomValue(68);
      setDistTest(true)
    }
  };

  useEffect(() => {
    // Component monte edildiğinde ve pencere boyutu değiştiğinde işlevi çağır
    window.addEventListener("resize", handleWindowResize);

    // Component kaldırıldığında olay dinleyicisini temizle
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div className={`${style.barChart}`}>
      {values && 
      <Chart
        type={"bar"}
        height={`${isMobile ? "250px" : "400px"}`}
        width={(values && values.length > 0) && values.length * customValue}
        series={[
          {
            name: "",
            data: values && values,
          },
        ]}
        options={{
          colors: ["#6C5DD3"],
          labels: ["Dersler"],
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top", // top, center, bottom
              },
              distributed: distTest,
            },
          },
          //! Barların üzerinde değerleri buradan gösterebiliriz.
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              if (val === 0) {
                return "";
              } else {
                return Number(val).toLocaleString();
              }
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },
          xaxis: {
            categories: titles,
            position: "bottom",
            labels: {
              show: true,
              offsetY: 0,
              rotate: -90,
              rotateAlways: true,
              hideOverlappingLabels: true,
              trim: true,
              showDuplicates: false,
              maxHeight: isMobile ? 130 : 195,
              // maxHeight: isMobile ? 130 : 275,
              style: {
                fontSize: `${isMobile ? "10px" : "16px"}`,
                fontWeight: 600,
                colors: "#6C5DD3",
                height: 300,
              },
            //   formattfuner: (value) => {
            //     console.log(value);
            //     // return (value && value.split(',')[1][1] === '0' ? value : '');
            //     return value;
            // }
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            tooltip: {
              enabled: false,
              offsetY: -35,
            },
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: true,
              style: {
                fontSize: `${isMobile ? "10px" : "16px"}`,
                fontWeight: 600,
                colors: "#6C5DD3",
              },
              formatter: function (val) {
                return Number(val).toLocaleString();
              },
            },
          },
        }}
      />
      }
    </div>
  );
};

export default ChartInfo;
