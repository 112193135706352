import React, { useEffect, useState } from "react";
import style from "./StepWrapper.module.scss";
import { useNavigate, useParams } from "react-router";
import { useGameStepsWithSectionQuery } from "../../../../../store/features/apiSlice";
import Icon from "../../../../../components/icon/Icon";
import { getGameStepStatusColor } from "../../gameFunctions";

const StepHeader = () => {
  const keywords = {
    games: "games",
    codeBoo: "codeBoo",
    codeCizz: "codeCizz",
  };
  const currentKeyword = window.location.pathname.split("/")[1];
  const keywordUrl = keywords[currentKeyword] || "games";

  const [sectionSteps, setSectionSteps] = useState(
    JSON.parse(localStorage.getItem("sectionSteps")) || []
  );

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(null);
  const { gameId, sectionId } = useParams();
  const id = gameId;
  const { data, isLoading, refetch } = useGameStepsWithSectionQuery({
    id,
    sectionId,
  });
  const steps = data || [];

  const gameLogoData = [
    { id: 1, image_path: "/images/games/uzay_logo.png" },
    { id: 2, image_path: "/images/games/kutup_logo.png" },
    { id: 3, image_path: "/images/games/ciftlik_logo.png" },
    { id: 4, image_path: "/images/games/codeali_logo.png" },
    { id: 6, image_path: "/images/games/galaksi_logo.png" },
    { id: 5, image_path: "/images/games/trafik_logo.png" },
    { id: 7, image_path: "/images/games/codeboo_logo.png" },
    { id: 8, image_path: "/images/games/codecizz_logo.png" },
  ];

  function getImagePathById(id) {
    const game = gameLogoData.find((item) => item.id === id);
    return game ? game.image_path : null;
  }
  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "https://gamelab.bilisimschool.com") {
        let gameResult = event.data.gameData;

        // Eğer oyun başarıyla tamamlandıysa
        if (gameResult?.status) {
          let userStatus = gameResult?.status === "success" ? 3 : 2;
          const newGame = { game: gameResult.game, user_status: userStatus };
          const updatedSectionSteps = sectionSteps.map((step) =>
            step.game === gameResult.game
              ? { ...step, user_status: userStatus }
              : step
          );
          const isExistingStep = sectionSteps.some(
            (step) => step.game === gameResult.game
          );
          const finalSectionSteps = isExistingStep
            ? updatedSectionSteps
            : [...updatedSectionSteps, newGame];

          setSectionSteps(finalSectionSteps);
          localStorage.setItem(
            "sectionSteps",
            JSON.stringify(finalSectionSteps)
          );
          console.log("object", gameResult);
          refetch();
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Event listener'ı temizle
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    //eslint-disable-next-line
  }, [sectionSteps]);

  if (isLoading) {
    return (
      <div
        className="w-100 h-100"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  return (
    <div className={`${style.tabContainer}`}>
      {steps?.map((tab, index) => {
        return (
          <div
            key={tab.id}
            className={`${style.tab} btn ${
              activeTab === tab.id ? `${style.active}` : ""
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            <div
              className={`${style.gameLogoContainer}`}
              onClick={() =>
                navigate(
                  `/${keywordUrl}/${gameId}/sections/${sectionId}/steps/${tab.id}`
                )
              }
            >
              <img
                src={getImagePathById(Number(gameId))}
                alt=""
                width={40}
                height={40}
                className={`${style.gameLogo} mx-auto d-block img-fluid`}
              />
              <span
                className={`${style.stepNumber} bg-${getGameStepStatusColor(
                  sectionSteps.some(
                    (game) => game.game === tab.id && Number(game.user_status)
                  ) || tab.user_status
                )}`}
              >
                {index + 1}
              </span>
            </div>
          </div>
        );
      })}
      <Icon
        icon={"home"}
        color={"success"}
        size="2x"
        className={`${style.homeIcon}`}
        onClick={() => handleNavigation("/games", `${gameId}`)}
      />
    </div>
  );
};

export default StepHeader;
