import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ width, height }) => {
	return (
		<></>

	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
