import React, { useContext, useEffect, useState } from "react";
import { externalLoginUser } from "./loginHelpers";
import useToken from "../../../utils/useToken";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../../store/auth-store";
import LoadingSpinner from "./login animation/LoadingSpinner";

const ExternalLogin = () => {
  const { setToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const cntTxt = useContext(AuthContext);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const schoolId = params.get("schoolId");
    const token = params.get("token");
    const credentials = { refresh: token };

    if (credentials && schoolId) {
      handleExternalLogin(credentials, schoolId);
    } else {
      setError("Geçersiz giriş bağlantısı, lütfen tekrar deneyin.");
      setTimeout(() => navigate("/login"), 3000);
    }
  }, [location.search, navigate]);

  async function handleExternalLogin(credentials, schoolId) {
    const response = await externalLoginUser({ credentials, schoolId });

    if (response.status === "success") {
      setToken({
        ...response,
        points: JSON.stringify(response.points),
      });

      cntTxt.login(response.token, { ...response });
      navigate("/");
    } else {
      setError(response.message || "Giriş başarısız, lütfen tekrar deneyin.");
      setTimeout(() => navigate("/login"), 3000);
    }
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      // background: "linear-gradient(135deg, #667eea, #764ba2)"
    },
    spinner: {
      marginBottom: 20,
    },
    text: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "white",
    },
    text2: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#000",
    },
    error: {
      fontSize: "20px",
      color: "red",
      fontWeight: "bold",
    },
  };

  return (
    <div style={error && styles.container} className="w-100">
      {error ? (
        <>
          <p style={styles.error}>{error}</p>
          <p style={styles.text2}>Giriş sayfasına yönlendiriliyorsunuz...</p>
        </>
      ) : (
        <>
          <div className="w-100 h-100">
            <LoadingSpinner />
          </div>
        </>
      )}
    </div>
  );
};

export default ExternalLogin;
