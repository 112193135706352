import { useDispatch, useSelector } from "react-redux";
import Checks from "../../components/bootstrap/forms/Checks";
import { setSelectTeacher } from "../../pages/t14/teacher/teacherSlice";
import useDarkMode from "../../hooks/useDarkMode";

export default function TeacherSwitch() {
  const dispatch = useDispatch();
  const { darkModeStatus } = useDarkMode();
  const isTeacher = useSelector((state) => state.teacher.selectTeacher);

  const handleChangeTeacherSwitch = () => {
    dispatch(setSelectTeacher(!isTeacher));
  };
  return (
    <>
      <div className="">
        <div
          className={`d-flex justify-content-center align-items-center`}
        >
             <span className="navigation-link-info" style={{ color: (!isTeacher && !darkModeStatus) ? "#000" : (isTeacher && darkModeStatus) ? "#000" :"#B6B5C8",
        fontWeight:!isTeacher && "700" }}>
            <span className="navigation-text me-2">Öğrenci</span>
          </span>
          <div className="fs-4 d-flex p-0">
          <Checks
            type="switch"
            className="fs-4 border"
            value={isTeacher}
            checked={isTeacher}
            onChange={() => handleChangeTeacherSwitch()}
             style={{
               backgroundColor: `${
                isTeacher ? "#6C5DD3" : "#fff"
               }`
             }}
          />
          </div>
          <span className="" style={{ color: (isTeacher && !darkModeStatus) ? "#000" : (!isTeacher && darkModeStatus) ? "#000": "#B6B5C8",
        fontWeight:isTeacher && "700" }}>
            <span className="navigation-text">Öğretmen</span>
          </span>
        </div>
      </div>
    </>
  );
}
