import React, { useEffect, useState } from "react";
import style from "./WhatsappBanner.module.scss";

const WhatsappBanner = ({ banner }) => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=905377776342&text=Talent14+kullan%C4%B1c%C4%B1s%C4%B1+olarak+ileti%C5%9Fime+ge%C3%A7iyorum.&type=phone_number&app_absent=0";
  return (
    <div className={`${style.bannerContainer}`}>
      <img src={banner} alt="Banner" />
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className={`${style.whatsappClickArea}`}
      >
        {/* Whatsapp link içeriği */}
      </a>
    </div>
  );
};

export default WhatsappBanner;
