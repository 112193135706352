import React from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../../../layout/Page/Page";
import { useUserSertificatesQuery } from "../../../../../../store/features/apiSlice";
import { useNavigate } from "react-router";
import style from "./CompetitionCertificate.module.scss";
import katilim_sertifikasi from "./katilim_sertifikasi.webp";
import CertificateThumbnail from "./DynamicThumbnail/CertificateThumbnail";

const CompetitionCertificate = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useUserSertificatesQuery();
  const showCertificate = (id) => {
    navigate(`certificates/${id}`);
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
        className="w-100"
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  const competitionCertificates = data?.filter(item => item.hasOwnProperty("competition")) || [];
  return (
    <PageWrapper title="Belgelerim">
      <Page>
        {competitionCertificates.length > 0 ? (
          <div className={style.certificateGrid}>
            {competitionCertificates.map((item) => (
              <div
                key={item.id}
                className={style.certificateCard}
                onClick={() => showCertificate(item.id)}
              >
                <CertificateThumbnail name={item.title} src={katilim_sertifikasi} />
              </div>
            ))}
          </div>
        ) : (
          <div className={style.emptyState}>
            <p>Henüz bir sertifikanız yok.</p>
          </div>
        )}
      </Page>
    </PageWrapper>
  );
};

export default CompetitionCertificate;
