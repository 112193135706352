import React from "react";
import style from "../dashboard.module.scss";
import { Megaphone } from "../../../../../components/icon/bootstrap";

export const AnnouncementsCard = () => {
  const announcements = [
    { text: "Yapay zeka dersleri eklendi!", type: "admin" },
    { text: "Hafta sonu kodlama etkinliği var!", type: "institution" },
    { text: "Ödev teslim tarihi yaklaşıyor.", type: "admin" },
  ];
  return (
    <div className={`${style.card} ${style.scrollableCard}`}>
      <h5 className={style.sectionTitle}>
        <Megaphone /> Duyurular
      </h5>
      <div className={style.scrollContent}>
        {announcements.map((announcement, index) => {
          return (
            <div key={index} className={`${style.taskBox}`}>
              <span className={`fs-3 me-2 p-1 pe-0`}>
                {announcement.type === "admin" && "🖥️"}
                {announcement.type === "institution" && "🧑‍🏫"}
              </span>
              <p className="mb-0 fw-bold text-light">{announcement.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
