import { simplePost, put } from "../../../utils/service";

export async function loginUser(credentials) {
    try {
      const response = await simplePost("auth/login/", credentials);
      const {data} = response;
      // console.table(data)
      return { status: "success", token: data.access, email: data.email, roles: data.role, name: data.first_name, surname: data.last_name, id: data.id, points:data.points, school:data.school, birth_date:data.birth_date, username:data.username, user_avatar: data.user_avatar, subscription_type:data.subscription_type, userRoleStatus:data.role_status, role_status_date:data.role_status_date, subscription_expiration_date:data.subscription_expiration_date, last_login:data.last_login};
        //, message: data.message, status: data.status, };
    } catch ({ response }) {
      if(response)
        return { status: response.data.status, message: response.data.message };
      
        return { status: 'error', message: 'Bilinmeyen bir hata oluştu!' };
    }
  }
  
export async function registerUser(url='auth/register', credentials) {
    return simplePost(url, credentials)
      .then(({ data }) => {
        return {
          status: "success",
          token: data.token,
          email: data.email,
          roles: data.roles,
          name: data.name,
          surname: data.surname,
          id: data.id,
        };
      })
      .catch(({ response }) => {
        if(!response)
            return { status: 'error', message: 'Bilinmeyen bir hata oluştu!' };
            
          return { status: response.data.status, message: response.data.message };
      });
  }

export async function forgotPassword(credentials) {
  try {
    const response = await simplePost("auth/forgot-password/", credentials);
    console.log(response);
    return { status: "success" };
  } catch ({ response }) {
    if(response)
      return { status: response.data.status, message: response.data.message };

    return { status: 'error', message: 'Bilinmeyen bir hata oluştu!' };
  }
}

export async function resetPassword(credentials) {
  try {
    const response = await put("auth/reset-password/", credentials);
    console.log(response);
    return { status: "success" };
  } catch ({ response }) {
    if(response)
      return { status: response.data.status, message: response.data.message };

    return { status: 'error', message: 'Bilinmeyen bir hata oluştu!' };
  }
}

export async function createPassword(credentials) {
  try {
    const response = await put("auth/passwordless/", credentials);
    console.log(response);
    return { status: "success" };
  } catch ({ response }) {
    if(response)
      return { status: response.data.status, message: response.data.message };

    return { status: 'error', message: 'Bilinmeyen bir hata oluştu!' };
  }
}
