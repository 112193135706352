import React, { useContext, useState } from "react";
import Button from "../../../../components/bootstrap/Button";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Spinner from "../../../../components/bootstrap/Spinner";
import { AuthContext } from "../../../../store/auth-store";
import {
  useTeacherRegisterMutation,
} from "../../../../store/features/apiSlice";
import showNotification from "../../../../components/extras/showNotification";
import Icon from "../../../../components/icon/Icon";
import TeacherDocumentModal from "./TeacherDocumentModal";
import style from "../customLogin/login.module.scss";


const TeacherForm = ({ setError, setIsNewUser }) => {
  const [postTeacherRegister] = useTeacherRegisterMutation();
  const [busy, setBusy] = React.useState(false);
  const [isOpenDocementModal, setIsOpenDocumentModal] = useState(false);
  const [publicId, setPublicId] = useState(null);
  const cntTxt = useContext(AuthContext);

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "Bu alan zorunludur.";
    }
    if (!values.last_name) {
      errors.last_name = "Bu alan zorunludur.";
    }

    if (!values.email) {
      errors.email = "Email adresi gereklidir.";
    }
    if (
      values.email.length > 0 &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Geçersiz email adresi.";
    }
    if (!values.telephone || values.telephone.includes("_")) {
      errors.telephone = "Telefon gereklidir.";
    }
    if (!document) {
      console.log(document);
      errors.graduation = "Belge gereklidir.";
    }
    if (!values.password) {
      errors.password = "Şifre gereklidir.";
    }
    if (
      values.password.length > 0 &&
      !/[a-z|A-Z]/.test(values.password)
    ) {
      errors.password = "Şifre en az bir harf içermelidir.";
    }
    if (
      values.password.length > 0 &&
      !/[0-9]/.test(values.password)
    ) {
      errors.password = "Şifre en az bir rakam içermelidir.";
    }
    else if (values.password.length > 0 && values.password.length < 8) {
      errors.password = "Şifre en az 8 karakter olmalıdır.";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Şifre tekrarı gereklidir.";
    }
    if (!errors.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = "Şifreler eşleşmiyor.";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      telephone: "",
      confirmPassword: "",
    },
    validate,
    onSubmit: async (values) => {
      setBusy(true);
      let teacherValues = {
        email: values.email,
        password: values.password,
        first_name: values.first_name,
        last_name: values.last_name,
        telephone: values.telephone,
      };

      const response = await postTeacherRegister({
        data: teacherValues,
      });
      if (response.error) {
        console.log(response.error);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Uyarı</span>
          </span>,
          <p style={{ fontSize: "14px" }}>Bu email zaten kullanılmaktadır.</p>,
          "warning"
        );
      } else {
        setPublicId(response.data.user.public_id)
        setIsOpenDocumentModal(true)
        cntTxt.login(response.token, {
          email: response.email,
          roles: response.roles,
          first_name: response.first_name,
          last_name: response.last_name,
          id: response.id,
        });
      }
      setBusy(false);
      return;
    },
  });

  return (
    <>
    <TeacherDocumentModal
    isOpenDocementModal={isOpenDocementModal}
    setIsOpenDocumentModal={setIsOpenDocumentModal}
    publicId={publicId}
    setIsNewUser={setIsNewUser}
    />
      <div className="col-12">
        <FormGroup id="signup-email" isFloating label="Email">
          <Input
            name="email"
            type="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.email}
            invalidFeedback={formik.errors.email}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup isFloating label="Ad">
          <Input
            name="first_name"
            autoComplete="given-name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.first_name}
            invalidFeedback={formik.errors.first_name}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup isFloating label="Soyad">
          <Input
            name="last_name"
            autoComplete="family-name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.last_name}
            invalidFeedback={formik.errors.last_name}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup isFloating label="Telefon" id="telephone">
          <Input
            type="text"
            mask="999 999 99 99"
            placeholder="Telefon numarası"
            required
            onChange={formik.handleChange}
            value={formik.values.telephone}
            onBlur={formik.handleBlur}
            isValid={formik.isValid}
            isTouched={formik.touched.telephone}
            invalidFeedback={formik.errors.telephone}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup isFloating label="Şifre">
          <Input
            name="password"
            type="password"
            autoComplete="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.password}
            invalidFeedback={formik.errors.password}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup isFloating label="Şifre (Tekrar)">
          <Input
            name="confirmPassword"
            type="password"
            autoComplete="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            isValid={formik.isValid}
            onBlur={formik.handleBlur}
            isTouched={formik.touched.confirmPassword}
            invalidFeedback={formik.errors.confirmPassword}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <span style={{color:"#fff"}}>
          Hesap oluşturarak Bilişim School A.Ş.'nin{" "}
          <a
            href="https://talent14.com/uyelik-sozlesmesi-ve-site-kullanim-kosullari/"
            target="_blank"
            rel="noopener noreferrer"
            className="fw-bold"
            style={{color:"#fff"}}
          >
            Üyelik Sözleşmesi
          </a>{" "}
          ile{" "}
          <a
            href="https://talent14.com/gizlilik-ve-cerez-politikasi/"
            target="_blank"
            rel="noopener noreferrer"
            className="fw-bold"
            style={{color:"#fff"}}
          >
            Çerez ve Gizlilik Politikası
          </a>{" "}
          koşullarını kabul etmiş olursunuz.
        </span>
      </div>
      <div className={`${style.saveAndLoginButton} col-12 mb-3`}>
        <Button
          color="info"
          className="w-100 py-3"
          onClick={formik.handleSubmit}
        >
          {busy && (
            <Spinner color="primary" inButton isSmall>
              Loading...
            </Spinner>
          )}
          Kayıt Ol
        </Button>
      </div>
    </>
  );
};

export default TeacherForm;
