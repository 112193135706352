// src/analytics.js

import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize("G-93S33TERBB"); // kendi ölçüm ID'n
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

// ✅ Referrer kaynağını algılayıp event olarak gönder
export const logReferrerSource = () => {
  const rawRef = document.referrer;
  let refKaynak = "Doğrudan";

  const refSources = {
    "google.": "Google",
    "linkedin.": "LinkedIn",
    "instagram.": "Instagram",
    "facebook.": "Facebook",
    "twitter.": "Twitter",
    "x.com": "Twitter",
    "t.co": "Twitter (kısa)",
    "youtube.": "YouTube",
    "tiktok.": "TikTok",
    "pinterest.": "Pinterest",
  };

  for (const key in refSources) {
    if (rawRef.includes(key)) {
      refKaynak = refSources[key];
      break;
    }
  }

  if (refKaynak === "Doğrudan" && rawRef) {
    refKaynak = rawRef; // tanımlanamayan ama boş olmayan kaynak
  }

  logEvent("trafik_kaynagi", "referrer", refKaynak);
};

// analytics.js
export const logUTMParams = () => {
    const params = new URLSearchParams(window.location.search);
  
    const source = params.get("utm_source");
    const medium = params.get("utm_medium");
    const campaign = params.get("utm_campaign");
  
    if (source || medium || campaign) {
      logEvent("utm_kampanya", "kaynak", `source=${source}, medium=${medium}, campaign=${campaign}`);
    }
  };
  
