import {
    createSlice,
  } from '@reduxjs/toolkit'
import { menuList } from './homeworkManagement/teacher-homeworks/menuList'

const initialState={
    menu:menuList.LESSONS,
    showCreateLesson:false,
    selectedClass:[],
    selectedLessonId:null, 
    selectedHomeWork:null,
    selectedPath:null,
    selectedPathId:null,
    selectedLectureId:null,
    paths:[],
    practices:[],
    students:[],
    content:{
        course_lessons:[],
        learning_paths:[]
    },
    selectTeacher:true,
}

const teacherSlice = createSlice({
    name:"teacher",
    initialState,
    reducers:{
        setShowCreateLesson:(state,action)=>{
            state.showCreateLesson = action.payload
        },
        setSelectedClass:(state,action)=>{
            state.selectedClass = action.payload
        },
        setPractices:(state,action)=>{
            state.practices = action.payload
        },
        setStudents:(state,action)=>{
            state.students = action.payload
        },
        setMenu: (state, action) => {
            state.menu = action.payload
        },
        setSelectedLessonId:(state,action)=>{
            state.selectedLessonId = action.payload
        },
        setSelectedPathId:(state,action)=>{
            state.selectedPathId = action.payload
        },
        setSelectedLectureId:(state,action)=>{
            state.selectedLectureId = action.payload
        },
          setSelectedHomework:(state,action)=>{
            state.selectedHomeWork = action.payload
        },
        setContentCourses:(state,action)=>{
            state.content = {...state.content,course_lessons:action.payload};
        },
        setPath:(state,action)=>{
            state.selectedPath = action.payload;
        },
        setPaths:(state,action)=>{
            state.paths = action.payload;
        },
        setSelectTeacher:(state,action)=>{
            state.selectTeacher = action.payload
        },
    }
})

export const {setPath,setPaths,setSelectedHomework,setSelectedPathId,setSelectedLessonId,setMenu,setShowCreateLesson,setSelectedClass,setPractices,setStudents,setContentCourses,setSelectedLectureId,setSelectTeacher} = teacherSlice.actions;

export default teacherSlice;