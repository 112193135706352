import React from "react";
import style from "./TrailsWrapper.module.scss";
import USER_SUBSCRIPTION_TYPE from "../../../../../common/data/enumUserSubscriptionType";
import { useNavigate } from "react-router";
import _ from "underscore";
import Button from "../../../../../components/bootstrap/Button";
import MDEditor from "@uiw/react-md-editor";
const TrailsContent = ({ courses }) => {
  console.log(courses);
  const userSubscriptionType = localStorage.getItem("subscription_type");
  // const userSubscriptionType = 1;
  const isPremium =
    Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.PREMIUM.id;
  const isStandard =
    Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id;
  // const isFree = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id;

  const freeCourses = [13, 14, 15, 101, 179, 180, 181, 182, 183, 184];
  const standardCourses = [
    115, 106, 19, 69, 70, 71, 72, 94, 105, 112, 113, 114, 116, 117, 118,
  ];
  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };

  return (
    <div className={`${style.trailContent}`}>
      {courses?.map((item) => {
        const canAccessCourse =
          freeCourses.includes(item.id) ||
          (standardCourses.includes(item.id) && isStandard) ||
          isPremium;
        // const is_draft = item.status === COURSE_STATUS.DRAFT.id;
        return (
          <div className={`${style.courseCard} pt-3`}>
            <div className={`${style.courseImage}`}>
              <img
                src={`/images/${item.technology}.png`}
                alt=""
                width={128}
                height={128}
                className="mx-auto d-block img-fluid mb-3"
              />
            </div>
            <ProgressBar percentage={item.percentage} darkMode={false} />
            <div className={`${style.courseDetails}`}>
              <p className={`${style.courseTitle} m-0 p-0`}>{item.title}</p>
              <MDEditor.Markdown
                className={`${style.courseDescription}`}
                source={
                  item.description ??
                  `Bu dersi tamamladıklarında öğrencilerin;
- **Bilgisayarın zaman içerisindeki değişimi ve gelişimi** hakkında bilgi sahibi olmaları  
- **Temel seviyede bilgisayar kullanım bilgisine** erişmeleri hedeflenmektedir.`
                }
              />
            </div>
            <div className={`${style.buttonContainer}`}>
              <Button
                color={canAccessCourse ? "dark" : "danger"}
                className={`${style.button} btn ${
                  canAccessCourse ? "btn-myButton" : "btn-myDanger"
                } mb-4 shadow-3d-up-hover ${
                  canAccessCourse ? "shadow-3d-myButton" : "shadow-3d-myDanger"
                }`}
                size="lg"
                tag="a"
                to={
                  canAccessCourse
                    ? `/lessons/AllLessonsAndContent/${item.id}`
                    : undefined
                }
                onClick={
                  !canAccessCourse
                    ? () => handleNavigation(`/profile`, "Account Details")
                    : undefined
                }
              >
                {canAccessCourse ? "Öğrenmeye Başla" : "Planı Yükselt"}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TrailsContent;

export const ProgressBar = ({ percentage, darkMode }) => {
  return (
    <div
      className={`${style.progressContainer} ${
        darkMode ? "dark-mode" : "light-mode"
      }`}
    >
      <div
        className={`${style.progressBar}`}
        style={{ width: `${percentage}%` }}
      >
        <span className={`${style.progressText}`}>{percentage}%</span>
      </div>
    </div>
  );
};
