import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../../../components/bootstrap/Card";
import Icon from "../../../../../../components/icon/Icon";
import PaginationButtons, {
  dataPagination,
} from "../../../../../../components/PaginationButtons";
import useSortableData from "../../../../../../hooks/useSortableData";
import useSelectTable from "../../../../../../hooks/useSelectTable";
import Checks from "../../../../../../components/bootstrap/forms/Checks";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Confirm from "../../../../../common/Confirm";
import { CATEGORY_ACCESS_STATUS } from "../../../../../../common/data/enumCyber";

function Button(props) {
  return (
    <button type="button" className={`btn btn-light-${props.class}`}>
      {props.children}
    </button>
  );
}

const CategoryTable = ({
  categories = [],
  deleteItem,
  filteredCategories = [],
  handleCategory,
}) => {
  const active = <Button class="success">Aktif</Button>;
  const passive = <Button class="danger">Pasif</Button>;
  const unknown = <Button class="secondary">Bilinmiyor</Button>;
  const publicPath = <Button class="success">Herkese Açık</Button>;
  const privatePath = <Button class="danger">Özel</Button>;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const currentCategories = filteredCategories ?? categories;

  const { items, requestSort, getClassNamesFor } =
    useSortableData(currentCategories);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => deleteItem(item));
    }
    setOpen(false);
    setSelected([]);
  };

  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);

  return (
    <>
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handlCancel}
      />
      {selected.length > 0 ? (
        <button
          className="btn btn-danger"
          style={{ display: "block", position: "fixed", right: "100px" }}
          onClick={() => {
            setSelected(selected);
            setOpen(true);
          }}
        >
          Seçilenleri Sil
        </button>
      ) : (
        <button
          style={{ display: "none" }}
          onClick={() => {
            setSelected(selected);
            setOpen(true);
          }}
        >
          Seçilenleri Sil
        </button>
      )}

      <Card>
        <CardBody>
          <table className="table table-modern">
            <thead>
              <tr>
                <th style={{ width: "10px" }}>{SelectAllCheck}</th>
                <th onClick={() => requestSort("title")}>
                  Başlık
                  <Icon
                    size="lg"
                    className={getClassNamesFor("title")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("description")}>
                  Açıklama
                  <Icon
                    size="lg"
                    className={getClassNamesFor("description")}
                    icon="FilterList"
                  />
                </th>
                <th
                  onClick={() => requestSort("status")}
                  style={{ textAlign: "center" }}
                >
                  Aktif Mi
                  <Icon
                    size="lg"
                    className={getClassNamesFor("status")}
                    icon="FilterList"
                  />
                </th>
                <th
                  onClick={() => requestSort("access_status")}
                  style={{ textAlign: "center" }}
                >
                  Erişim Durumu
                  <Icon
                    size="lg"
                    className={getClassNamesFor("access_status")}
                    icon="FilterList"
                  />
                </th>
                <th style={{ textAlign: "center" }}>Eylemler</th>
              </tr>
            </thead>
            <tbody>
              {!(currentCategories && currentCategories.length > 0) ? (
                <div>Kategori bulunmuyor</div>
              ) : (
                currentCategories.map((item) => (
                  <tr key={item.id.toString()}>
                    <td>
                      <Checks
                        id={item.id.toString()}
                        name="selectedList"
                        value={item.id}
                        onChange={selectTable.handleChange}
                        checked={selectTable.values.selectedList.includes(
                          item.id.toString()
                        )}
                      />
                    </td>
                    <td style={{ textAlign: "justify" }}>{item.title}</td>
                    <td style={{ textAlign: "justify" }}>{item.description}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.status ? active : passive}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.access_status === CATEGORY_ACCESS_STATUS.PRIVATE.id
                        ? privatePath
                        : item.access_status ===
                          CATEGORY_ACCESS_STATUS.PUBLIC.id
                        ? publicPath
                        : unknown}
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setSelected([item.id]);
                            setOpen(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleCategory(item.id)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </CardBody>
        <PaginationButtons
          data={items}
          label="kategori"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
    </>
  );
};

export default CategoryTable;
