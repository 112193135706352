import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Button from "../../../../components/bootstrap/Button";
import { forgotPassword } from "../loginHelpers";
import Alert from "../../../../components/bootstrap/Alert";
import Spinner from "../../../../components/bootstrap/Spinner";
import { useFormik } from "formik";
import { ArrowBackIosNew, EmailOutlined } from "@mui/icons-material";
import style from "../customLogin/login.module.scss"
import loginBrandLogo from "../../../../assets/loginBrandLogo.png";
import loginComputer from "../../../../assets/loginComputer.png";

const ForgotPassword = () => {
  const [sentEmail, setSentEmail] = useState(false);
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email adresi gerekli!";
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      setBusy(true);

      const response = await forgotPassword({
        email: values.email,
      });
      if (response.status === "success") {
        setSentEmail(true);
      } else setError(response.message);
      setBusy(false);
    },
  });

  return (
    <PageWrapper
      title="Forgot Password"
      className={`${style.loginBackground}`}
    >
      <Page className="p-0">
        {error !== "" && (
          <Alert borderWidth={0} className="" color="danger" icon="Warning">
            {error}
          </Alert>
        )}
        <div className={`${style.loginContainer}`}>
        <div className={`${style.loginContainerLeft}`}>
          <div className={`${style.logo}`}>
            <img src={loginBrandLogo} alt="" />
          </div>
          <div className={`${style.imgDiv}`}>
            <img src={loginComputer} alt="" />
          </div>
        </div>
        <div className={`${style.loginContainerRight} px-5`}>
        <div className={`${style.arrowBackDiv} mb-1`}>
                  <Link
                    to="/login"
                    className={`${style.arrowBackLogo} mb-1`}
                  >
                    <ArrowBackIosNew/> Geri
                  </Link>
                </div>
                <div className={`${style.forgotPasswordTitle} mb-1`}>Şifremi Unuttum</div>
                <div className={`${style.forgotPasswordTitleMessage} mb-1`}>
                  Kullanıcı adınızı veya e-mailinizi girin.
                </div>

                {sentEmail &&
                  <div className="alert alert-primary shadow flex-nowrap" role="alert">
                    <EmailOutlined className="me-2"/> Şifre sıfırlama linki sistemde kayıtlı e-mailinize gönderildi.
                  </div>
                }

                <form className="row g-4">
                  <div className="col-12">
                    <FormGroup
                      isFloating
                      label="Kullanıcı adın veya emailin"
                    >
                      <Input
                        name="email"
                        value={formik.email}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.email}
                        invalidFeedback={formik.errors.email}
                        autoComplete=""
                      />
                    </FormGroup>
                  </div>
                  <div className={`${style.saveAndLoginButton} col-12`}>
                    <Button
                      color="info"
                      className="w-100 py-3"
                      onClick={formik.handleSubmit}
                      disabled={busy}
                    >
                      {busy && (
                        <Spinner color="primary" inButton isSmall>
                          Loading...
                        </Spinner>
                      )}
                      Şifremi Sıfırla
                    </Button>
                  </div>
                </form>
        </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default ForgotPassword;
