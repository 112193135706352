import React, { useState } from "react";
import style from "./Cyber14Wrapper.module.scss";
import Page from "../../../../layout/Page/Page";
import Card from "../../../../components/bootstrap/Card";
import CTFWrapper from "./CTF/CTFWrapper";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Icon from "../../../../components/icon/Icon";

const Cyber14Wrapper = () => {
  const _activeTab = sessionStorage.getItem("cyber_activeTab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <CTFWrapper />,
      tabName: "CTF",
      icon1: "FlagFill",
      icon2: null,
    },
    {
      content: null,
      tabName: "Kaynaklar",
      icon1: "Folder",
      icon2: null,
    },
    {
      content: null,
      tabName: "Makineler",
      icon1: "web",
      icon2: null,
    },
  ];

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("cyber_activeTab", tabNumber);
      setActiveTab(tabNumber);
    }
  };


  return (
    <PageWrapper title={"Cyber"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <Card className="h-100 mt-3 mx-lg-3">
          <div className="page container-xxl">
            <div
              className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto px-3 gap-1`}
            >
              {tabContents.map((tab, index) => (
                <div
                  key={index}
                  className={`${style.tab} ${
                    activeTab === index + 1 ? `${style.activetab}` : ""
                  } d-flex justify-content-center align-items-center gap-2 p-3`}
                  onClick={() => handleTabClick(index + 1)}
                >
                  <div className={`${style.tabsLogo}`}>
                    <Icon
                      icon={tab.icon1}
                      size="2x"
                      className="me-2"
                      style={{
                        position: "relative",
                        bottom: "4px",
                        right: "5px",
                      }}
                    />
                  </div>
                  <div className={`${style.tabsDescription}`}>
                    <span>{tab.tabName}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="tab-content">
              {tabContents[activeTab - 1] &&
                tabContents[activeTab - 1]?.content}
            </div>
          </div>
        </Card>
      </Page>
    </PageWrapper>
  );
};

export default Cyber14Wrapper;
