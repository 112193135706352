import React, { useEffect } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Logo from "../../../../components/Logo";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import { useFormik } from "formik";
import { useCreateCourseMutation } from "../../../../store/features/apiSlice";
import showNotification from "../../../../components/extras/showNotification";
import { useGetSchoolsQuery } from "../../teacher/teacherApi";
import { CoursePrograms } from "./constant/CourseProgramList";
import CustomFormField from "../../../../common/customFormField/CustomFormField";
import { getCustomErrorMessage, handleErrorMessage } from "../../../../utils/customErrors";

const CreateCourseModal = ({
  isOpenNewCourseModal,
  setIsOpenNewCourseModal,
  isPartner
}) => {
  const [createCourse] = useCreateCourseMutation();
  const { data: schools } = useGetSchoolsQuery();
  const schoolsList =
    schools &&
    schools.map((item) => {
      return {
        text: item.title,
        value: item.id,
      };
    });

  useEffect(() => {
    getSchoolStatus();
    //eslint-disable-next-line
  }, [isPartner]);

  function getSchoolStatus() {
    if (isPartner) {
      formik.setFieldValue("school_status", 3);
    } else {
      formik.resetForm()
      formik.setFieldValue("school_status", 1);
    }
  }
  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }

    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }

    if (!values.technology) {
      errors.technology = "Program dilini seçiniz";
    }
    if (values.school_status === 3 && !values.school) {
      errors.school = "Partner seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      technology: "",
      order_number: 9999,
      status: 1,
      level: null,
      school: null,
      school_status: null,
      chapters: [
        {id:null,
        title: "Örnek Ünite",
        order_number: 9999,
        lessons: [
          {
            id:null,
            title: "Örnek video",
            order_number: 9999,
            description: "Bu video taslak olarak konulmuştur.",
            video: `<iframe width="1118" height="629" src="https://www.youtube.com/embed/lbhbT0hu8Z4" title="CodeLab Platformu | Bilişim School" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
            subscription_type:isPartner ? 3 : 1,
            points:5
          },
        ],
        learning_path: null
      }]
    },
    validate,
    onSubmit: () => {
      handleCourse();
    },
  });

  const handleCourse = async () => {
    const courseValues = { ...formik.values };
    const response = await createCourse(courseValues);
    if (response.error) {
      const customError = handleErrorMessage(response.error.data)
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>{getCustomErrorMessage(customError)}</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Kayıt Başarılı</span>
        </span>,
        "Ders başarıyla eklendi.",
        "success"
      );
    }
    setIsOpenNewCourseModal(false);
    formik.resetForm();
    getSchoolStatus()
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenNewCourseModal}
      setIsOpen={setIsOpenNewCourseModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenNewCourseModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Ders</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        >
          <div className="row gap-3 d-flex justify-content-center">
            <div className="col-lg-8">
              <CustomFormField type="text" label="Başlık" name={"title"} formik={formik}/>
            </div>
            <div className="col-lg-8">
              <CustomFormField type="textArea" label="Açıklama/Not" name={"description"} formik={formik} style={{ height: "150px" }}/>
            </div>
            <div className="col-lg-8">
              <CustomFormField type="select"  label="Programlama Dili" name={"technology"} options={CoursePrograms} formik={formik}/>
            </div>
            <div className="col-lg-8">
              <CustomFormField type="text" label="Sıra Numarası" name={"order_number"} formik={formik}/>
            </div>
            <div className="col-lg-8">
              <CustomFormField type="select"  label="Aktiflik" name={"status"} options={[
                    { value: "0", text: "Pasif" },
                    { value: "1", text: "Aktif" },
                    { value: "2", text: "Taslak" },
                  ]} formik={formik}/>
            </div>
            {isPartner && (
              <>
                <div className="col-lg-8">
                  <CustomFormField type="select"  label="Partner Seç" name={"school"} options={schoolsList} formik={formik}/>
                </div>
                <div className="col-lg-8">
                  <CustomFormField type="select"  label="Ders Kaynağı" name={"school_status"} options={[
                    { value: "1", text: "Default" },
                    { value: "2", text: "Okul" },
                    { value: "3", text: "Partner" },
                  ]} formik={formik} disabled={true}/>
                </div>
              </>
            )}
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateCourseModal;
