export const handleDownload = async (CTFId, setLoading, triggerDownload) => {
  setLoading(true);
  try {
    const response = await triggerDownload({ id: CTFId }).unwrap();

    if (!response) {
      console.error("Dosya alınamadı!");
      return;
    }

    let fileName = `CTF_${CTFId}`;
    let mimeType = "application/octet-stream"; // Varsayılan MIME türü

    // Dosya adı belirleme
    if (response.fileName) {
      fileName = response.fileName;
    } else if (response.headers?.["content-disposition"]) {
      const contentDisposition = response.headers["content-disposition"];
      const match = contentDisposition.match(/filename="(.+)"/);
      if (match && match[1]) {
        fileName = match[1];
      }
    }

    if (response.type) {
      mimeType = response.type;
    }

    // **Eğer dosya bir resimse, yeni sekmede aç**
    const fileTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/webp",
      "application/pdf",
    ];
    const blob = new Blob([response], { type: mimeType });

    if (fileTypes.includes(mimeType)) {
      const imageUrl = URL.createObjectURL(blob);
      window.open(imageUrl, "_blank"); // Yeni sekmede aç
      return;
    }

    // **Diğer dosyalar indirilsin**
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Belleği temizle
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Dosya indirilemedi:", error);
  } finally {
    setLoading(false); // Yükleniyor durumu sona erdi
  }
};

export const getFileExtension = (fileName) => {
  const fileExtension = fileName?.split(".").pop().toUpperCase();
  return fileExtension;
};
