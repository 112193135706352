import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import Brand from "../Brand/Brand";
import Navigation from "../Navigation/Navigation";
import User from "../User/User";
import {
  studentPanel,
  teacherPanel,
  adminPages,
  schoolManagerPages,
} from "../../menu";
import ThemeContext from "../../contexts/themeContext";
import useAsideTouch from "../../hooks/useAsideTouch";
import { AuthContext } from "../../store/auth-store";
import USER_ROLES from "../../common/data/enumUserRole";
import Collapse from "../../components/bootstrap/Collapse";
import { useSelector } from "react-redux";
import TEACHER_ROLE_STATUS from "../../common/data/enumTeacherRoleStatus";
import { useGetAllSchoolRestrictionsQuery } from "../../pages/t14/admin/school management/school restrictions/restrictionApi";

const Aside = () => {
  const asideRef = useRef(null)
  const { user = { roles: 1 } } = useContext(AuthContext);
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const isTeacherSwitch = useSelector((state) => state.teacher.selectTeacher);
  const schoolId = localStorage.getItem("school");
  const isSchoolId = schoolId > 0;
  const teacherStatus = localStorage.getItem("userRoleStatus");
  const isPending = Number(teacherStatus) === TEACHER_ROLE_STATUS.PENDING;
  const isUnauthorizedTeacher = isPending;
  const { asideStyle, touchStatus, hasTouchButton } = useAsideTouch();

  const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === "true";
  const isAdmin = USER_ROLES.ADMIN.id === user?.roles || false;
  const isStudent = USER_ROLES.STUDENT.id === user?.roles || false;
  const isTeacher = USER_ROLES.TEACHER.id === user?.roles || false;
  const isSchoolManager = USER_ROLES.SCHOOL_MANAGER.id === user?.roles || false;
  const { pages, ...rest } = studentPanel;
  const schoolStudentPanel = studentPanel;

  const { data } = useGetAllSchoolRestrictionsQuery(schoolId, {skip:!schoolId || isAdmin});

  const restrictions = {
    "lessons": data && data[0].course,
    "learningPaths": data && data[0].codelab,
    "games": data && data[0].codemino,
    "codeBoo": data && data[0].codeboo,
    "codeÇizz": data && data[0].codecizz,
    "codeArt": data && (data[0].codemino && data[0].codeboo && data[0].codecizz),
    "practice": data && data[0].codelab,
  };
  const modifiedStudentMenuList = {};
  
  for (const [key, value] of Object.entries(schoolStudentPanel)) {
    if (restrictions[key] === false) {
      continue;
    }
    modifiedStudentMenuList[key] = value;
  }

  
  const modifiedTeacherMenuList = {};
  for (const [key, value] of Object.entries(teacherPanel)) {
    if (restrictions[key] === false) {
      continue;
    }
    modifiedTeacherMenuList[key] = value;
  }
const [asideHover, setAsideHover] = useState(false)
  useEffect(() => {
    const handleMouseOver = () => {
      if (asideRef.current) {
        setAsideHover(true)
      }
    };

    const handleMouseOut = () => {
      if (asideRef.current) {
        setAsideHover(false)
      }
    };

    const divElement = asideRef.current;
    if (divElement) {
      divElement.addEventListener('mouseover', handleMouseOver);
      divElement.addEventListener('mouseout', handleMouseOut);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (divElement) {
        divElement.removeEventListener('mouseover', handleMouseOver);
        divElement.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, []);
  
  if (!user) return null;

  return (
    <motion.aside
      style={asideStyle}
      ref={asideRef}
      className={classNames(
        "aside",
        { open: asideStatus },
        {
          "aside-touch-bar": hasTouchButton && isModernDesign,
          "aside-touch-bar-close":
            !touchStatus && hasTouchButton && isModernDesign,
          "aside-touch-bar-open":
            touchStatus && hasTouchButton && isModernDesign,
        }
      )}
    >
      <div className="aside-head">
        <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
      </div>

      <div className="aside-body">
        {isStudent && isSchoolId && (
          <Navigation menu={modifiedStudentMenuList} id="aside-dashboard" asideHover={asideHover} asideBottom={false}/>
        )}
        {isStudent && !isSchoolId && (
          <Navigation menu={studentPanel} id="aside-dashboard" asideHover={asideHover} asideBottom={false}/>
        )}
        {isUnauthorizedTeacher && (
          <Navigation menu={studentPanel} id="aside-dashboard" asideHover={asideHover} asideBottom={false}/>
        )}
        {isTeacher && !isUnauthorizedTeacher && !isSchoolId &&(
          <>
            <Collapse isOpen={isTeacherSwitch}>
              <Navigation menu={teacherPanel} id="aside-dashboard" />
            </Collapse>
            <Collapse isOpen={!isTeacherSwitch}>
              <Navigation menu={rest} id="aside-dashboard" />
            </Collapse>
          </>
        )}
        {isTeacher && !isUnauthorizedTeacher && isSchoolId && (
          <>
            <Collapse isOpen={isTeacherSwitch}>
              <Navigation menu={modifiedTeacherMenuList} id="aside-dashboard" />
            </Collapse>
            <Collapse isOpen={!isTeacherSwitch}>
              <Navigation menu={modifiedStudentMenuList} id="aside-dashboard" />
            </Collapse>
          </>
        )}
        {isSchoolManager && (
          <Navigation menu={schoolManagerPages} id="aside-dashboard" />
        )}
        {isAdmin ? <Navigation menu={adminPages} id="aside-dashboard" /> : null}
      </div>

      <div className="aside-foot">
        <User />
      </div>
    </motion.aside>
  );
};

export default Aside;
