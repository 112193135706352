import React, { useState } from "react";
import style from "../dashboard.module.scss";
import HomeworkDetailModal from "./HomeworkDetailModal";
import Icon from "../../../../../components/icon/Icon";

export const HomeworkTasksCard = ({ activeHomeworks }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHomeworkId, setSelectedHomeworkId] = useState(null);

  const today = new Date();

  const getStatus = (homework) => {
    const endDate = new Date(homework.end_date);
    const isCompleted = homework.student_status === 2;
    const isDeadlinePassed = endDate < today;

    if (isCompleted) return "success";
    if (!isCompleted && isDeadlinePassed) return "failed";
    return "pending";
  };

  const tasks =
    activeHomeworks?.map((hw) => ({
      id: hw.id,
      name: hw.title,
      status: getStatus(hw),
    })) || [];

  const total = tasks.length;
  const completed = tasks.filter((t) => t.status === "success").length;
  const completionRate = total ? Math.round((completed / total) * 100) : 0;

  const openModal = (id) => {
    setSelectedHomeworkId(id);
    setIsModalOpen(true);
  };

  return (
    <div
      className={`${style.card} ${style.scrollableCard} ${style.homeworkCard}`}
    >
      <h5 className={style.sectionTitle}>
        <Icon icon="MenuBook" size="2x" />
        Ödevlerim
      </h5>
      <p className="mb-1 fw-bold text-primary fs-5">
        📖 Ödev Tamamlama Oranı: <strong>%{completionRate}</strong>
      </p>
      <div className="progress mb-2">
        <div
          className="progress-bar bg-success"
          style={{ width: `${completionRate}%` }}
        ></div>
      </div>
      <hr className="my-2" />

      <div className={style.scrollHomeworkContent}>
        {tasks.map((task, index) => {
          const textColor =
            task.status === "success"
              ? "text-light"
              : task.status === "failed"
              ? "text-light"
              : "text-light";

          return (
            <div
              key={index}
              className={style.taskBox}
              onClick={() => openModal(task.id)}
              style={{ cursor: "pointer" }}
            >
              <span className={`fs-3 me-2 p-1 pe-0`}>
                {task.status === "success" && "🌟"}
                {task.status === "pending" && "⏳"}
                {task.status === "failed" && "🔴"}
              </span>
              <p className={`mb-0 fw-bold ${textColor}`}>{task.name}</p>
            </div>
          );
        })}
      </div>

      {/* Detay Modal */}
      <HomeworkDetailModal
        isOpenHomeworkDetailModal={isModalOpen}
        setIsOpenHomeworkDetailModal={setIsModalOpen}
        homeworkId={selectedHomeworkId}
      />
    </div>
  );
};
