import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../../../components/bootstrap/Card";
import Icon from "../../../../../../components/icon/Icon";
import PaginationButtons, {
  dataPagination,
} from "../../../../../../components/PaginationButtons";
import useSortableData from "../../../../../../hooks/useSortableData";
import useSelectTable from "../../../../../../hooks/useSelectTable";
import Checks from "../../../../../../components/bootstrap/forms/Checks";
import { IconButton, Tooltip } from "@mui/material";
import { Check, Delete, Edit } from "@mui/icons-material";
import Confirm from "../../../../../common/Confirm";
import {
  CTF_DIFFICULTY,
  CTF_STATUS,
} from "../../../../../../common/data/enumCyber";
import { useLazyGetCTFFileQuery } from "../../../../../../store/features/cyberApi";
import LoadingSpinner from "../../../../../../utils/LoadingSpinner";
import { getFileExtension, handleDownload } from "./CTFUtils";

function Button(props) {
  return (
    <button type="button" className={`btn btn-light-${props.class}`}>
      {props.children}
    </button>
  );
}
const active = <Button class="success">Aktif</Button>;
const passive = <Button class="danger">Pasif</Button>;
const unknown = <Button class="secondary">Bilinmiyor</Button>;
const difficultyButtons = {
  [CTF_DIFFICULTY.EASY.id]: <Button class="success">Kolay</Button>,
  [CTF_DIFFICULTY.MEDIUM.id]: <Button class="warning">Orta</Button>,
  [CTF_DIFFICULTY.HARD.id]: <Button class="danger">Zor</Button>,
  [CTF_DIFFICULTY.IMPOSSIBLE.id]: <Button class="dark">İmkansız</Button>,
};

const CTFTable = ({
  questions = [],
  deleteItem,
  filteredCTF = [],
  handleCTF,
  testCTF,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [triggerDownload] = useLazyGetCTFFileQuery();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const currentCTF = filteredCTF ?? questions;

  const { items, requestSort, getClassNamesFor } = useSortableData(currentCTF, {
    key: "id",
    direction: "ascending",
  });
  const onCurrentPageData = dataPagination(items, currentPage, perPage);
  const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);

  const handleDelete = () => {
    if (selected.length > 0) {
      selected.forEach((item) => deleteItem(item));
    }
    setOpen(false);
    setSelected([]);
    refetch();
  };

  const handlCancel = () => setOpen(false);

  useEffect(() => {
    if (selectTable.values.selectedList) {
      setSelected(selectTable.values.selectedList);
    }
  }, [selectTable.values.selectedList]);


  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handlCancel}
      />
      {selected.length > 0 ? (
        <button
          className="btn btn-danger"
          style={{ display: "block", position: "fixed", right: "100px" }}
          onClick={() => {
            setSelected(selected);
            setOpen(true);
          }}
        >
          Seçilenleri Sil
        </button>
      ) : (
        <button
          style={{ display: "none" }}
          onClick={() => {
            setSelected(selected);
            setOpen(true);
          }}
        >
          Seçilenleri Sil
        </button>
      )}

      <Card>
        <CardBody>
          <table className="table table-modern">
            <thead>
              <tr>
                <th style={{ width: "10px" }}>{SelectAllCheck}</th>
                <th onClick={() => requestSort("title")}>
                  Başlık
                  <Icon
                    size="lg"
                    className={getClassNamesFor("title")}
                    icon="FilterList"
                  />
                </th>
                <th onClick={() => requestSort("description")}>
                  Açıklama
                  <Icon
                    size="lg"
                    className={getClassNamesFor("description")}
                    icon="FilterList"
                  />
                </th>
                <th
                  onClick={() => requestSort("file_name")}
                  style={{ textAlign: "start" }}
                >
                  Dosya
                  <Icon
                    size="lg"
                    className={getClassNamesFor("file_name")}
                    icon="FilterList"
                  />
                </th>
                <th
                  onClick={() => requestSort("status")}
                  style={{ textAlign: "center" }}
                >
                  Aktif Mi
                  <Icon
                    size="lg"
                    className={getClassNamesFor("status")}
                    icon="FilterList"
                  />
                </th>
                <th
                  onClick={() => requestSort("difficulty")}
                  style={{ textAlign: "center" }}
                >
                  Zorluk
                  <Icon
                    size="lg"
                    className={getClassNamesFor("difficulty")}
                    icon="FilterList"
                  />
                </th>
                <th style={{ textAlign: "center" }}>Eylemler</th>
              </tr>
            </thead>
            <tbody>
              {!(currentCTF && currentCTF.length > 0) ? (
                <div>Soru bulunmuyor</div>
              ) : (
                items.map((item) => (
                  <tr key={item.id.toString()}>
                    <td>
                      <Checks
                        id={item.id.toString()}
                        name="selectedList"
                        value={item.id}
                        onChange={selectTable.handleChange}
                        checked={selectTable.values.selectedList.includes(
                          item.id.toString()
                        )}
                      />
                    </td>
                    <td style={{ textAlign: "justify" }}>{item.title}</td>
                    <td style={{ textAlign: "justify" }}>{item.description}</td>
                    <td
                      style={{
                        textAlign: "center",
                        cursor: item.file_name ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (item.file_name) {
                          handleDownload(item.id, setLoading, triggerDownload);
                        }
                      }}
                    >
                      {item.file_name ? (
                        <>{getFileExtension(item.file_name)}</>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.status === CTF_STATUS.ACTIVE.id
                        ? active
                        : item.status === CTF_STATUS.PASSIVE.id
                        ? passive
                        : unknown}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {difficultyButtons[item.difficulty]}
                    </td>
                    <td style={{ width: "150px", textAlign: "center" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setSelected([item.id]);
                            setOpen(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleCTF(item.id)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Check">
                        <IconButton onClick={() => testCTF(item.id)}>
                          <Check />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </CardBody>
        <PaginationButtons
          data={items}
          label="kategori"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
    </>
  );
};

export default CTFTable;
