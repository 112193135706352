import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../components/bootstrap/forms/Input";
import Button from "../../../../components/bootstrap/Button";
import useDarkMode from "../../../../hooks/useDarkMode";
import { resetPassword } from "../loginHelpers";
import Alert from "../../../../components/bootstrap/Alert";
import Spinner from "../../../../components/bootstrap/Spinner";
import Privacy from "../Privacy";
import { useFormik } from "formik";
import { Check } from "@mui/icons-material";
import Logo from "../../../../components/Logo";

const ResetPassword = () => {
  const { darkModeStatus } = useDarkMode();
  const navigate = useNavigate();
  const { id } = useParams();
  const [changedPassword, setChangedPassword] = useState(false);
  const [error, setError] = useState("");
  const [busy, setBusy] = useState(false);
  const regexGuid = useMemo(() => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, []);
  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = "Şifre gereklidir.";
    }
       if (
      values.password.length > 0 &&
      !/[a-z|A-Z]/.test(values.password)
    ) {
      errors.password = "Şifre en az bir harf içermelidir.";
    }
    if (
      values.password.length > 0 &&
      !/[0-9]/.test(values.password)
    ) {
      errors.password = "Şifre en az bir rakam içermelidir.";
    }
    else if (values.password.length < 8) {
      errors.password = "Şifre en az 8 karakter olmalıdır.";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Şifre tekrarı gereklidir.";
    }
    if (!errors.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = "Şifreler eşleşmiyor.";
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate,
    onSubmit: async (values) => {
      setBusy(true);

      const response = await resetPassword({
        id: id,
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      if (response.status === "success") {
        setChangedPassword(true);
      } else setError(response.message);
      setBusy(false);
    },
  });

  // Hooks
  useEffect(() => {
    if (!regexGuid.test(id)) {
      navigate("/login");
    }
  }, [id, navigate, regexGuid]);

  return (
    <PageWrapper
      title="Reset Password"
      className="bg-warning"
    >
      <Page className="p-0">
        {error !== "" && (
          <Alert borderWidth={0} className="" color="danger" icon="Warning">
            {error}
          </Alert>
        )}

        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                <div className="text-center my-5">
                  <Link
                    to="/"
                    className={classNames(
                      "text-decoration-none  fw-bold display-2",
                      {
                        "text-dark": !darkModeStatus,
                        "text-light": darkModeStatus,
                      }
                    )}
                  >
                    <Logo width={200}/>
                  </Link>
                </div>

                <div className="text-center h1 fw-bold">Şifremi Değiştir</div>
                <div className="text-center h4 text-muted mb-5">
                  Yeni şifrenizi giriniz.
                </div>

                {changedPassword &&
                  <div className="alert alert-success shadow flex-nowrap" role="alert">
                    <Check className="me-2"/> Şifreniz başarılı şekilde değiştirildi.
                    <Link
                      to="/login"
                      className={classNames(
                        "text-decoration-none fw-bold ms-1",
                        {
                          "text-dark": !darkModeStatus,
                          "text-light": darkModeStatus,
                        }
                      )}
                    >
                      Giriş Yapabilirsiniz!
                    </Link>
                  </div>
                }

                <form className="row g-4">
                  <div className="col-12">
                    <FormGroup isFloating label="Şifre">
                      <Input
                        name="password"
                        type="password"
                        autoComplete="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.password}
                        invalidFeedback={formik.errors.password}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <FormGroup isFloating label="Şifre (Tekrar)">
                      <Input
                        name="confirmPassword"
                        type="password"
                        autoComplete="password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        isValid={formik.isValid}
                        onBlur={formik.handleBlur}
                        isTouched={formik.touched.confirmPassword}
                        invalidFeedback={formik.errors.confirmPassword}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12">
                    <Button
                      color="warning"
                      className="w-100 py-3"
                      onClick={formik.handleSubmit}
                      disabled={busy}
                    >
                      {busy && (
                        <Spinner color="primary" inButton isSmall>
                          Loading...
                        </Spinner>
                      )}
                      Şifremi Değiştir
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
            <Privacy isNewUser={false} classNames={classNames}/>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default ResetPassword;
