import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../../../layout/SubHeader/SubHeader";
import Button from "../../../../../../components/bootstrap/Button";
import Page from "../../../../../../layout/Page/Page";
import Card, {
  CardBody,
  CardFooter,
  CardFooterRight,
  CardHeader,
  CardLabel,
  CardTitle,
} from "../../../../../../components/bootstrap/Card";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../../components/bootstrap/forms/Input";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import { useClearCacheMutation } from "../../../../../../store/features/apiSlice";
import { useFormik } from "formik";
import Select from "../../../../../../components/bootstrap/forms/Select";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Confirm from "../../../../../common/Confirm";
import { useLocation } from "react-use";
import _ from "underscore";
import {
  useSinglePresentationQuery,
  useUpdatePresentationMutation,
} from "../../teacherEducationApi";
import ShowSlideModal from "../4-ShowSlideModal";
import { CoursePrograms } from "../../../course management/constant/CourseProgramList";

const rowStyle = {
  cursor: "pointer",
};

const UpdatePresentation = () => {
  const { id } = useParams();
  const { data, isSuccess } = useSinglePresentationQuery({
    id,
  });
  const [clearCache] = useClearCacheMutation();
  const [updatePresentation] = useUpdatePresentationMutation();
  const [open, setOpen] = useState(false);
  const [slideId, setSlideId] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [isOpenShowSlideModal, setIsOpenShowSlideModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activeTabParam = searchParams.get("activeTab");
    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [location.search]);

  const TABS = {
    PRESENTATION_DETAIL: "Presentation Details",
    slide: "slide",
  };
  const [activeTab, setActiveTab] = useState(TABS.slide);

  useEffect(() => {
    if (isSuccess && data) {
      formik.setFieldValue("title", data.title);
      formik.setFieldValue("description", data.description);
      formik.setFieldValue("technology", data.technology);
      formik.setFieldValue("cover_image", data.cover_image);
      formik.setFieldValue("status", data.status);
      formik.setFieldValue("order_number", data.order_number);
      formik.setFieldValue("slides", data.slides);
    }
    //eslint-disable-next-line
  }, [isSuccess, data]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }

    if (!values.cover_image) {
      errors.cover_image = "Logoyu seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: null,
      technology: null,
      cover_image: "",
      order_number: "",
      status: "2",
      slides: [],
    },
    validate,
    onSubmit: () => {
      handlePresentation();
    },
  });

  const handlePresentation = async () => {
    const presentationValues = { ...formik.values };
    const slidesWithoutPresentationParemeter = data.slides?.map((item) => {
      const { presentation, ...rest } = item;
      return rest;
    });
    const response = await updatePresentation({
      id,
      ...presentationValues,
      slides: slidesWithoutPresentationParemeter,
    });
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Güncelleme Başarısız</span>
        </span>,
        "Bir hata oluştu",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Güncelleme Başarılı</span>
        </span>,
        "Sunum bilgileri başarıyla güncellendi.",
        "success"
      );
    }
    clearCache();
  };

  //! delete slide--------
  const removeSlide = (e) => {
    setOpen(true);
    setSlideId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleDelete = async () => {
    let newSlides = data.slides.filter(
      (item) => Number(item.id) !== Number(slideId)
    );

    newSlides = newSlides.map((item) => {
      const { presentation, ...rest } = item;
      return rest;
    });
    newSlides = _.sortBy(newSlides, "order_number");
    const response = await updatePresentation({
      id,
      title: data.title,
      ...data,
      slides: newSlides,
    });
    setOpen(false);
    if (response.error) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="danger" size="lg" className="me-1" />
          <span>Silme İşlemi Başarısız</span>
        </span>,
        "Bir hata meydana geldi",
        "danger"
      );
    } else {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="Info" size="lg" className="me-1" />
          <span>Silme Başarılı</span>
        </span>,
        "Slayt başarıyla silindi.",
        "success"
      );
    }
    clearCache();
  };
  const handleCancel = () => setOpen(false);

  //! handle slide-------
  const handleSlide = (e) => {
    console.log(
      "%cUpdatePresentation.js line:180 e",
      "color: white; background-color: #007acc;",
      e
    );
    let currentSlide =
      data &&
      data.slides.find(
        (slide) => Number(slide.id) === Number(e.currentTarget.id)
      );
    setCurrentSlide(currentSlide);
    setIsOpenShowSlideModal(true);
  };

  return (
    <PageWrapper title={"Sunum Bilgileri"}>
      <ShowSlideModal
        isOpenShowSlideModal={isOpenShowSlideModal}
        setIsOpenShowSlideModal={setIsOpenShowSlideModal}
        data={data}
        currentSlide={currentSlide}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="info"
            isLink
            icon="ArrowBack"
            tag="a"
            onClick={goBack}
            // className={style.SubHeader}
          >
            Sunum Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
      </SubHeader>
      <Page>
        <div className="row h-100">
          <div className="col-xl-3 col-lg-4 col-md-4">
            <Card stretch>
              <CardHeader>
                <CardLabel icon="Edit" iconColor="info">
                  <CardTitle>Sunum Düzenle</CardTitle>
                </CardLabel>
              </CardHeader>
              <CardBody isScrollable>
                <div className="row g-3">
                  <div className="col-12">
                    <Button
                      icon="Info"
                      color="info"
                      className="w-100 p-3"
                      isLight={TABS.PRESENTATION_DETAIL !== activeTab}
                      onClick={() => setActiveTab(TABS.PRESENTATION_DETAIL)}
                    >
                      Sunum Bilgileri
                    </Button>
                  </div>
                  <div className="col-12">
                    <Button
                      icon="Computer"
                      color="info"
                      className="w-100 p-3"
                      isLight={TABS.slide !== activeTab}
                      onClick={() => setActiveTab(TABS.slide)}
                    >
                      Slaytlar
                    </Button>
                  </div>
                  <div className="col-12 border-bottom" />
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-8">
            {TABS.PRESENTATION_DETAIL === activeTab && (
              <Card
                stretch
                tag="form"
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <CardHeader>
                  <CardLabel icon="Details" iconColor="info">
                    <CardTitle>Sunum Bilgileri</CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className="pb-0" isScrollable>
                  <form
                    onSubmit={formik.handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <div className="row g-4">
                      <div className="col-lg-8">
                        <FormGroup
                          // className="col-4"
                          id="title"
                          isFloating
                          label="Başlık"
                        >
                          <Input
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            onBlur={formik.handleBlur}
                            isValid={formik.isValid}
                            isTouched={formik.touched.title}
                            invalidFeedback={formik.errors.title}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-lg-8">
                        <FormGroup
                          // className="col-4"
                          id="description"
                          isFloating
                          label="Açıklama/Not"
                        >
                          <Input
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            onBlur={formik.handleBlur}
                            isValid={formik.isValid}
                            isTouched={formik.touched.description}
                            invalidFeedback={formik.errors.description}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-lg-8">
                        <FormGroup id="cover_image" isFloating label="Logo">
                          <Select
                            placeholder="Logo"
                            ariaLabel="Logo"
                            onChange={formik.handleChange}
                            value={formik.values.cover_image}
                            isValid={formik.isValid}
                            onBlur={formik.handleBlur}
                            isTouched={formik.touched.cover_image}
                            invalidFeedback={formik.errors.cover_image}
                            list={CoursePrograms}
                            // className="text-dark"
                            style={{ height: "50px" }}
                          ></Select>
                        </FormGroup>
                      </div>
                      <div className="col-lg-8">
                        <FormGroup
                          isFloating
                          label="Sıra Numarası"
                          id="order_number"
                        >
                          <Input
                            name="order_number"
                            type="text"
                            autoComplete="order_number"
                            value={formik.values.order_number}
                            onChange={formik.handleChange}
                            isValid={formik.isValid}
                            onBlur={formik.handleBlur}
                            isTouched={formik.touched.order_number}
                            invalidFeedback={formik.errors.order_number}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-lg-8">
                        <FormGroup id="status">
                          <Select
                            placeholder="Aktiflik"
                            ariaLabel="status"
                            onChange={formik.handleChange}
                            value={formik.values.status}
                            isValid={formik.isValid}
                            onBlur={formik.handleBlur}
                            isTouched={formik.touched.status}
                            invalidFeedback={formik.errors.status}
                            list={[
                              { value: "0", text: "Pasif" },
                              { value: "1", text: "Taslak" },
                              { value: "2", text: "Aktif" },
                            ]}
                            className="text-dark"
                            style={{ height: "50px" }}
                          ></Select>
                        </FormGroup>
                      </div>
                    </div>
                  </form>
                </CardBody>
                <CardFooter>
                  <CardFooterRight>
                    <Button
                      icon="DoneOutline"
                      color="success"
                      isLight
                      onClick={async () => {
                        formik.handleSubmit();
                      }}
                    >
                      Güncelle
                    </Button>
                    <Button
                      icon="Cancel"
                      color="danger"
                      isLight
                      tag="a"
                      to={-1}
                    >
                      İptal
                    </Button>
                  </CardFooterRight>
                </CardFooter>
              </Card>
            )}
            {TABS.slide === activeTab && (
              <Card stretch tag="form" noValidate>
                <CardHeader>
                  <CardLabel icon="Computer" iconColor="success">
                    <CardTitle>Slayt Bilgileri</CardTitle>
                  </CardLabel>
                  <Button
                    color="info"
                    className={`ms-5 mb-4 shadow-3d-up-hover}`}
                    onClick={(e) => handleSlide(e)}
                  >
                    Slayt Ekle
                  </Button>
                </CardHeader>
                <CardBody>
                  <table className="table table-modern">
                    <thead style={{ fontSize: "16px" }}>
                      <tr>
                        <th>Başlık</th>
                        <th style={{ textAlign: "center" }}>Sırası</th>
                        <th className="text-center">Sil</th>
                        <th style={{ textAlign: "center" }}>Ünite Detayı</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "16px" }}>
                      {data &&
                        data.slides.map((course) => {
                          const { id, title, order_number } = course;
                          return (
                            <tr key={id}>
                              <td
                                id={id}
                                style={rowStyle}
                                onClick={(e) => handleSlide(e)}
                              >
                                {title}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {order_number}
                              </td>
                              <td className="text-center">
                                <IconButton
                                  id={id}
                                  onClick={(e) => removeSlide(e)}
                                >
                                  <Delete className="fs-2" />
                                </IconButton>
                              </td>
                              <td className="text-center">
                                <IconButton
                                  id={id}
                                  onClick={(e) => handleSlide(e)}
                                >
                                  <Edit className="fs-2" />
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default UpdatePresentation;
