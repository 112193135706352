import React, { useEffect, useMemo, useState } from "react";
import TabsComponent from "./TabsComponent";
import GameContent from "./GameContent";
import style from "./GamesWrapper.module.scss";
import { useGamesQuery } from "../../../../store/features/apiSlice";
import { useLocation } from "react-router";

const GamesWrapper = () => {
  const location = useLocation();
  const { data, isLoading } = useGamesQuery();
  const searchParams = new URLSearchParams(location.search);
  const activeTabParam = searchParams.get("activeTab");

  // Filtrelenmiş veriyi hesaplamak için useMemo kullanıyoruz (performans için)
  const games = useMemo(() => {
    return data ? data.filter((game) => game.title !== "Trafik Macerası") : [];
  }, [data]);

  const [activeTab, setActiveTab] = useState(null);

  // URL’deki aktif tab varsa onu kullan, yoksa ilk oyun id'sini al
  useEffect(() => {
    if (activeTabParam) {
      setActiveTab(Number(activeTabParam));
    } else if (games.length > 0) {
      setActiveTab(games[0].id);
    }
  }, [activeTabParam, games]); // Data güncellendiğinde ve URL değiştiğinde çalışır

  //localdeki oyun durumunu temizle
  useEffect(() => {
    localStorage.removeItem("sectionSteps");
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          fontWeight: "600",
        }}
      >
        <div className={`${style.loader}`}>
          <div className={`${style.progressLoading}`}></div>
        </div>
        <p>Yükleniyor...</p>
      </div>
    );
  }
  return (
    <div>
      <TabsComponent
        data={games}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <GameContent
        game={games?.find((item) => item.id === activeTab) || games[0]}
      />
    </div>
  );
};

export default GamesWrapper;
