import React from 'react';

const LoadingSpinner = () => {
  const loaderStyle = {
    width: '100%',
    height: '10px',
    backgroundColor: '#f3f3f3',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const progressLoadingStyle = {
    width: '50%',
    height: '100%',
    backgroundColor: '#6c5dd3',
    animation: 'load 2s ease-in-out infinite',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        fontWeight: '600',
      }}
    >
      <div style={loaderStyle}>
        <div style={progressLoadingStyle}></div>
      </div>
      <p>Yükleniyor...</p>

      <style>
        {`
          @keyframes load {
            0% {
              width: 0%;
            }
            50% {
              width: 50%;
            }
            100% {
              width: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingSpinner;
