import React, { useEffect} from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../../components/bootstrap/forms/Select";
import showNotification from "../../../../../components/extras/showNotification";
import { useCreatePresentationMutation } from "../teacherEducationApi";
import { CoursePrograms } from "../../course management/constant/CourseProgramList";

const CreatePresentationModal = ({ isOpenNewPresentationModal, setIsOpenNewPresentationModal}) => {
  const [createPresentation] = useCreatePresentationMutation();

  useEffect(() => {
    if (isOpenNewPresentationModal) {
      setIsOpenNewPresentationModal(true);
    } else {
      setIsOpenNewPresentationModal(false);
    }
  }, [isOpenNewPresentationModal, setIsOpenNewPresentationModal]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Sunumun adını giriniz";
    }

    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }

    if (!values.cover_image) {
      errors.cover_image = "Logoyu seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      technology: null,
      cover_image:"",
      order_number: 9999,
      status: "2",
    },
    validate,
    onSubmit: () => {
      handlePresentation();
    },
  });

  const handlePresentation = async()=>{
    const presentationValues = { ...formik.values };
          const response = await createPresentation(
           presentationValues
          );
          if (response.error) {
            showNotification(
              <span className="d-flex align-items-center">
                <Icon icon="danger" size="lg" className="me-1" />
                <span>Kayıt Başarısız</span>
              </span>,
              "Bir hata oluştu",
              "danger"
            );
          } else {
            showNotification(
              <span className="d-flex align-items-center">
                <Icon icon="Info" size="lg" className="me-1" />
                <span>Kayıt Başarılı</span>
              </span>,
              "Sunum başarıyla eklendi.",
              "success"
            );
          }
          setIsOpenNewPresentationModal(false);
          formik.resetForm();
        }
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenNewPresentationModal}
      setIsOpen={setIsOpenNewPresentationModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenNewPresentationModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Sunum</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
      <form onSubmit={formik.handleSubmit} 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formik.handleSubmit();
                }
              }}>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="title"
              isFloating
              label="Başlık"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.title}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.title}
                invalidFeedback={formik.errors.title}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup
              // className="col-4"
              id="description"
              isFloating
              label="Açıklama/Not"
            >
              <Input
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                isValid={formik.isValid}
                isTouched={formik.touched.description}
                invalidFeedback={formik.errors.description}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="cover_image" isFloating
              label="Ders Logosu">
              <Select
                placeholder="Logo"
                ariaLabel="Logo"
                onChange={formik.handleChange}
                value={formik.values.cover_image}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.cover_image}
                invalidFeedback={formik.errors.cover_image}
                list={CoursePrograms}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup isFloating label="Sıra Numarası" id="order_number">
              <Input
                name="order_number"
                type="text"
                autoComplete="order_number"
                value={formik.values.order_number}
                onChange={formik.handleChange}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.order_number}
                invalidFeedback={formik.errors.order_number}
              />
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <FormGroup id="status">
              <Select
                placeholder="Aktiflik"
                ariaLabel="status"
                onChange={formik.handleChange}
                value={formik.values.status}
                isValid={formik.isValid}
                onBlur={formik.handleBlur}
                isTouched={formik.touched.status}
                invalidFeedback={formik.errors.status}
                list={[
                  { value: "0", text: "Pasif" },
                  { value: "1", text: "Taslak" },
                  { value: "2", text: "Aktif" },
                ]}
                className="text-dark"
              ></Select>
            </FormGroup>
          </div>
        </div>
        </form>
      </ModalBody>
        <ModalFooter>
          <Button
            icon="DoneOutline"
            color="success"
            isLight
            onClick={async () => {
              formik.handleSubmit();
            }}
          >
            Kaydet
          </Button>
        </ModalFooter>
    </Modal>
  );
};

export default CreatePresentationModal;
