import { createSlice} from "@reduxjs/toolkit";


const pathSlice = createSlice({
    name: "path",
    initialState: {
        // paths:[],
        selectedPath: {},
        isLoading:false,
        showAllPractices:false,
    },
    reducers: {
        setPaths: (state, action) => {
            state.paths = action.payload;
        },
        setSelectedPath: (state, action) => {
            console.log("setSelectedPath", action.payload);
            state.selectedPath = action.payload;
        },
        setIsLoading:(state,action)=>{
            console.log("%c setIsLoading", action.payload);
            state.isLoading = action.payload;
        },
        setShowAllPractices:(state,action)=>{
            console.log("%c setShowAllPractices", action.payload);
            state.showAllPractices = action.payload;
        },
    }
});

export const pathActions = pathSlice.actions;

export default pathSlice;