export const CoursePrograms = [
    {
      text: "Bilgisayarımı Tanıyorum",
      value: "Bilgisayarimi Taniyorum",
    },
    {
      text: "Scratch İle Programlama",
      value: "Scratch Ile Programlama",
    },
    {
      text: "Bilgisayar Temelleri",
      value: "Bilgisayar Temelleri",
    },
    {
      text: "Python - Turtle Modülü",
      value: "Python - Turtle Modulu",
    },
    {
      text: "Python - Pygame Modülü",
      value: "Python - Pygame Modulu",
    },
    {
      text: "Python ile Yapay Zeka",
      value: "Python ile Yapay Zeka",
    },
    { text: "HTML-CSS", value: "HTML-CSS" },
    { text: "Node.js-Express.js", value: "Node.js-Express.js" },
    { text: "React", value: "React" },
    {
      text: "Siber Güvenlik Temelleri",
      value: "Siber Guvenlik Temelleri",
    },
    { text: "Bilgisayar Ağları", value: "Bilgisayar Aglari" },
    { text: "Linux", value: "Linux" },
    {
      text: "Ağ ve Sistem Sızma Testleri",
      value: "Ag ve Sistem Sizma Testleri",
    },
    { text: "Web Sızma Testleri", value: "Web Sizma Testleri" },
    {
      text: "Yeni Nesil Teknoloji Güvenlik",
      value: "Yeni Nesil Teknoloji Guvenlik",
    },
    { text: "Linux Bash Script", value: "Linux Bash Script" },
    { text: "Robotiğe Giriş", value: "Robotige Giris" },
    {
      text: "Arduino ile Robotik",
      value: "Arduino ile Robotik",
    },
    { text: "C++", value: "C++" },
    { text: "Robotik Projeler", value: "Robotik Projeler" },
    { text: "Pinoo", value: "Pinoo" },
    { text: "Roblox", value: "Roblox" },
    { text: "CodeÇizz", value: "CodeCizz" },
    { text: "CodeArt", value: "CodeArt" },
    { text: "Python", value: "python" },
    { text: "JavaScript", value: "javascript" },
    { text: "Mor Maker", value: "morMaker" },
    { text: "Maker RKA", value: "makerRKA" },
    { text: "Talent 14", value: "talent14" },
    { text: "Roblox Lua Kodlama", value: "roblox_lua_kodlama" },
    {
      text: "Scratch İleri Seviye",
      value: "scratch_ileri_seviye",
    },
    {
      text: "Çocuğumu Önemsiyorum",
      value: "cocugumu_onemsiyorum",
    },
    { text: "YZA-1", value: "YZA-1" },
    { text: "YZA-2", value: "YZA-2" },
    { text: "YZA-3", value: "YZA-3" },
    { text: "YZA-4", value: "YZA-4" },
    { text: "YZA-5", value: "YZA-5" },
    { text: "YZA-6", value: "YZA-6" },
    { text: "YZA-7", value: "YZA-7" },
    { text: "YZA-8", value: "YZA-8" },
    { text: "YZA-9", value: "YZA-9" },
    { text: "YZA-10", value: "YZA-10" },
    { text: "Öğrenci YZA-1", value: "Ogrenci_YZA-1" },
    { text: "Öğrenci YZA-2", value: "Ogrenci_YZA-2" },
    { text: "Öğrenci YZA-3", value: "Ogrenci_YZA-3" },
    { text: "Öğrenci YZA-4", value: "Ogrenci_YZA-4" },
    { text: "Öğrenci YZA-5", value: "Ogrenci_YZA-5" },
    { text: "Öğrenci YZA-6", value: "Ogrenci_YZA-6" },
    { text: "Öğrenci YZA-7", value: "Ogrenci_YZA-7" },
    { text: "Öğrenci YZA-9", value: "Ogrenci_YZA-9" },
    { text: "Öğrenci YZA-10", value: "Ogrenci_YZA-10" },
  ]
