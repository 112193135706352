import { createSlice} from "@reduxjs/toolkit";


const teacherLessonVideoSlice = createSlice({
    name: "teacherLessonVideo",
    initialState: {
        course:{},
        currentChapterForTeacher:localStorage.getItem("currentChapterForTeacher")?JSON.parse(localStorage.getItem("currentChapterForTeacher")):[],
        lectureVideoContentForTeacher:localStorage.getItem("lectureVideoContentForTeacher")?JSON.parse(localStorage.getItem("lectureVideoContentForTeacher")):{url:"",description:"", videoId:"",content:""},
        lessonCompletedForTeacher:false
    },
    reducers: {
        setCourse: (state, action) => {
            state.course = action.payload;
        },
        setCurrentChapterForTeacher: (state, action) => {
            let lessonId = action.payload.lessonId
            let index = state.currentChapterForTeacher.findIndex(s=>s.lessonId === lessonId)
            if(index === -1){
                state.currentChapterForTeacher = [...state.currentChapterForTeacher, action.payload];
            }
            else {
                let tmp = state.currentChapterForTeacher;
                tmp[index] = {...tmp[index], ...action.payload}
                state.currentChapterForTeacher = [...tmp]
            }
            localStorage.setItem("currentChapterForTeacher",JSON.stringify(state.currentChapterForTeacher))
        },
        lectureVideoContentForTeacher: (state, action) => {
            state.lectureVideoContentForTeacher=action.payload
            localStorage.setItem("lectureVideoContentForTeacher",JSON.stringify(state.lectureVideoContentForTeacher))
        },
        setLessonCompletedForTeacher: (state, action) => {
            state.lessonCompletedForTeacher = action.payload;
        },

    }
});

export const {lectureVideoContentForTeacher, setCurrentChapterForTeacher,setCourse, setLessonCompletedForTeacher} = teacherLessonVideoSlice.actions;

export default teacherLessonVideoSlice;