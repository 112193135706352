import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../components/bootstrap/Modal";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/bootstrap/Button";
import Input from "../../../../components/bootstrap/forms/Input";
import showNotification from "../../../../components/extras/showNotification";
import Label from "../../../../components/bootstrap/forms/Label";
import Spinner from "../../../../components/bootstrap/Spinner";

const TeacherDocumentModal = ({
  isOpenDocementModal,
  setIsOpenDocumentModal,
  publicId,
  setIsNewUser,
}) => {
  //states
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);

  //constant
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;

  //functions
  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(e.target.files[0]);
      setErrorMessage("");
    } else {
      setSelectedFile(null);
      setErrorMessage("Lütfen sadece PDF dosyası yükleyin.");
    }
  };

  const handleSave = () => {
      if (selectedFile && publicId) {
          console.log(`Seçilen dosya adı: ${selectedFile.name}`);
          const formData = new FormData();
          formData.append("teacher_cv", selectedFile);
          formData.append("public_id", publicId);
          
        fetch(baseUrl + `file_upload/teacher_cv/`, {
            method: "POST",
            body: formData,
        })
        .then((response) => response.text())
        .then((data) => {
          console.log("Yükleme tamamlandı");
          showNotification(
            <span className="d-flex align-items-center">
              <Icon icon="Info" size="lg" className="me-1" />
              <span>Bilgi</span>
            </span>,
            <p style={{ fontSize: "14px" }}>Kaydınız başarıyla oluşturuldu.</p>,
            "success"
          );
          setIsNewUser();
          setIsOpenDocumentModal(false);
        })
        .catch((error) => {
          console.error("Yükleme hatası", error);
          showNotification(
            <span className="d-flex align-items-center">
              <Icon icon="danger" size="lg" className="me-1" />
              <span>Belge Ekleme Başarısız</span>
            </span>,
            "Bir hata oluştu",
            "danger"
          );
        });
      setBusy(false);
    } else {
      setErrorMessage("Lütfen bir PDF dosyası seçin.");
    }
  };

  // useEffects
  useEffect(() => {
    if (isOpenDocementModal) {
      setIsOpenDocumentModal(true);
    } else {
      setIsOpenDocumentModal(false);
    }
  }, [isOpenDocementModal, setIsOpenDocumentModal]);

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenDocementModal}
      setIsOpen={setIsOpenDocumentModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenDocumentModal}>
        <ModalTitle id="example-title">
          {/* {title} */}
          <div className="d-flex justify-content-center align-items-center my-1">
            <img
              className="thumb"
              src="/images/friday-happy.gif"
              alt="tebrikler"
            />
            <h3>
              Harika! <br /> Kullanıcı kaydınız oluşturuldu.
            </h3>
          </div>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div>
          <p style={{ fontSize: "14px" }}>
            *Öğretmen olarak işlemlerininizin tamamlanması için lütfen yetki
            belgenizi <span className="fw-bold">PDF</span> formatında ekleyiniz.
          </p>
          <p style={{ fontSize: "14px" }}>
            *Yetki belgeniz onaylanana kadar platformu <span className="fw-bold">Ücretsiz Öğrenci</span> yetkisiyle kullanabilirsiniz.
          </p>
        </div>
        <div className="col-12">
          <Label>Mezuniyet Belgesi</Label>
          <Input type="file" accept=".pdf" onChange={handleDocumentChange} />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            handleSave();
            selectedFile && setBusy(true)
          }}
        >
          {busy && (
            <Spinner color="primary" inButton isSmall>
              Loading...
            </Spinner>
          )}
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TeacherDocumentModal;
