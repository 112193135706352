const USER_PAYMENT_PLAN_TYPE = {
    STANDARTMOUNTHLY : {
        id: 1,
        newPrice: '400',
        oldPrice: '500',
    },
    STANDARTYEARLY : {
        id: 2,
        newPrice: '2400',
        oldPrice: '3000',
    },
    PREMIUMMOUNTHLY : {
        id: 3,
        newPrice: '600',
        oldPrice: '700',
    },
    PREMIUMYEARLY : {
        id: 4,
        newPrice: '3600',
        oldPrice: '4200',
    },
};
export default USER_PAYMENT_PLAN_TYPE;