import React, { useState } from "react";
import style from "./SchoolWrapper.module.scss";
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../../common/Headers/DashboardHeader";
import Page from "../../../../../layout/Page/Page";
import classPathsLogo1 from "../../../../../../src/assets/lessons/classPathsLogo1.svg";
import classPathsLogo2 from "../../../../../../src/assets/lessons/classPathsLogo2.svg";
import announce1 from "../../../../../../src/assets/lessons/announce1.svg";
import announce2 from "../../../../../../src/assets/lessons/announce2.svg";
import LiveLessonForStudent from "../../../lessons/liveLessonForStudents/LiveLessonForStudent";
import SchoolOrPartnerContentsWrapper from "../school-partner contents wrapper/SchoolOrPartnerContentsWrapper";
import { useGetSchoolBannerForAdminQuery } from "../../../../../store/features/apiSlice";
import Card from "../../../../../components/bootstrap/Card";
import Banner from "../../dashboard/banner and announcements/banner/Banner";

const SchoolWrapper = () => {
  const school = localStorage.getItem("school");
  const { data: banner, isLoading: isLoadingBanner } =
    useGetSchoolBannerForAdminQuery(school);

  const _activeTab = sessionStorage.getItem("activeTabContentsForSchool");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <SchoolOrPartnerContentsWrapper isPartner={false} />,
      tabName: "İzle & Öğren",
      icon1: classPathsLogo1,
      icon2: classPathsLogo2,
    },
    {
      content: <LiveLessonForStudent />,
      tabName: "Canlı Ders",
      icon1: announce1,
      icon2: announce2,
    },
  ];

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("activeTabContentsForSchool", tabNumber);
      setActiveTab(tabNumber);
    }
  };
  return (
    <PageWrapper title={"Okul İçerikleri"}>
      <DashboardHeader />
      <Page
        container={false}
        style={{ padding: "0" }}
        className={`${style.pageContainer}`}
      >
        {!isLoadingBanner && (
          <Card>{banner && <Banner banner={banner} />}</Card>
        )}
        <div
          className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
        >
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`${style.tab} ${
                activeTab === index + 1 ? `${style.activetab}` : ""
              } d-flex justify-content-center align-items-center gap-2 p-3`}
              onClick={() => handleTabClick(index + 1)}
            >
              <div className={`${style.tabsLogo}`}>
                <img
                  src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                  alt=""
                />
              </div>
              <div className={`${style.tabsDescription}`}>
                <span>{tab.tabName}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="tab-content">
          {tabContents[activeTab - 1] && tabContents[activeTab - 1].content}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default SchoolWrapper;
