import React, { useEffect, useMemo, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../../components/Logo";
import Icon from "../../../../../../../components/icon/Icon";
import { useFormik } from "formik";
import showNotification from "../../../../../../../components/extras/showNotification";
import {
  useCreateCyberCategoryMutation,
  useCyberCTFQuestionsQuery,
  useSingleCyberCatogoryQuery,
  useUpdateCyberCategoryMutation,
} from "../../../../../../../store/features/cyberApi";
import CustomFormField from "../../../../../../../common/customFormField/CustomFormField";
import Card, {
  CardBody,
  CardHeader,
} from "../../../../../../../components/bootstrap/Card";
import Input from "../../../../../../../components/bootstrap/forms/Input";
import Button from "../../../../../../../components/bootstrap/Button";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../../../../../utils/customErrors";

const CategoryForm = ({ isOpen, setIsOpen, handleKeyDown, categoryId }) => {
  const { data: currentCategory, isLoading } = useSingleCyberCatogoryQuery(
    categoryId,
    { skip: !categoryId }
  );
  const [createCategory] = useCreateCyberCategoryMutation();
  const [updateCategory] = useUpdateCyberCategoryMutation();
  const { data: questions = [] } = useCyberCTFQuestionsQuery();

  const [searchValue, setSearchValue] = useState("");
  const [filteredContents, setFilteredContents] = useState([]);

  const contents = useMemo(
    () =>
      questions.map((q) => ({
        id: q.id,
        title: q.title,
      })),
    [questions]
  );

  useEffect(() => {
    if (categoryId && currentCategory) {
      formik.setValues({
        title: currentCategory.title || "",
        description: currentCategory.description || "",
        status: currentCategory.status ?? 1,
        access_status: currentCategory.access_status ?? 1,
        capture_the_flag_questions:
          currentCategory.capture_the_flag_questions.map((q) => q.capturetheflagquestion) || [],
      });
    } else {
      formik.resetForm();
    }
    setSearchValue("")
    //eslint-disable-next-line
  }, [categoryId, currentCategory]);
  

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Konunun adını giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      status: 1,
      access_status: 2,
      capture_the_flag_questions:[],
    },
    enableReinitialize: true,
    validate,
    onSubmit: () => {
      handleCategory();
    },
  });
  const handleCategory = async () => {
    const { ...categoryValues } = formik.values;
    console.log("categoryValues", categoryValues);
    let response;
    try {
      if (categoryId) {
        response = await updateCategory({
          data: categoryValues,
          id: categoryId,
        });
      } else {
        response = await createCategory({ data: categoryValues });
      }
      if (response.error) {
        const customError = handleErrorMessage(response.error.data);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>{getCustomErrorMessage(customError, "Kayıt Başarısız")}</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
           `${categoryId ? "Kategori başarıyla güncellendi." : "Kategori başarıyla eklendi."}`,
          "success"
        );
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
    // clearCache();
    setIsOpen(false);
    formik.resetForm();
  };
  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target;
    const itemId = parseInt(value); // ID'nin sayı olduğundan emin olmak için

    if (checked) {
      formik.setFieldValue("capture_the_flag_questions", [
        ...formik.values.capture_the_flag_questions,
        itemId,
      ]);
    } else {
      formik.setFieldValue(
        "capture_the_flag_questions",
        formik.values.capture_the_flag_questions.filter((id) => id !== itemId)
      );
    }
  };

  useEffect(() => {
    if (searchValue) {
      setFilteredContents(
        contents?.filter((content) =>
          content?.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [contents, searchValue]);
  const _contents = searchValue ? filteredContents : contents;

  //   console.log(contents);
  if (isLoading) return <div>Loading...</div>;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
      size={null}
      onKeyDown={handleKeyDown}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">
            {categoryId ? "Kategori Düzenle" : "Yeni Kategori"}
          </span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-10">
            <CustomFormField
              type="text"
              label="Başlık"
              name={"title"}
              formik={formik}
            />
          </div>
          <div className="col-lg-10">
            <CustomFormField
              type="textArea"
              label="Açıklama"
              name={"description"}
              formik={formik}
              style={{ height: "150px" }}
            />
          </div>
          <div
            style={{ overflow: "scroll", height: "250px" }}
            className="col-lg-10"
          >
            <Card className="">
              <CardHeader>
                <Input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  placeholder="İçeriklerde Ara.."
                />
              </CardHeader>
              <CardBody>
                <table className="table table-modern">
                  <tbody>
                    {_contents?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            id={item.id.toString()}
                            name="capture_the_flag_questions"
                            value={item.id}
                            onChange={handleCheckboxClick}
                            checked={formik.values.capture_the_flag_questions?.includes(
                              item.id
                            )}
                          />
                        </td>
                        <td style={{ textAlign: "justify" }}>{item.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-10">
            <CustomFormField
              type="select"
              label="Aktiflik"
              name={"status"}
              options={[
                { value: "1", text: "Aktif" },
                { value: "2", text: "Pasif" },
              ]}
              formik={formik}
            />
          </div>
          <div className="col-lg-10">
            <CustomFormField
              type="select"
              label="Erişim Durumu"
              name={"access_status"}
              options={[
                { value: "1", text: "Özel" },
                { value: "2", text: "Herkese Açık" },
              ]}
              formik={formik}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategoryForm;
