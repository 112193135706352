import React from "react";
import style from "../dashboard.module.scss";
import Icon from "../../../../../components/icon/Icon";

export const LeadershipAwardsCard = () => {
  return (
<div className={`${style.card} ${style.scrollableCard}`}>
  <h5 className={style.sectionTitle}>
    <Icon icon="EmojiEvents" size="2x" />
    Liderlik ve Ödüller
  </h5>

  <div className={style.itemBox}>
    <span className="fs-1 me-2 p-1">🏆</span>
    <p className="mb-1 fw-bold text-light d-inline">
      Sıralama: <strong>2046 / 25919</strong>
    </p>
  </div>

  <hr className="my-1" />

  <div className={style.itemBox}>
    <span className="fs-1 me-2 p-1">🎖</span>
    <p className="mb-1 fw-bold text-light d-inline">
      Rozetler: <strong>3 / 12</strong>
    </p>
  </div>

  <hr className="my-1" />

  <div className={style.itemBox}>
    <span className="fs-1 me-2 p-1">📜</span>
    <p className="mb-1 fw-bold text-light d-inline">
      Sertifikalar: <strong>0 / 54</strong>
    </p>
  </div>

  <hr className="my-1" />

  <div className={style.itemBox}>
    <span className="fs-1 me-2 p-1">🏅</span>
    <p className="mb-1 fw-bold text-light d-inline">
      Sınıf Başarı Sırası: <strong>1 / 5</strong>
    </p>
  </div>
</div>

  );
};
