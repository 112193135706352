import { Route } from 'react-router-dom';
import Cyber14Wrapper from '../../pages/t14/admin/cyber14/Cyber14Wrapper';

const CyberRoutes = () => (
  <>
    <Route path="cyber" element={<Cyber14Wrapper />} />
  </>
);

export default CyberRoutes;
