import React, { useEffect, useState } from "react";
import style from "./StudentHeader.module.scss";
import defaultSchool from "../../../../../../assets/school/defaultSchoolLogo.png";
import bannerStudent from "../../../../../../assets/dashboardImages/bannerStudent.svg";
import { getSchoolLogo } from "../../../../schoolManager/dashboard/school-manager-header/SchoolManagerHeader";
import { getUserNameFromEmail } from "../../../../profilPage/utils";
import Spinner from "../../../../../../components/bootstrap/Spinner";

function capitalize(word) {
  if (word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }
  return;
}

const StudentHeader = ({ studentClassName, teacherFulName, schoolName }) => {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const schoolId = localStorage.getItem("school");
  const name = localStorage.getItem("name");
  const surname = localStorage.getItem("surname");
  const email = localStorage.getItem("email");
  const userFulName =
    name && name !== "null"
      ? capitalize(name) + " " + capitalize(surname)
      : getUserNameFromEmail(email);
  const spinner = <Spinner color="primary" style={{ marginTop: "20px" }} />;

  const [schoolLogo, setSchoolLogo] = useState(null);
   useEffect(() => {
      let isMounted = true;
      async function fetchData() {
        try {
          const logoUrl = await getSchoolLogo(schoolId);
          if (isMounted) {
            setSchoolLogo(logoUrl || null);
          }
        } catch (error) {
          console.error("Error fetching school logo:", error);
        } finally {
          if (isMounted) setLoading(false);
        }
      }
      fetchData();
      return () => {
        isMounted = false;
      };
    }, [schoolId]);

  return (
    <div
      className={`${style.schoolContainer} my-3 d-flex align-items-center justify-content-between`}
    >
      <img className={`${style.banner}`} src={bannerStudent} alt="" />
      <div
        className={`${style.leftSection} d-flex justify-content-center align-items-start flex-column gap-2`}
      >
        <div className={`${style.classDetail}`}>
          <div className={`${style.classTitleContainer}`}>
            <div className={`${style.classTitleShort}`}>{studentClassName}</div>
            <div className={`${style.classTitleFull}`}>{studentClassName}</div>
          </div>
          <div className={`${style.line}`}></div>
          <div className={`${style.teacherTitleContainer}`}>
            <div className={`${style.teacherTitleShort}`}>{teacherFulName}</div>
            <div className={`${style.teacherTitleFull}`}>{teacherFulName}</div>
          </div>
        </div>
        <div className={`${style.studentTitleContainer}`}>
          <div className={`${style.studentTitleShort}`}>
            {userFulName || "-"}
          </div>
          <div className={`${style.studentTitleFull}`}>
            {userFulName || "-"}
          </div>
        </div>
      </div>
      {schoolId > 0 && (
        <div className={`${style.rightSection}`}>
          {loading ? (
            spinner
          ) : (
            <div className={`${style.schoolLogo}`}>
              {!schoolLogo ? (
                <img src={defaultSchool} alt="" />
              ) : (
                <img
                  src={schoolLogo}
                  alt=""
                  width={64}
                  height={64}
                  className="mx-auto d-block"
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StudentHeader;
