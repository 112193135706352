import React from "react";
import style from "./LessonCompletedRate.module.scss";
import ChartInfo from "./ChartInfo";
import { Card } from "react-bootstrap";
import { useCourseCompletitionRateOfClassroomQuery } from "../../../../teacher/teacherApi";

const LessonCompletedRateForTeacher = ({ classId, data }) => {
  const { data: courseCompletitionRate } = useCourseCompletitionRateOfClassroomQuery(classId);
  const defaultLessons = [13,15,14,19,2,3,4,5]
  // Eğer courseCompletitionRate verisi varsa işlem yapalım
  if (!courseCompletitionRate) {
    return <div>Loading...</div>;
  }

  let firstLessonsToShow = courseCompletitionRate.filter((item) => defaultLessons.includes(item.course_id));
  firstLessonsToShow = defaultLessons.map(id => firstLessonsToShow.find(item => item.course_id === id)).filter(item => item !== undefined);

  const otherLessons = courseCompletitionRate.filter((item) => !defaultLessons.includes(item.course_id));
  const noStartedLessons = otherLessons.filter((item) => item.average_completion_rate === 0);
  const startedLessons = otherLessons.filter((item) => item.average_completion_rate > 0);

  const lessonCompletionRateData = [
    ...firstLessonsToShow,
    ...startedLessons,
    ...noStartedLessons
  ];
  return (
    <Card className={`${style.LessonCompletedRateContainer} p-3 px-5`}>
      <div className={`${style.title} my-3 px-3`}>Ders Tamamlama Oranı</div>
      <div className="d-flex">
        <div className={`${style.chartDiv}`}>
          <ChartInfo value={lessonCompletionRateData}/>
        </div>
      </div>
    </Card>
  );
};

export default LessonCompletedRateForTeacher;
