import React from 'react';
import style from './LoadingSpinner.module.scss';

const LoadingSpinner = () => {
  return (
    <div className={style.loadingContainer}>
      <div className={style['loadingio-spinner-bean-eater-nq4q5u6dq7r']}>
        <div className={style['ldio-x2uulkbinbj']}>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
        <p className="fw-bold text-center" style={{fontSize:"24px", color:"#6C5DD3"}}>
      Talent14 evrenine giriş yapıyorsunuz...
        </p>
    </div>
  );
}

export default LoadingSpinner;
