import React, { useEffect, useState } from "react";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import { adminPages } from "../../../../../../menu";
import Page from "../../../../../../layout/Page/Page";
import Button from "../../../../../../components/bootstrap/Button";
import { useCyberCategoriesQuery, useDeleteCyberCategoryMutation } from "../../../../../../store/features/cyberApi";
import CategoryTable from "./CategoryTable";
import CategoryForm from "./forms/CategoryForm";
import { getCustomErrorMessage, handleErrorMessage } from "../../../../../../utils/customErrors";
import showNotification from "../../../../../../components/extras/showNotification";
import Icon from "../../../../../../components/icon/Icon";
import LoadingSpinner from "../../../../../../utils/LoadingSpinner";

const CTFCategories = () => {
  const { data, isLoading } = useCyberCategoriesQuery();
  const [deleteCategory] = useDeleteCyberCategoryMutation();
  const categories = data ?? [];
  const [search, setSearch] = useState("");
  const [filteredCategories, setFilteredCategories] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(false);
 
  const deleteItem = async (id) => {
    try {
      const response = await deleteCategory(id);
      if (response.error) {
        const customError = handleErrorMessage(response.error.data);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>{getCustomErrorMessage(customError)}</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          "Kategori başarıyla silindi.",
          "success"
        );
      }
    } catch (error) {
      console.error("hata")
    }
  };

  const handleCategory = (id) => {
    if (id) {
      setCategoryId(id);
      setIsOpen(true)
    }else{
      setCategoryId(null);
      setIsOpen(true)
    }
  };

  useEffect(() => {
    filterPractice();
    //eslint-disable-next-line
  }, [search, categories]);

  const filterPractice = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newcategories = categories?.filter(
      (item) =>
        item.title.toLowerCase().includes(search) ||
        item.description.toLowerCase().includes(search)
    );
    setFilteredCategories(newcategories);
  };

  return (
    <PageWrapper title={adminPages.cyber.text}>
      <Page>
        <CategoryForm isOpen={isOpen} setIsOpen={setIsOpen} categoryId={categoryId}/>
        <div className="row">
          <div className="col-3">
            <Button
              className="btn btn-success"
              onClick={()=>handleCategory()}
            >
              Yeni Kategori Ekle
            </Button>
          </div>
          <div className="col-6">
            <form className="d-flex gap-1" onSubmit={filterPractice}>
              <input
                className="form-control"
                type="search"
                placeholder="Aramak istediğiniz soruyu yazınız"
                value={search}
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
              />
              <button className="btn btn-warning w-25">Bul</button>
            </form>
          </div>
        </div>
        <br />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <CategoryTable
            categories={categories ?? []}
            deleteItem={deleteItem}
            filteredCategories={filteredCategories}
            handleCategory={handleCategory}
          />
        )}
      </Page>
    </PageWrapper>
  );
};

export default CTFCategories;
