import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import style from "./dashboard.module.scss";
import { LeadershipAwardsCard } from "./components/LeadershipAwardsCard";
import { HomeworkTasksCard } from "./components/HomeworkTasksCard";
import { AnnouncementsCard } from "./components/AnnouncementsCard";
import { LearningTopicsCard } from "./components/LearningTopicsCard";
import { CallToActionBox } from "./components/CallToActionBox";
import BotCards from "./components/BotCards";
import FreeStudent from "./components/FreeStudent";
import WhatsappBanner from "./banner and announcements/banner/WhatsappBanner";
import whatsappBanner from "../../../../assets/banner/whatsappBanner.png";
import Card from "../../../../components/bootstrap/Card";
import StudentWithoutClass from "./studentWithoutClass/StudentWithoutClass";
import {
  useGetListStudentsHomeworkQuery,
  useGetStudentSubscribedClassesQuery,
} from "../../../../store/features/apiSlice";
import StudentWithSchool from "./components/studentWithSchool/StudentWithSchool";
import { useGet_a_SchoolQuery } from "../../teacher/teacherApi";
import Button from "../../../../components/bootstrap/Button";
import GamesWrapper from "../../games/gamesWrapper/GamesWrapper";

const StudentDashboard = () => {
  const schoolId = localStorage.getItem("school");
  const isSchoolId = Number(schoolId) > 0;

  const { data: subscribedClassData, isLoading: isSubscribedLoading } =
    useGetStudentSubscribedClassesQuery();
  const { data: currentSchool, isLoading: isSchoolLoading } =
    useGet_a_SchoolQuery(schoolId, {
      skip: !schoolId,
    });
  const { data: studentHomeworkList } = useGetListStudentsHomeworkQuery();

  // Kontroller
  const isDerslig = currentSchool?.title === "DERSLİG";
  const subscribedStudent = Boolean(
    subscribedClassData && Object.keys(subscribedClassData).length
  );
  const unassignedStudent = isSchoolId && !subscribedStudent;

  const freeStudent = false;
  const showLeadership = true;
  const showHomework = !freeStudent;
  const showAnnouncements = true;

  return (
    <PageWrapper>
      <DashboardHeader />
      <Page container={false}>
        <div className={`${style.dashboardContainer} mx-5`}>
          {!subscribedStudent && !unassignedStudent && (
            <>
              <FreeStudent />
              <Card className="mt-3">
                {whatsappBanner && <WhatsappBanner banner={whatsappBanner} />}
              </Card>
            </>
          )}

          {(unassignedStudent || subscribedStudent) && <StudentWithSchool />}
          {isDerslig ? (
            <>
              <div className="d-flex justify-content-start">
                <Button
                  className="py-2 px-4 text-light"
                  style={{
                    background: "#D81768",
                    borderRadius: "6px",
                    transition: "background 0.3s ease",
                  }}
                  onClick={() =>
                    window.open("https://www.derslig.com/", "_blank")
                  }
                  onMouseEnter={(e) => (e.target.style.background = "#b9155a")}
                  onMouseLeave={(e) => (e.target.style.background = "#D81768")}
                >
                  DERSLİG'e Geri Dön
                </Button>
              </div>
              <div className="mt-3">
                <GamesWrapper />
              </div>
            </>
          ) : (
            <>
              <div className={`${style.dynamicGrid} mt-5`}>
                {showLeadership && <LeadershipAwardsCard />}
                {showHomework && (
                  <HomeworkTasksCard activeHomeworks={studentHomeworkList} />
                )}
                {showAnnouncements && <AnnouncementsCard />}
              </div>
              <div className="d-none d-lg-block">
                <CallToActionBox />
              </div>

              <div className="row mt-3 gap-lg-0 gap-3">
                <div className="col-lg-6 col-12">
                  <LearningTopicsCard />
                </div>
                <div className="col-lg-6 col-12">
                  <BotCards />
                </div>
              </div>
            </>
          )}
          {!isSubscribedLoading &&
            !isSchoolLoading &&
            !subscribedStudent &&
            !isSchoolId && (
              <div className="my-4">
                <StudentWithoutClass />
              </div>
            )}
        </div>
      </Page>
    </PageWrapper>
  );
};

export default StudentDashboard;
