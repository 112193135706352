import { apiSlice } from "../../../../../store/features/apiSlice";

export const restrictionApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSchoolRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/${id}/`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateSchoolRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/${data.id}/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getSchoolTrailRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/${id}/trails`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateSchoolTrailRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/${data.id}/trails/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getSchoolCoursesRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/${id}/courses`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateSchoolCoursesRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/${data.id}/courses/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getSchoolChaptersRestrictions: builder.query({
            query: ({id, course}) => ({
                // url: `restrictions/schools/${id}/chapters/?course=${course}`,
                url: `restrictions/schools/${id}/chapters/?course=${course}`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        updateSchoolChaptersRestrictions: builder.mutation({
            query: (data) => ({
                url: `restrictions/schools/${data.id}/chapters/`,
                method: "put",
                body:data
            }),
            invalidatesTags: ["restrictions"],
        }),
        getAllSchoolRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/${id}/access/`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),
        getAllClassRestrictions: builder.query({
            query: (id) => ({
                url: `restrictions/schools/classrooms/${id}/access/`,
                method: "get",
            }),
            providesTags: ["restrictions"],
        }),

        //--endpoints
    }),
});

export const {
 useGetSchoolRestrictionsQuery,
 useUpdateSchoolRestrictionsMutation,
 useGetSchoolTrailRestrictionsQuery,
 useUpdateSchoolTrailRestrictionsMutation,
 useGetSchoolCoursesRestrictionsQuery,
 useUpdateSchoolCoursesRestrictionsMutation,
 useGetSchoolChaptersRestrictionsQuery,
 useUpdateSchoolChaptersRestrictionsMutation,
 useGetAllSchoolRestrictionsQuery,
 useGetAllClassRestrictionsQuery,

} = restrictionApi;