import React from "react";
import style from "../dashboard.module.scss";
import AVATARS from "../../../../../common/data/avatarsDummy";

const FreeStudent = () => {
  let userName = localStorage.getItem("username");
  const userAvatar = localStorage.getItem("user_avatar");
  return (
    <div
      className={`${style.freeStudentBanner} d-flex justify-content-between align-items-center`}
    >
      <div className={`${style.welcome}`}>
        Tekrar Hoşgeldin {`${userName}`}!
      </div>
      <div className={`${style.userAvatar}`}>
        <img
          src={
            userAvatar && userAvatar !== "null"
              ? userAvatar
              : AVATARS.defaultAvatar
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default FreeStudent;
