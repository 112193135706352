import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../components/Logo";
import Icon from "../../../../../../components/icon/Icon";
import Button from "../../../../../../components/bootstrap/Button";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import Select from "../../../../../../components/bootstrap/forms/Select";
import showNotification from "../../../../../../components/extras/showNotification";
import {
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
} from "../../teacherEducationApi";
import { useClearCacheMutation } from "../../../../../../store/features/apiSlice";
import Spinner from "../../../../../../components/bootstrap/Spinner";
import { CoursePrograms } from "../../../course management/constant/CourseProgramList";

const ShowDocumentModal = ({
  isOpenDocumentModal,
  setIsOpenDocumentModal,
  currentDocument,
}) => {
  const [createDocument] = useCreateDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [clearCache] = useClearCacheMutation();
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (currentDocument) {
      console.log(
        "%c6-UpdateLessonModal.js line:52 currentDocument",
        "color: white; background-color: #007acc;",
        currentDocument
      );
      console.log(
        "%c6-UpdateLessonModal.js line:54 currentDocument.content_id",
        "color: white; background-color: #007acc;",
        currentDocument.content_id
      );
      formik.setFieldValue("id", currentDocument.id);
      formik.setFieldValue("title", currentDocument.title);
      formik.setFieldValue("order_number", currentDocument.order_number);
      formik.setFieldValue("description", currentDocument.description);
      formik.setFieldValue("cover_image", currentDocument.cover_image);
      formik.setFieldValue("status", currentDocument.status);
    } else {
      formik.resetForm();
    }
    //eslint-disable-next-line
  }, [currentDocument]);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    // setFileInputColor("dark")
    // formik.setFieldValue('document',selectedFile)
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Belgenin adını giriniz";
    }

    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }

    if (!values.cover_image) {
      errors.cover_image = "Logoyu seçiniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: null,
      title: "",
      description: "",
      technology: null,
      cover_image: "",
      order_number: 9999,
      status: "2",
    },
    validate,
    onSubmit: () => {
      if (file || currentDocument) {
        setBusy(true);
        handleDocument();
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="warning" size="lg" className="me-1" />
            <span>Lütfen bir dosya seçin.</span>
          </span>,
          "Dosya seçilmedi",
          "warning"
        );
      }
    },
  });

  const handleDocument = async () => {
    if (currentDocument) {
      const formData = new FormData();
      formData.append("title", formik.values.title);
      formData.append("description", formik.values.description);
      formData.append("cover_image", formik.values.cover_image);
      formData.append("order_number", formik.values.order_number);
      formData.append("status", formik.values.status);
      formData.append("technology", formik.values.technology);

      if (file) {
        formData.append("document", file);
      }
      const response = await updateDocument({
        id: formik.values.id,
        formData,
        title: formik.values.title,
      });
      if (response.error) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Doküman başarıyla güncellendi.",
          "success"
        );
        setFile(null);
      }
    } else {
      const formData = new FormData();
      formData.append("title", formik.values.title);
      formData.append("description", formik.values.description);
      formData.append("cover_image", formik.values.cover_image);
      formData.append("order_number", formik.values.order_number);
      formData.append("status", formik.values.status);
      formData.append("technology", formik.values.technology);
      formData.append("document", file); // Dosyayı FormData'ya ekleyin
      const response = await createDocument(formData);
      if (response.error) {
        const titleError = response.error.data.title;
        const isTitleError =
          titleError[0] === "document with this title already exists.";
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>Kayıt Başarısız</span>
          </span>,
          `${
            isTitleError
              ? "Bu başlıkta bir doküman zaten var"
              : "Bir hata oluştu"
          }`,
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          "Doküman başarıyla eklendi.",
          "success"
        );
        formik.resetForm();
        setFile(null);
      }
    }
    setIsOpenDocumentModal(false);
    clearCache();
    setBusy(false);
  };
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenDocumentModal}
      setIsOpen={setIsOpenDocumentModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenDocumentModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">{`${
            !currentDocument ? "Yeni Belge" : "Belgeyi Güncelle"
          }`}</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        >
          <div className="row gap-3 d-flex justify-content-center">
            <div className="col-lg-8">
              <FormGroup
                // className="col-4"
                id="title"
                isFloating
                label="Başlık"
              >
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                  isValid={formik.isValid}
                  isTouched={formik.touched.title}
                  invalidFeedback={formik.errors.title}
                />
              </FormGroup>
            </div>
            <div className="col-lg-8">
              <FormGroup
                // className="col-4"
                id="description"
                isFloating
                label="Açıklama/Not"
              >
                <Input
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  isValid={formik.isValid}
                  isTouched={formik.touched.description}
                  invalidFeedback={formik.errors.description}
                />
              </FormGroup>
            </div>
            <div className="col-lg-8">
              <FormGroup id="cover_image" isFloating label="Doküman Logosu">
                <Select
                  placeholder="Logo"
                  ariaLabel="Logo"
                  onChange={formik.handleChange}
                  value={formik.values.cover_image}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.cover_image}
                  invalidFeedback={formik.errors.cover_image}
                  list={CoursePrograms}
                  className="text-dark"
                ></Select>
              </FormGroup>
            </div>
            <div className="col-lg-8">
              <FormGroup isFloating label="Sıra Numarası" id="order_number">
                <Input
                  name="order_number"
                  type="text"
                  autoComplete="order_number"
                  value={formik.values.order_number}
                  onChange={formik.handleChange}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.order_number}
                  invalidFeedback={formik.errors.order_number}
                />
              </FormGroup>
            </div>
            <div className="col-lg-8">
              <FormGroup id="status">
                <Select
                  placeholder="Aktiflik"
                  ariaLabel="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  isValid={formik.isValid}
                  onBlur={formik.handleBlur}
                  isTouched={formik.touched.status}
                  invalidFeedback={formik.errors.status}
                  list={[
                    { value: "0", text: "Pasif" },
                    { value: "1", text: "Taslak" },
                    { value: "2", text: "Aktif" },
                  ]}
                  className="text-dark"
                ></Select>
              </FormGroup>
            </div>
            <div className="col-lg-8">
              <FormGroup
                // className="col-4"
                id="document"
                isFloating
                label="Belge"
              >
                <Input onChange={handleFileChange} type="file" accept=".pdf" />
              </FormGroup>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
          disabled={busy}
        >
          {busy && (
            <Spinner color="primary" inButton isSmall>
              Loading...
            </Spinner>
          )}
          {`${!currentDocument ? "Kaydet" : "Güncelle"}`}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShowDocumentModal;
