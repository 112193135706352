import React from "react";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../components/bootstrap/Modal";
import Logo from "../../../components/Logo";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPaymentPlan } from "../../../store/loginWithPaymentPlanSlice";
import { useState } from "react";

const RegisterControlModal = () => {
  const { paymentPlan, register } = useSelector(
    (store) => store.loginWithPaymentPlan
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //! PAYMENT PLAN LOGIN SECTION
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const planParam = searchParams.get("plan");
    const priceParam = searchParams.get("price");

    if (planParam) {
      dispatch(setPaymentPlan({ plan: planParam, price: priceParam }));
    }
    //eslint-disable-next-line
  }, []);

  if (paymentPlan.plan === "standart") {
    if (paymentPlan.price === "monthly") {
      localStorage.setItem("plan", "standartMounthly");
    } else if (paymentPlan.price === "annually") {
      localStorage.setItem("plan", "standartAnnually");
    }
  } else if (paymentPlan.plan === "premium") {
    if (paymentPlan.price === "monthly") {
      localStorage.setItem("plan", "premiumMounthly");
    } else if (paymentPlan.price === "annually") {
      localStorage.setItem("plan", "premiumAnnually");
    }
  }
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (paymentPlan && !register) {
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [paymentPlan, register]);

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      titleId="example-title"
    >
      <ModalHeader setIsOpen={setIsOpenModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Bilgi</span>
          <span className="ps-2">
            <Icon icon="Verified" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-9 d-flex align-items-center">
            <div>
              <h2>Merhaba 👋🏻</h2>
              <p className="lead">
                Plan satın almak için öncelikle kayıt olmalısınız.
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={() => {
            navigate("/");
            setIsOpenModal(false);
          }}
        >
          Tamam
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RegisterControlModal;
