const DifficultyLevels = Object.freeze({
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
});

// export default DifficultyLevels;

// export function getDifficultyText(difficulty) {
//   if (difficulty === undefined || difficulty === null) {
//     console.warn("Zorluk seviyesi tanımlanmadı. Varsayılan olarak 'Kolay' dönülüyor.");
//     return "Kolay"; // Varsayılan zorluk seviyesi
//   }

//   switch (difficulty) {
//     case DifficultyLevels.EASY:
//       return "Kolay";
//     case DifficultyLevels.MEDIUM:
//       return "Orta";
//     case DifficultyLevels.HARD:
//       return "İleri";
//     default:
//       throw new Error("Geçersiz zorluk seviyesi: " + difficulty);
//   }
// }

// const DifficultyLevels = Object.freeze({
//   EASY: 1,
//   MEDIUM: 2,
//   HARD: 3,
// });

// export default DifficultyLevels;

export function getDifficultyText(difficulty) {
  const difficultyMap = {
    [DifficultyLevels.EASY]: { text: "Kolay", color: "green", emoji: "🟢" },
    [DifficultyLevels.MEDIUM]: { text: "Orta", color: "orange", emoji: "🟠" },
    [DifficultyLevels.HARD]: { text: "Zor", color: "red", emoji: "🔴" },
  };

  if (difficulty === undefined || difficulty === null || !difficultyMap[difficulty]) {
    console.warn(`Geçersiz veya tanımlanmamış zorluk seviyesi (${difficulty}). Varsayılan olarak 'Kolay' dönülüyor.`);
    return difficultyMap[DifficultyLevels.EASY]; // Varsayılan olarak Kolay döner
  }

  return difficultyMap[difficulty];
}

