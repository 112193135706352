import boy_1 from '../../assets/avatars/boy_1.png';
import boy_2 from '../../assets/avatars/boy_2.png';
import boy_3 from '../../assets/avatars/boy_3.png';
import boy_4 from '../../assets/avatars/boy_4.png';
import boy_5 from '../../assets/avatars/boy_5.png';
import boy_6 from '../../assets/avatars/boy_6.png';
import boy_7 from '../../assets/avatars/boy_7.png';
import boy_8 from '../../assets/avatars/boy_8.png';
import boy_9 from '../../assets/avatars/boy_9.png';
import boy_10 from '../../assets/avatars/boy_10.png';
import girl_1 from '../../assets/avatars/girl_1.png';
import girl_2 from '../../assets/avatars/girl_2.png';
import girl_3 from '../../assets/avatars/girl_3.png';
import girl_4 from '../../assets/avatars/girl_4.png';
import girl_5 from '../../assets/avatars/girl_5.png';
import girl_6 from '../../assets/avatars/girl_6.png';
import girl_7 from '../../assets/avatars/girl_7.png';
import girl_8 from '../../assets/avatars/girl_8.png';
import girl_9 from '../../assets/avatars/girl_9.png';
import girl_10 from '../../assets/avatars/girl_10.png';
import defaultAvatar from '../../assets/avatars/defaultAvatar.png';
import defaultAvatarSmall from '../../assets/avatars/defaultAvatarSmall.png';


const AVATARS = {
	boy_1:boy_1,
	boy_2:boy_2,
	boy_3:boy_3,
	boy_4:boy_4,
	boy_5:boy_5,
	boy_6:boy_6,
	boy_7:boy_7,
	boy_8:boy_8,
	boy_9:boy_9,
	boy_10:boy_10,
	girl_1:girl_1,
	girl_2:girl_2,
	girl_3:girl_3,
	girl_4:girl_4,
	girl_5:girl_5,
	girl_6:girl_6,
	girl_7:girl_7,
	girl_8:girl_8,
	girl_9:girl_9,
	girl_10:girl_10,
	defaultAvatar:defaultAvatar,
	defaultAvatarSmall:defaultAvatarSmall,
};
export default AVATARS;
