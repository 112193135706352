import * as React from 'react';

function SvgPapyrusCertificate(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      className="svg-icon"
      viewBox="0 0 64 64"
      {...props}
    >
      <g>
        {/* Papirüs Belgesinin Dış Hatları */}
        <path
          d="M6 5c-1.1 0-2 .9-2 2v50c0 1.1.9 2 2 2h52c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2H6z"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
        
        {/* Papirüs Kenar Kıvrımları */}
        <path
          d="M6 5C6 3.9 6.9 3 8 3h48c1.1 0 2 .9 2 2v50c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V5z"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />

        {/* Belge Ortasında Basit Çizgi Detayı */}
        <path
          d="M8 25h48"
          stroke="currentColor"
          strokeWidth="1"
          fill="none"
        />

        {/* Papirüs Belgesinin Alt Kenarı */}
        <path
          d="M6 57c0-1.1.9-2 2-2h48c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2v-4z"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />

        {/* Papirüsün Kenarlarında Hafif Dalgalanma */}
        <path
          d="M6 7c.5 2 .9 4 2 5s1.5-2.5 3-2 2 2 3 3 1 3 2 4 1.5-3.5 3-3c1.5 0 2.5 1 4 0.5s1.5-2.5 3-2c1.5 0 2.5 2 3 2 1.5 0 2.5-2 4-1 1 1 2 4 3 4.5"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
      </g>
    </svg>
  );
}

export default SvgPapyrusCertificate;
