import React, { useState } from "react";
import style from "./TalentCupWrapperForStudent.module.scss";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";
import announce2 from "../../../../../src/assets/lessons/announce2.svg";
import classPathsLogo2 from "../../../../../src/assets/lessons/classPathsLogo2.svg";
import CompetitionList from "./CompetitionListForStudent";
import CompetitionCertificate from "./components/competition_certificates/CompetitionCertificate";
import Icon from "../../../../components/icon/Icon";

const TalentCupWrapperForStudent = () => {
  const _activeTab = sessionStorage.getItem("talentCupActiveTab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const tabContents = [
    {
      content: <CompetitionList />,
      tabName: "Yarışmalar",
      icon1: "Trophy",
      icon2: announce2,
    },
    {
      content: <CompetitionCertificate />,
      tabName: "Belgelerim",
      icon1: "Certificate",
      icon2: classPathsLogo2,
    },
  ];

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
      sessionStorage.setItem("talentCupActiveTab", tabNumber);
      setActiveTab(tabNumber);
    }
  };

  // useEffect(() => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.hideWidget();
  //   }
  //   return () => {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   };
  // }, []);
  return (
    <PageWrapper title={"Dersler"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="page container-xxl">
          <div
            className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto mb-5 px-3 gap-1`}
          >
            {tabContents.map((tab, index) => (
              <div
                key={index}
                className={`${style.tab} ${
                  activeTab === index + 1 ? `${style.activetab}` : ""
                } d-flex justify-content-center align-items-center gap-2 p-3`}
                onClick={() => handleTabClick(index + 1)}
              >
                <div className={`${style.tabsLogo}`}>
                  <Icon
                    icon={tab.icon1}
                    size="2x"
                    className="me-2"
                    style={{
                      position: "relative",
                      bottom: "4px",
                      right: "5px",
                    }}
                  />
                </div>
                <div className={`${style.tabsDescription}`}>
                  <span>{tab.tabName}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default TalentCupWrapperForStudent;
