import { useState } from "react";

export default function useToken() {
    const getToken = () => {
        const token = localStorage.getItem('token')
        return token;
    }

    const getEmail = () => {
        const email = localStorage.getItem('email');
        return email;
    }

    const getRoles = () => {
        const roles = localStorage.getItem('roles');
        return roles;
    }

    const [token, setToken] = useState(getToken());
    // eslint-disable-next-line
    const [email, setEmail] = useState(getEmail());
    // eslint-disable-next-line
    const [roles, setRoles] = useState(getRoles());

    const saveToken = ({ token, roles, name, surname, email, id, points, username, school, birth_date, user_avatar, subscription_type, userRoleStatus, role_status_date, subscription_expiration_date, last_login }) => {
        localStorage.setItem('token', token);
        localStorage.setItem('roles', roles);
        localStorage.setItem('name', name);
        localStorage.setItem('surname', surname);
        localStorage.setItem('email', email);
        localStorage.setItem('id', id);
        localStorage.setItem('points', points);
        localStorage.setItem('username', username);
        localStorage.setItem('school', school);
        localStorage.setItem('birth_date', birth_date);
        localStorage.setItem('user_avatar', user_avatar);
        localStorage.setItem('subscription_type', subscription_type);
        localStorage.setItem('userRoleStatus', userRoleStatus);
        localStorage.setItem('role_status_date', role_status_date);
        localStorage.setItem('subscription_expiration_date', subscription_expiration_date);
        localStorage.setItem('last_login', last_login);
        setToken(token)
    }

    return {
        setToken: saveToken,
        token,
        email
    }
}