import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Profil, presentationForStudent } from "../../menu";
import {
  DropdownItem,
  DropdownMenu,
} from "../../components/bootstrap/Dropdown";
import Button from "../../components/bootstrap/Button";
import useDarkMode from "../../hooks/useDarkMode";
import Collapse from "../../components/bootstrap/Collapse";
import Navigation, { NavigationLine } from "../Navigation/Navigation";
import Icon from "../../components/icon/Icon";
import useNavigationItemHandle from "../../hooks/useNavigationItemHandle";
import { post } from "../../utils/service";
import USER_ROLES from "../../common/data/enumUserRole";
import { AuthContext } from "../../store/auth-store";
import AVATARS from "../../common/data/avatarsDummy";
import { useDispatch } from "react-redux";
import { setPaymentPlan } from "../../store/loginWithPaymentPlanSlice";
import ThemeContext from "../../contexts/themeContext";
import TeacherSwitch from "./TeacherSwitch";
import TEACHER_ROLE_STATUS from "../../common/data/enumTeacherRoleStatus";
import { nanoid } from "nanoid";

const User = () => {
  const navigate = useNavigate();
  const handleItem = useNavigationItemHandle();
  const { user, token, logout } = useContext(AuthContext);
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);

  const teacherStatus = localStorage.getItem("userRoleStatus")
  const isPending = Number(teacherStatus) === TEACHER_ROLE_STATUS.PENDING;
  const isRejected = Number(teacherStatus) === TEACHER_ROLE_STATUS.REJECTED;
  const isUnauthorizedTeacher = isPending || isRejected

  const dispatch = useDispatch();

  const [collapseStatus, setCollapseStatus] = useState(false);


  let userName = localStorage.getItem("username");
  userName = userName && userName !== "null" ? userName.slice(0, 15) : null;
  const role = user?.roles || USER_ROLES.GUEST.id;
  const email = user?.email || null;
  const name = userName || email?.substring(0, email.indexOf("@")) || "Guest";

  const displayName = () => {
    if (name === "") {
      return "Misafir";
    } else if (name === "null") {
      // return 'Merhaba';
      return email?.substring(0, email.indexOf("@")).slice(0, 15);
    }
    return name;
  };
  
  const userRole =
    (role === "" && "Misafir") ||
    (role === "null" && "Kullanıcı") ||
    (role === USER_ROLES.STUDENT.id && USER_ROLES.STUDENT.name) ||
    (role === USER_ROLES.TEACHER.id && USER_ROLES.TEACHER.name) ||
    (role === USER_ROLES.SCHOOL_MANAGER.id && USER_ROLES.SCHOOL_MANAGER.name) ||
    (role === USER_ROLES.EDITOR.id && USER_ROLES.EDITOR.name) ||
    (role === USER_ROLES.USER_SUPPORT.id && USER_ROLES.USER_SUPPORT.name) ||
    (role === USER_ROLES.ADMIN.id && USER_ROLES.ADMIN.name);

  const logInOutText = () => {
    if (!token) {
      return "Giriş Yap";
    }
    return "Çıkış Yap";
  };

  const logoutUser = async () => {
    try {
      if (token) {
        await post("auth/logout/", token);
        logout();
      }
      return;
    } catch ({ response }) {
      // console.log(response);
    } finally {
      dispatch(setPaymentPlan(false));
      navigate(`/`);
    }
  };
 
  let userAvatar = localStorage.getItem("user_avatar");
  return (
    <>
      <div
        className={classNames("user", { open: collapseStatus })}
        style={{ backgroundColor: !darkModeStatus ? "#F3F4FD" : "#1E2027" }}
        role="presentation"
        onClick={() => setCollapseStatus(!collapseStatus)}
      >
        <div className="user-avatar">
          {userRole === USER_ROLES.TEACHER.name ? (
            ""
          ) : (
            <img
              src={
                userAvatar && userAvatar !== "null"
                  ? userAvatar
                  : AVATARS.defaultAvatarSmall
              }
              alt="Avatar"
              width={128}
              height={128}
            />
          )}
        </div>
        <div className="user-info">
          <div
            className="user-name d-flex align-items-center"
            style={{ color: "rgb(104, 104, 109)" }}
          >
            {displayName()}
            <Icon icon="Verified" className="ms-1" color="info" />
          </div>
          <div className="user-sub-title">{userRole}</div>
        </div>
      </div>

      <DropdownMenu>
        <DropdownItem>
          <Button icon="AccountBox" onClick={() => navigate(`/profile`)}>
            Profil
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? "DarkMode" : "LightMode"}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label="Toggle fullscreen"
          >
            {darkModeStatus ? "Dark Mode" : "Light Mode"}
          </Button>
        </DropdownItem>
      </DropdownMenu>

      <Collapse isOpen={collapseStatus} className="user-menu">
      <Navigation id={nanoid()} menu={Profil}/>
        <nav aria-label="aside-bottom-user-menu">
          <div className="navigation">
            {userRole === USER_ROLES.STUDENT.name && <Navigation id={nanoid()} menu={presentationForStudent}/>}
            <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={() => {
                setDarkModeStatus(!darkModeStatus);
                handleItem();
              }}
            >
              <span className="navigation-link navigation-link-pill">
                <span
                  className="navigation-link-info"
                  style={{ color: "#B6B5C8" }}
                >
                  <Icon
                    icon={darkModeStatus ? "DarkMode" : "LightMode"}
                    color={darkModeStatus ? "info" : "warning"}
                    className="navigation-icon"
                  />
                  <span className="navigation-text">
                    {darkModeStatus ? "Dark Mode" : "Light Mode"}
                  </span>
                </span>
              </span>
            </div>
            {/*	Full Screen */}
            <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={() => setFullScreenStatus(!fullScreenStatus)}
            >
              <span className="navigation-link navigation-link-pill">
                <span
                  className="navigation-link-info"
                  style={{ color: "#B6B5C8" }}
                >
                  <Icon
                    icon={fullScreenStatus ? "FullscreenExit" : "Fullscreen"}
                    className="navigation-icon"
                  />
                  <span className="navigation-text">
                    {fullScreenStatus ? "Ekranı Küçült" : "Tam Ekran"}
                  </span>
                </span>
              </span>
            </div>

            {/* Switch For Teacher */}
            {userRole === USER_ROLES.TEACHER.name && !isUnauthorizedTeacher && <TeacherSwitch />}
          </div>
        </nav>
        <NavigationLine />
        <nav aria-label="aside-bottom-user-menu-2">
          <div className="navigation">
            <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={logoutUser}
            >
              <span className="navigation-link navigation-link-pill">
                <span
                  className="navigation-link-info"
                  style={{ color: "#B6B5C8" }}
                >
                  <Icon icon="Logout" className="navigation-icon" />
                  <span className="navigation-text">{logInOutText()}</span>
                </span>
              </span>
            </div>
          </div>
        </nav>
      </Collapse>
    </>
  );
};

export default User;
