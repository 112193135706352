import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../../components/Logo";
import Icon from "../../../../../../../components/icon/Icon";
import { useFormik } from "formik";
import showNotification from "../../../../../../../components/extras/showNotification";
import {
  useLazyGetCTFFileQuery,
  useRunCTFQuestionMutation,
  useSingleCyberCTFQuestionQuery,
} from "../../../../../../../store/features/cyberApi";
import CustomFormField from "../../../../../../../common/customFormField/CustomFormField";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../../../../../utils/customErrors";
import { getFileExtension, handleDownload } from "../CTFUtils";
import LoadingSpinner from "../../../../../../../utils/LoadingSpinner";
import Button from "../../../../../../../components/bootstrap/Button";
import styles from "./CTRRun.module.scss";

const CTFRun = ({ isOpen, setIsOpen, CTFId = 0 }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hint1, setHint1] = useState(false);
  const [hint2, setHint2] = useState(false);
  const [triggerDownload] = useLazyGetCTFFileQuery();
  const [runCTFQuestion] = useRunCTFQuestionMutation();

  const { data: currentCTF, isLoading } = useSingleCyberCTFQuestionQuery(
    CTFId,
    { skip: !CTFId }
  );

  useEffect(() => {
    if (currentCTF) {
      formik.setValues({
        flag: currentCTF.flag || "",
        capturetheflagquestion: currentCTF.id || "",
        category: currentCTF.categories[0]?.category || 0,
      });
    } else {
      formik.resetForm();
    }
    //eslint-disable-next-line
  }, [currentCTF]);

  const validate = (values) => {
    const errors = {};

    if (!values.flag) {
      errors.flag = "Flag değerini giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      flag: currentCTF?.flag ?? "",
      capturetheflagquestion: CTFId,
      category: currentCTF?.categories[0]?.category,
    },
    validate,
    onSubmit: (values) => {
      handleTestCTF(values);
    },
  });

  const handleTestCTF = async (values) => {
    const { ...CTFValues } = formik.values;
    console.log("categoryValues", CTFValues);
    let response;
    try {
      response = await runCTFQuestion({ data: CTFValues });
      if (response.error) {
        const customError = handleErrorMessage(response.error.data);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>{getCustomErrorMessage(customError)}</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          `Cevap gönderildi.`,
          "success"
        );
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
    // clearCache();
    setIsOpen(false);
  };

  useEffect(() => {
    formik.setFieldValue("categories", selectedItems);
    //eslint-disable-next-line
  }, [selectedItems]);

  if (isLoading || loading) return <LoadingSpinner />;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
      size={null}
      fullScreen={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="tour-title" className={styles.modalTitle}>
          <Logo height={28} />{" "}
          <h2 className="ps-2">
            {currentCTF?.title}
            <span className="ps-2">
              <Icon icon="Flag" color="info" />
            </span>
          </h2>
        </ModalTitle>
      </ModalHeader>

      <ModalBody className={styles.modalBody}>
        <div>
          <h3>Açıklama</h3>
          <p>
            Aşağıdaki linkte belirtilen dosyanın içerisinde gizlenmiş bir flag
            değeri bulunmaktadır. Onu bize verir misin?
          </p>
        </div>
        <div
          className={`${styles.fileLinkContainer} ${
            !currentCTF?.file_name ? "disabled" : ""
          }`}
          onClick={(e) => {
            if (currentCTF?.file_name) {
              handleDownload(currentCTF?.id, setLoading, triggerDownload);
            }
          }}
        >
          {currentCTF?.file_name ? (
            <>
              Capture The Flag Question File (
              {getFileExtension(currentCTF?.file_name)})
            </>
          ) : (
            "-"
          )}
        </div>

        <div className={styles.hintContainer}>
          <div className={styles.hintButtonContainer}>
            <button
              className={styles.hintButton}
              onClick={() => setHint1(true)}
              disabled={hint1}
            >
              İpucu 1
            </button>
            <button
              className={styles.hintButton}
              onClick={() => setHint2(true)}
              disabled={hint2}
            >
              İpucu 2
            </button>
          </div>
        </div>

        <div>
          {hint1 && (
            <div className={styles.hint1Container}>
              <p className={styles.hintText}>
                İpucu 1: {currentCTF?.educational_hint}
              </p>
            </div>
          )}
        </div>
        <div>
          {hint2 && (
            <div className={styles.hint2Container}>
              <p className={styles.hintText}>
                İpucu 2: {currentCTF?.additional_hint}
              </p>
            </div>
          )}
        </div>

        {currentCTF?.users_solved > 0 && (
          <div className={styles.usersSolvedContainer}>
            <h5>{currentCTF?.users_solved} Kullanıcı Çözdü</h5>
          </div>
        )}

        <div className="col-lg-12">
          <CustomFormField
            type="textArea"
            label="Flag"
            name={"flag"}
            formik={formik}
            // className={styles.flagInput}
            style={{ height: "150px" }}
          />
        </div>
        <div className={styles.submitButtonContainer}>
          <Button
            icon="DoneOutline"
            color="success"
            isLight
            onClick={async () => {
              formik.handleSubmit();
            }}
            className={styles.successButton}
          >
            Gönder
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CTFRun;
