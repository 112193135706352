import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import jwt_decode from "jwt-decode";
import {useNavigate } from 'react-router-dom';
const AuthContext = React.createContext({
  user: {roles:0},
  token: null,
  login: () => {},
  logout: () => {},
});



const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const [token, setToken] = useLocalStorage('token', null);
  const navigate = useNavigate()

  const handleLogin = (token, user) => {
    setUser({...user});
    setToken(token);
  };
  const handleLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    setUser(null);
    setToken(null);
    navigate("/login");
  };
// eslint-disable-next-line
const [isTokenValid, setIsTokenValid] = useState(true);

// useEffect(() => {
//   const interval = setInterval(() => {
//     const decodedToken = jwt_decode(token);
//     const currentTime = Date.now() / 1000;

//     if (decodedToken.exp < currentTime) {
//       setIsTokenValid(false);
//       clearInterval(interval);
//       localStorage.clear()
//       setUser(null);
//       setToken(null);
//       navigate("/login")
//     }
//   }, 60000);

//   return () => clearInterval(interval);
//   //eslint-disable-next-line
// }, [token]);

// useEffect(() => {
//   if (!token) {
//     return;
//   }

//   const interval = setInterval(() => {
//     try {
//       const decodedToken = jwt_decode(token);
//       const currentTime = Date.now() / 1000;

//       if (decodedToken.exp < currentTime) {
//         setIsTokenValid(false);
//         clearInterval(interval);
//         localStorage.clear();
//         setUser(null);
//         setToken(null);
//         navigate("/login");
//       }
//     } catch (error) {
//       console.error("Geçersiz token:", error);
//       clearInterval(interval);
//       localStorage.clear();
//       setUser(null);
//       setToken(null);
//       navigate("/login");
//     }
//   }, 60000);

//   return () => clearInterval(interval);
// }, [token, navigate, setUser, setToken]);

useEffect(() => {
  if (!token) return; // Token yoksa herhangi bir işlem yapma

  try {
    const decodedToken = jwt_decode(token);
    const expirationTime = decodedToken.exp * 1000 - Date.now(); // Token'in kalan süresi

    if (expirationTime <= 0) {
      handleLogout();
      return;
    }

    // Token süresine göre bir setTimeout ayarla
    const timeoutId = setTimeout(() => {
      handleLogout();
    }, expirationTime);

    return () => clearTimeout(timeoutId); // Bileşen unmount olduğunda temizle
  } catch (error) {
    console.error("Geçersiz token:", error);
    handleLogout(); // Hata varsa oturumu kapat
  }
    //eslint-disable-next-line
}, [token, navigate]);

  return (
    <AuthContext.Provider
      value={{ user, token, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };