import React from "react";
import style from "../dashboard.module.scss";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFollowingLessonsQuery } from "../../../../../store/features/apiSlice";
import Icon from "../../../../../components/icon/Icon";

export const LearningTopicsCard = () => {
  const navigate = useNavigate();
  const { data: followingLessonsData, isLoading, error } = useFollowingLessonsQuery();

  // const following_lessons = React.useMemo(() => {
  //   if (!followingLessonsData || typeof followingLessonsData !== "object") return [];
  //   return Object.entries(followingLessonsData)
  //     .map(([topicName, info]) => ({
  //       id: info.id,
  //       name: topicName,
  //       progress: info.percentage,
  //     }))
  //     .filter((lesson) => lesson.progress < 100); // sadece devam edenleri al
  // }, [followingLessonsData]);

  const following_lessons = [
    {
      id: "lesson-1",
      name: "Siber Güvenliğe Giriş",
      progress: 60
    },
    {
      id: "lesson-3",
      name: "Şifre Güvenliği",
      progress: 45
    },
    {
      id: "lesson-4",
      name: "Ağ Güvenliği",
      progress: 80
    },
    {
      id: "lesson-1",
      name: "Siber Güvenliğe Giriş",
      progress: 60
    },
    {
      id: "lesson-3",
      name: "Şifre Güvenliği",
      progress: 45
    },
    {
      id: "lesson-4",
      name: "Ağ Güvenliği",
      progress: 80
    }
  ]
  

  const handleNavigate = (id) => {
    navigate(`/lessons/AllLessonsAndContent/${id}`);
  };

  return (
    <div className={`${style.card} ${style.scrollableCard}`}>
      <h5 className={style.sectionTitle}>
        <Icon icon="VideoCall" size="2x"/>Takip Ettiğim Konular
      </h5>

      {isLoading && <p>Yükleniyor...</p>}
      {error && <p>Bir hata oluştu.</p>}

      <div className={style.progressContainer}>
        {following_lessons.map((topic) => (
          <div key={topic.id} className="mb-3">
            <p className="fw-bold mb-1">{topic.name}</p>
            <ProgressBar
              now={topic.progress}
              label={`${topic.progress}%`}
              animated
              className="cursor-pointer"
              style={{
                height: "20px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => handleNavigate(topic.id)}
            />
          </div>
        ))}
        {following_lessons.length === 0 && !isLoading && (
          <p className="text-muted">Devam eden bir konun yok.</p>
        )}
      </div>
    </div>
  );
};
