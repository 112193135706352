import { apiSlice } from "./apiSlice";

export const cyberApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Category
    cyberCategories: builder.query({
      query: () => ({
        url: `cyber14/categories/`,
        method: "get",
      }),
      providesTags: ["cyber"],
    }),
    singleCyberCatogory: builder.query({
      query: (id) => ({
        url: `cyber14/categories/${id}`,
        method: "get",
      }),
      providesTags: ["cyber"],
    }),
    createCyberCategory: builder.mutation({
      query: ({
        data
      }) => ({
        url: `cyber14/categories/`,
        method: "post",
        body: data,
      }),
      invalidatesTags: ["cyber"],
    }),
    updateCyberCategory: builder.mutation({
      query: ({
        id,
        data
      }) => ({
        url: `cyber14/categories/${id}/`,
        method: "put",
        body: data,
      }),
      invalidatesTags: ["cyber"],
    }),
    deleteCyberCategory: builder.mutation({
      query: (id) => ({
        url: `cyber14/categories/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["cyber"],
    }),
    
    //Questions -- capture_the_flag_questions
    cyberCTFQuestions: builder.query({
      query: () => ({
        url: `cyber14/capture_the_flag_questions/`,
        method: "get",
      }),
      providesTags: ["cyber"],
    }),
    singleCyberCTFQuestion: builder.query({
      query: (id) => ({
        url: `cyber14/capture_the_flag_questions/${id}`,
        method: "get",
      }),
      providesTags: ["cyber"],
    }),
    createCTFQuestion: builder.mutation({
      query: ({
        formData
      }) => ({
        url: `cyber14/capture_the_flag_questions/`,
        method: "post",
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
          body: formData,
      }),
      invalidatesTags: ["cyber"],
    }),
    updateCTFQuestion: builder.mutation({
      query: ({
        id,
        formData
      }) => ({
        url: `cyber14/capture_the_flag_questions/${id}/`,
        method: "put",
        prepareHeaders: (headers) => {
            headers.set("Content-Type", "multipart/form-data");
            return headers;
          },
          body: formData,
      }),
      invalidatesTags: ["cyber"],
    }),
    deleteCTFQuestion: builder.mutation({
      query: (id) => ({
        url: `cyber14/capture_the_flag_questions/${id}/`,
        method: "delete",
      }),
      invalidatesTags: ["cyber"],
    }),
    getCTFFile: builder.query({
      query: (data) => ({
        url: `cyber14/capture_the_flag_questions/${data.id}/file/`,
        method: "get",
        responseHandler: async (response) => {
          // Gelen yanıtı Blob olarak döndür
          const blob = await response.blob();
          return blob;
        },
      }),
      providesTags: ["cyber"],
    }),
    runCTFQuestion: builder.mutation({
      query: ({
        data
      }) => ({
        url: `cyber14/capture_the_flag_questions/run/`,
        method: "post",
          body: data,
      }),
    }),

    //--endpoints
  }),
});

export const {
//Category
 useCyberCategoriesQuery,
 useSingleCyberCatogoryQuery,
 useCreateCyberCategoryMutation,
 useUpdateCyberCategoryMutation,
 useDeleteCyberCategoryMutation,

//Questions
 useCyberCTFQuestionsQuery,
 useLazyCyberCTFQuestionsQuery,
 useSingleCyberCTFQuestionQuery,
 useCreateCTFQuestionMutation,
 useUpdateCTFQuestionMutation,
 useDeleteCTFQuestionMutation,
 useLazyGetCTFFileQuery,
 useRunCTFQuestionMutation
} = cyberApi;
