import React, { useEffect, useState } from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../../../components/bootstrap/Modal";
import Logo from "../../../../../../../components/Logo";
import Icon from "../../../../../../../components/icon/Icon";
import { useFormik } from "formik";
import showNotification from "../../../../../../../components/extras/showNotification";
import {
  useCyberCategoriesQuery,
  useSingleCyberCTFQuestionQuery,
} from "../../../../../../../store/features/cyberApi";
import CustomFormField from "../../../../../../../common/customFormField/CustomFormField";
import Input from "../../../../../../../components/bootstrap/forms/Input";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../../../../../utils/customErrors";
import { useClearCacheMutation } from "../../../../../../../store/features/apiSlice";
import Wizard, { WizardItem } from "../../../../../../../components/Wizard";
import Label from "../../../../../../../components/bootstrap/forms/Label";
import FormGroup from "../../../../../../../components/bootstrap/forms/FormGroup";
import ListGroup, {
  ListGroupItem,
} from "../../../../../../../components/bootstrap/ListGroup";
import Badge from "../../../../../../../components/bootstrap/Badge";
import { post, put } from "../../../../../../../utils/service";
import useSortableData from "../../../../../../../hooks/useSortableData";
import Checks from "../../../../../../../components/bootstrap/forms/Checks";

export const CTFDifficultyOptions = [
  { value: "1", text: "Kolay" },
  { value: "2", text: "Orta" },
  { value: "3", text: "Zor" },
  { value: "4", text: "İmkansız" },
];
export const CTFStatusOptions = [
  { value: "1", text: "Aktif" },
  { value: "2", text: "Pasif" },
];

const CTFForm = ({ isOpen, setIsOpen, handleKeyDown, CTFId = 0, refetch }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentFile, setCurrentFile] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const { data: categories, isLoading: categoryLoading } =
    useCyberCategoriesQuery();

  const { items } = useSortableData(
    filteredCategories?.length > 0
      ? filteredCategories
      : categories
      ? categories
      : []
  );

  const { data: currentCTF, isLoading} = useSingleCyberCTFQuestionQuery(
    CTFId,
    { skip: !CTFId }
  );

  useEffect(() => {
    if (currentCTF) {
      formik.setValues({
        ...currentCTF,
        categories: currentCTF.categories?.map((cat) => cat.category) || [],
      });
      setCurrentFile(currentCTF?.file_name || "");
      setTimeout(() => {
        setSelectedItems(
          currentCTF.categories?.map((cat) => cat.category) || []
        );
      }, 0);
    } else {
      formik.resetForm();
      setSelectedItems([]);
      setCurrentFile("")
    }
    //eslint-disable-next-line
  }, [CTFId, currentCTF, isOpen]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Başlık giriniz";
    }
    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }
    if (!values.points) {
      errors.points = "Puanı giriniz";
    }
    if (!values.categories.length > 0) {
      errors.categories = "Kategori seçiniz";
    }
    if (!values.flag) {
      errors.flag = "Flag değerini giriniz";
    }
    if (!values.educational_hint) {
      errors.educational_hint = "İlk ipucunu giriniz";
    }
    if (!values.additional_hint) {
      errors.additional_hint = "İkinci ipucunu giriniz";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      points: "",
      status: 1,
      difficulty: 1,
      flag: "",
      educational_hint: "",
      additional_hint: "",
      categories: currentCTF?.categories?.map((cat) => cat.category) || [],
      capture_the_flag_question_file: null,
    },
    validate,
    onSubmit: (values) => {
      handleCTFQuestion(values);
    },
  });

  const handleCTFQuestion = async (values) => {
    if (
      !values.title
      //  ||
      // !values.description ||
      // !values.points ||
      // !values.flag ||
      // !values.educational_hint ||
      // !values.additional_hint
    ) {
      alert("Lütfen tüm zorunlu alanları doldurun!");
      return;
    }

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("points", values.points);
    formData.append("status", values.status);
    formData.append("difficulty", values.difficulty);
    formData.append("flag", values.flag);
    formData.append("educational_hint", values.educational_hint);
    formData.append("additional_hint", values.additional_hint);
    if (values.categories.length > 0) {
      values.categories.forEach((category) => {
        formData.append("categories", category);
      });
    }

    if (values.capture_the_flag_question_file) {
      formData.append(
        "capture_the_flag_question_file",
        values.capture_the_flag_question_file
      );
    }
    let response;
    try {
      if (CTFId) {
        response = await put(
          `cyber14/capture_the_flag_questions/${CTFId}/`,
          formData
        );
      } else {
        response = await post(`cyber14/capture_the_flag_questions/`, formData);
      }
      if (response.error) {
        console.log(response.error);
        const customError = handleErrorMessage(response.error.data);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>{getCustomErrorMessage(customError, "Kayıt Başarısız")}</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Kayıt Başarılı</span>
          </span>,
          `${CTFId ? "CTF başarıyla güncellendi." : "CTF başarıyla eklendi."}`,
          "success"
        );
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
    // clearCache();
    setIsOpen(false);
    // formik.resetForm();
    refetch();
    setCurrentFile("");
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      formik.setFieldValue("capture_the_flag_question_file", selectedFile);
      setErrorMessage(false);
      console.log(selectedFile);
    } else {
      formik.setFieldValue("capture_the_flag_question_file", null);
      setErrorMessage(true);
    }
  };

  const handleSearchChange = (newValue) => {
    setSearchValue(newValue);
  };

  const filterCategories = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newCategories =
      categories &&
      categories.filter(
        (item) =>
          item.title &&
          item.title.toLowerCase().includes(searchValue?.toLowerCase() || "")
      );
    setFilteredCategories(newCategories);
  };

  //effects
  useEffect(() => {
    filterCategories();
    //eslint-disable-next-line
  }, [searchValue]);

  const handleCheckboxChange = (categoryId) => {
    setSelectedItems(
      (prev) =>
        prev.some((item) => item === categoryId)
          ? prev.filter((item) => item !== categoryId) // Çıkar
          : [...prev, categoryId] // Ekle
    );
  };
  useEffect(() => {
    formik.setFieldValue("categories", selectedItems);
  }, [selectedItems]);

  if (isLoading && categoryLoading) return <div>Loading...</div>;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
      size={null}
      fullScreen={true}
      onKeyDown={handleKeyDown}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} />{" "}
          <span className="ps-2">
            {CTFId ? "CTF Düzenle" : "Yeni CTF"}
          </span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Wizard className="mb-0" stretch={false} onSubmit={formik.handleSubmit}>
          <WizardItem id="content1">
            <div className="row g-4">
              <div className="col-12">
                <CustomFormField
                  type="text"
                  label="Başlık"
                  name={"title"}
                  formik={formik}
                />
              </div>
              <div className="col-12">
                <CustomFormField
                  type="textArea"
                  label="Açıklama"
                  name={"description"}
                  formik={formik}
                  style={{ height: "150px" }}
                />
              </div>
            </div>
          </WizardItem>
          <WizardItem id="content2">
            <div>
              <div className="row g-4">
                <div
                  className="col-12"
                  style={{ height: "250px", overflow: "scroll" }}
                >
                  <Input
                    value={searchValue}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    type="text"
                    placeholder="İçeriklerde Ara.."
                    className="mb-2"
                  />
                  {items.length === 0 ? (
                    <div>
                      <span>Kayıtlı öğrenme yolu bulunamadı</span>
                    </div>
                  ) : (
                    items.map((item, i) => {
                      return (
                        <div className="d-flex" key={item.id}>
                          <div>
                            <Checks
                              id={item.id.toString()}
                              name="selectedList"
                              value={item.id}
                              onChange={() =>
                                handleCheckboxChange(
                                  item.id
                                  // item.practice_order_number
                                )
                              }
                              checked={selectedItems.includes(item.id)}
                            />
                          </div>
                          <div>{item.title}</div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </WizardItem>
          <WizardItem id="content3">
            <div className="row g-4">
              <div className="col-12">
                <CustomFormField
                  type="select"
                  label="Zorluk"
                  name={"difficulty"}
                  options={CTFDifficultyOptions}
                  formik={formik}
                />
              </div>
              <div className="col-12">
                <CustomFormField
                  type="text"
                  label="Puan"
                  name={"points"}
                  formik={formik}
                />
              </div>
              <div className="col-12">
                <CustomFormField
                  type="select"
                  label="Aktiflik"
                  name={"status"}
                  options={CTFStatusOptions}
                  formik={formik}
                />
              </div>
            </div>
          </WizardItem>
          <WizardItem id="content4">
            <div className="row g-4 d-flex">
              <div className="col-12">
                <CustomFormField
                  type="textArea"
                  label="Flag"
                  name={"flag"}
                  formik={formik}
                  style={{ height: "150px" }}
                />
              </div>
              <div className="col-6">
                <CustomFormField
                  type="textArea"
                  label="İpucu 1"
                  name={"educational_hint"}
                  formik={formik}
                  style={{ height: "150px" }}
                />
              </div>
              <div className="col-6">
                <CustomFormField
                  type="textArea"
                  label="İpucu 2"
                  name={"additional_hint"}
                  formik={formik}
                  style={{ height: "150px" }}
                />
              </div>
              <div className="col-12">
                <Label>
                  Proje Dosyası{" "}
                  {currentFile && (
                    <span style={{ color: "blue" }}>
                      👉{currentFile}
                    </span>
                  )}
                </Label>
                <FormGroup id="ctf">
                  <Input
                    onChange={handleFileChange}
                    type="file"
                    // accept=".zip"
                  />
                </FormGroup>
                {errorMessage && (
                  <div>
                    <p
                      style={{
                        color: "#f46639",
                        fontSize: "11px",
                        margin: "3px",
                      }}
                    >
                      Dosyası Ekleyiniz
                    </p>
                  </div>
                )}
              </div>
            </div>
          </WizardItem>
          <WizardItem id="summary">
            <ListGroup isFlush>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Başlık
                </Badge>{" "}
                {formik.errors.title ? (
                  <span className="error">{formik.errors.title}</span>
                ) : (
                  formik.values.title
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Açıklama:
                </Badge>{" "}
                {formik.errors.description ? (
                  <span className="error">{formik.errors.description}</span>
                ) : (
                  formik.values.description
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Kategoriler:
                </Badge>{" "}
                {formik.errors.categories ? (
                  <span className="error">{formik.errors.categories}</span>
                ) : (
                  <span>
                    {formik.values.categories?.length > 0
                      ? formik.values.categories.map((cat) => cat).join(", ")
                      : "Kategori seçilmedi"}
                  </span>
                )}
              </ListGroupItem>

              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Zorluk:
                </Badge>{" "}
                {formik.errors.difficulty ? (
                  <span className="error">{formik.errors.difficulty}</span>
                ) : (
                  CTFDifficultyOptions.find(
                    (option) =>
                      Number(option.value) === formik.values.difficulty
                  )?.text || "Bilinmiyor"
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Puan:
                </Badge>{" "}
                {formik.errors.points ? (
                  <span className="error">{formik.errors.points}</span>
                ) : (
                  formik.values.points
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Durum:
                </Badge>{" "}
                {formik.errors.status ? (
                  <span className="error">{formik.errors.status}</span>
                ) : (
                  CTFStatusOptions.find(
                    (option) => Number(option.value) === formik.values.status
                  )?.text || "Bilinmiyor"
                )}
              </ListGroupItem>

              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Flag:
                </Badge>{" "}
                {formik.errors.flag ? (
                  <span className="error">{formik.errors.flag}</span>
                ) : (
                  formik.values.flag
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  İpucu1:
                </Badge>{" "}
                {formik.errors.educational_hint ? (
                  <span className="error">{formik.errors.educational_hint}</span>
                ) : (
                  formik.values.educational_hint
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  İpucu2:
                </Badge>{" "}
                {formik.errors.additional_hint ? (
                  <span className="error">{formik.errors.additional_hint}</span>
                ) : (
                  formik.values.additional_hint
                )}
              </ListGroupItem>
              <ListGroupItem>
                <Badge color="info" isLight rounded={0}>
                  Proje Dosyası:
                </Badge>{" "}
                {formik.values.capture_the_flag_question_file?.name ? (
                  formik.values.capture_the_flag_question_file.name
                ) : currentFile ? (
                  <span style={{ color: "blue" }}>
                    👉 {currentFile}
                  </span>
                ) : (
                  "Yok"
                )}
              </ListGroupItem>
            </ListGroup>
          </WizardItem>
        </Wizard>
      </ModalBody>
      {/* <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default CTFForm;
