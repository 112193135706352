import { configureStore } from "@reduxjs/toolkit";
import teacherSlice from "../pages/t14/teacher/teacherSlice";
import { apiSlice } from "./features/apiSlice";
import pathSlice from "./pathSlice";
import pathWithVideoSlice from "./pathWithVideoSlice";
import paymentSlice from "./paymentSlice";
import loginWithPaymentPlanSlice from "./loginWithPaymentPlanSlice";
import teacherLessonVideoSlice from "../pages/t14/teacher/teacherLessonVideoSlice";
import restrictionSlice from "../pages/t14/admin/school management/school restrictions/restrictionSlice";
import talentCupSlice from "../pages/t14/admin/talent cup/talentCupSlice";

const store = configureStore({
    reducer: {path:pathSlice.reducer,teacher:teacherSlice.reducer, teacherLessonVideoSlice:teacherLessonVideoSlice.reducer, pathWithVideo:pathWithVideoSlice.reducer, payment:paymentSlice.reducer, loginWithPaymentPlan:loginWithPaymentPlanSlice.reducer, restriction:restrictionSlice.reducer, talentCup:talentCupSlice.reducer, [apiSlice.reducerPath]:apiSlice.reducer},
    middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;