import React from "react";

const StepContent = ({competition=null, homework=null, step}) => {
  let stage = "PROD";
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;
  const isProd = baseUrl.includes("app.talent14");
  if (!isProd) {
    // dev code
    stage = "DEV";
  }
  const userId = localStorage.getItem("id");
  const createMarkup = () => {
    return {
      __html: step
        ? `<iframe src=${step?.url}?user=${userId}?game=${
            step?.id
          }?stage=${stage}?competition=${competition ?? null}?homework=${
            homework ?? null
          } style="flex-grow: 1; border: none; margin: 0; padding: 0;"></iframe>`
        : "",
    };
  };
  return (
    <div className="w-100 h-100">
      {" "}
      <div
        style={{ display: "flex", width: "100%", height: "100%" }}
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  );
};

export default StepContent;
